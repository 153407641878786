.file-skemaforside-less {
	color: red; /*  marker, not meant to be used. */
}

@import (reference) "constants.less";


/* skemascenarie contextmenu */
body:has(.display-grid-skemaforside) {
	.lec-context-menu > ul > li {
		&[data-command=""],
		.external-links {
			.surface-variant;
			display: flex;
			align-items: center;

			&::after {
				.ls-fonticon;
				position: absolute;
				right: 0.5em;
				content: "";
				padding-left: 0.1em;
			}

			&:active::after, &:focus::after {
				opacity: 0.88;
			}

			&:hover::after {
				opacity: 0.92;
			}

			&[disabled="disabled"]::after {
				opacity: 0.62;
			}

			&[disabled="disabled"]:hover::after {
				opacity: 0.62;
			}

			&.large::after {
				font-size: 2rem;
			}

			&::after {
				content: "open_in_new";
			}

			&:hover::after {
				color: @brush_linkHover;
				pointer-events: none;
			}
		}

		.external-links .buttonlink {
			width: 100%;

			a {
				margin-right: 2.4em;
			}
		}
	}
}

	.ls-toolbar-scenario {
		position: sticky;
		top: 0;
		z-index: 2;
		min-width: 10rem;
		max-width: 100%;
		box-sizing: border-box;
		margin: 0 auto;
		padding: 0 0.5rem;
		overflow-x: auto;
		flex-wrap: nowrap;
		border-bottom: solid 1px #ddd;

		.ls-std-rowblock-ltr {
			margin-bottom: 0;
		}
	}

	@skemaforside-grid-width: 98vw;
	@skemaforside-grid-filters-width: 252px;
	/*@skemaforside-grid-details-width: 530px;*/
	@skemaforside-grid-details-width: 420px;
	@skemaforside-grid-skema-width: calc(@skemaforside-grid-width - (64px + @skemaforside-grid-filters-width + @skemaforside-grid-details-width));



.display-grid-skemaforside {
	display: grid;
	width: @skemaforside-grid-width;
	margin-top: 0.5rem;
	/*grid-template-columns: 240px minmax(auto, 3fr) max-content;*/
	grid-template-columns: @skemaforside-grid-filters-width minmax(auto, 3fr) @skemaforside-grid-details-width;
	grid-template-rows: auto;
	grid-template-areas: 'filters skema details';

	&.ls-sidepanel-toggle {
		grid-template-columns: 0 minmax(auto, 3fr) 0;

		.grid-area-filters,
		.grid-area-details {
			display: none;
		}

		.grid-area-skema {

			.ls-skema-scroll-horizontal {
				width: calc(@skemaforside-grid-width - 64px);
			}
		}
	}


	.grid-area-filters {
		grid-area: filters;
		margin-right: 1rem;
		overflow-x: hidden;


		option {
			vertical-align: middle
		}
	}



	.grid-area-skema {
		grid-area: skema;
		border-left: solid 1px transparent;
		padding: 0.25rem;
		overflow-x: hidden;


		.ls-skema-scroll-horizontal {
			width: @skemaforside-grid-skema-width;
			overflow-x: auto;
			/* TEST scrollbar top **********/
			/*transform: rotateX(180deg);
			& >:first-child {
				transform: rotateX(-180deg);
			}*/
			/*********************************/

			.s2skyggeSkemabrik {
				a.s2ambient {
					cursor: pointer;
				}
			}
		}
	}

	.grid-area-details {
		grid-area: details;
		margin-left: 1rem;
		padding: 0.25rem;
		height: 100%;

		.ls-skemabrik-credit-area {
			width: 30rem;
			overflow-x: auto;
		}

		.ls-skemabrik-credit-teacher, .ls-skemabrik-credit-hold {
			min-width: 60%;

			tr {
				border-bottom: solid 1px @--md-sys-color-outline-variant;
			}

			th {
				vertical-align: text-top;
				font-size: 0.9em;
				text-align: center;
			}

			td {
				padding-top: 0.3rem;
				padding-bottom: 0.3rem;

				& > span {
					display: block;
				}
			}
		}

		.ls-skemabrik-credit-teacher {
			width: 100%;

			th:first-of-type {
				width: 50px;
			}
		}

		.ls-skemabrik-changes-table {
			/*max-width: 42rem;*/
			width: 30rem;

			a.s2skemabrik {
				margin-bottom: 0.3em;

				& > div {
					padding: 0.15em 0 0 0.25em;
				}
			}

			a.s2skemabrik.s2scenario:hover {
				cursor: default !important;
				opacity: 1
			}
		}

		.ls-modulregnskab-table {
			th {
				padding: 0.4rem;
			}

			td {
				vertical-align: text-top;
				text-align: right;
				max-width: 10rem;
				white-space: pre-wrap;
				word-wrap: normal;
				word-break: break-word;
				overflow-wrap: break-word;
				padding: 0.4rem;
			}

			td:nth-child(1), td:nth-child(2) {
				text-align: left;
			}
		}
	}
}


	.ls-master-pageheader {
		margin-bottom: 0
	}
	/*dbl.book*/
	.ls-skemabrik-dblbook-student {
		background-color: @color-dblbook-prio1;
		width: 0.7rem;
		height: 0.7rem;
		color: black;
		.outline-variant;
	}

	.ls-skemabrik-dblbook-teacher {
		background-color: @color-dblbook-prio2;
		width: 0.7rem;
		height: 0.7rem;
		color: black;
		.outline-variant;
	}


	.ls-skemabrik-dblbook-room {
		background-color: @color-dblbook-prio3;
		width: 0.7rem;
		height: 0.7rem;
		color: black;
		.outline-variant;
	}
	/**************************************/
	/* Miniskema ***************************/
	.ls-miniskema {
		background-color: #ffffff;
		border-collapse: collapse;
		border-width: 1px;
		border-style: solid;
		border-color: @brush_border_light;
		margin-bottom: 1rem;

		th {
			border-collapse: collapse;
			border-width: 1px;
			border-style: solid;
			border-color: @brush_border_light;
			padding: 0.3rem 0.5rem;
			text-align: center;
		}

		td {
			border-collapse: collapse;
			border-width: 1px;
			border-style: solid;
			border-color: @brush_border_light;
		}


		.ls-miniskema-db-container {
			display: flex;
			padding: 0.6rem 0.4rem;
			border: solid 2px transparent;
			margin: 0;
		}

		.ls-miniskema-position-hold {
			background-color: @--md-ref-palette-custom190; /*lyseblå baggrund*/
		}

		td:hover .ls-miniskema-db-container,
		.ls-miniskema-position-selected {
			padding: 0.6rem 0.4rem;
			border: solid 2px @--md-sys-color-primary;
			cursor: pointer;
		}

		tr:hover:not(tr:first-of-type) th {
			.primary-text;
			opacity: 0.7;
		}


		.ls-miniskema-dblbook-none {
			width: 1rem;
			height: 1rem;
			background-color: transparent; /*gennemsigtig baggrund*/
			border: solid 1px transparent;
		}

		.ls-miniskema-dblbook-student {
			width: 1rem;
			height: 1rem;
			background-color: @color-dblbook-prio1;
			.outline-variant;
		}

		.ls-miniskema-dblbook-teacher {
			width: 1rem;
			height: 1rem;
			background-color: @color-dblbook-prio2;
			.outline-variant;
		}

		.ls-miniskema-dblbook-room {
			width: 1rem;
			height: 1rem;
			background-color: @color-dblbook-prio3;
			.outline-variant;
		}
	}
	/* entity double booked******/
	div.buttonlink a:link[dblBook=true] {
		color: @color-dblbook-prio1;
	}
	/************************************/


	.ls-drag-info {
		position: absolute;
		right: 0px;
		background-color: white;
		padding-left: 0.5em;
		padding-right: 0.5em;
		border: 1px solid @--md-ref-palette-custom180;
	}


	/* ls-prikskema-************************/
.ls-prikskema {
	tr {
		&:nth-child(2n) {
			.surface-container;
		}

		& > th .ls-std-rowblock > div {
			width: 1rem;
			height: 1rem;
			margin: 0.25rem;
			margin-bottom: 0.5rem;
		}

		& > td {
			vertical-align: top;
			text-align: left;

			&:first-of-type {
				text-align: center;
				padding-top: 0.70rem;
			}

			.ls-label-block {
				height: 2rem;
				padding: 0;
				display: flex;
				align-items: center;

				& > span {
					margin: 0.25rem;
					vertical-align: middle;
				}
			}

			.ls-std-rowblock {
				display: flex;
				align-items: center;
				height: 2rem;
				margin: 0;

				& > span {
					width: 1rem;
					height: 1rem;
					border-radius: 50% 50%;
					border: solid 1px transparent;
					margin: 0.25rem;

					&.ls-prikskema-prik {
						.primary;

						&:hover {
							opacity: 0.9;
						}
					}
				}
			}
		}
	}
}



	.file-skemaforside-less-end {
		color: blue; /*  marker, not meant to be used. */
	}

	.ls-select-room-list-container {
		/*.surface-container;*/
		width: 100%;
		max-width: 15rem;
		padding: 0;

		select.ls-select-room-list {
			width: 100%;
			background-color: transparent;
			border: none;
			overflow-x: hidden;
			padding: 0;
		}

		select.ls-select-room-list option {
			background-color: transparent;
			min-height: 1rem;
			font-size: 1rem;
			.primary-text;
			padding: 0;
			max-width: 97%;
			overflow-x: hidden;
			/*override default room icon*/
			&.prepend-fonticon-room {
				.prepend-fonticon;

				&::before {
					content: "add";
					.primary-text;
				}
			}

			&:hover {
				opacity: 0.88
			}
		}

		select.ls-select-room-list option[dblBook="True"] {
			color: @color-dblbook-prio1;
		}
	}