.file-jquery-cluetip-css {
	color: blue;
}
/* global */
div.ui-cluetip {
	font-size: 1em;
}

.ui-cluetip-header,
.ui-cluetip-content {
	padding: 12px;
}

.ui-cluetip-header {
	font-size: 1em;
	margin: 0;
	overflow: hidden;
}

.cluetip-title .cluetip-close {
	float: right;
	position: relative;
}

.cluetip-close img {
	border: 0;
}

#cluetip-waitimage {
	width: 43px;
	height: 11px;
	position: absolute;
	/*  background-image: url(wait.gif); */
}

.cluetip-arrows {
	display: none;
	position: absolute;
	top: 0;
	left: -11px;
	width: 11px;
	height: 22px;
	background-repeat: no-repeat;
	background-position: 0 0;
	border-width: 0;
}

.cluetip-extra {
	display: none;
}

/* Lectio customizations */
#cluetip-close img {
	border: none;
}

.cluetip-default {
	background-color: #fffdea;
	word-wrap: break-word;
}

	.cluetip-default.ui-widget-content {
		border: 0px;
	}

.cluetip-arrows {
	top: 0px;
	background-position: 0px 0px;
}

.cluetip-default #cluetip-outer,
.cluetip-default .ui-cluetip-outer {
	margin: 0px;
	border-top: solid 1px #dae1e5;
	border-left: solid 1px #dae1e5;
	border-right: solid 1px #ffffff;
	border-bottom: solid 1px #ffffff;
	background-color: #fffdea;
	background-repeat: no-repeat;
	background-image: url('/lectio/img/cluetipbackground.gif');
	background-position: left top;
	background-repeat: repeat-x;
}

.cluetip-default h3#cluetip-title,
.cluetip-default .ui-cluetip-header {
	margin: 0px 0px 5px;
	background-color: #e0e7ed;
	color: #ffffff;
}

.cluetip-default #cluetip-inner,
.cluetip-default .ui-cluetip-content {
	padding: 6px 8px 6px 8px;
	color: #000000;
	text-align: left;
	background: #ffffff;
	border: 1px solid #b8c3cc;
}

.cluetip-default div#cluetip-close {
	margin: 0px 5px 5px;
	color: #990000;
}
