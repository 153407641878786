.file-master-less {
	color: blue;
}
@import (reference) "constants.less";
@import (reference) "framing.less";

article,
section,
aside,
hgroup,
nav,
header,
footer,
figure,
figcaption,
main {
	display: block;
}

// For js func
:root {
	--LectioJSUtils_Mobil: Desktop;
}

html {
	font-size: @ls-fontsizebase;
}

BODY {
	/*.surface;*/
	.background;
	height: 100%;
	text-align: center;
	margin: 0em;
	margin-left: auto;
	margin-right: auto;
	padding: 0em;
	font-family: 'helvetica neue', helvetica, arial, sans-serif;
	font-weight: normal;
	-webkit-text-size-adjust: none;

	/* Markering dette er ikke en driftsskole*/
	&.ikke-driftsskole-style {
		.ls-master-header-institution-name {
			color: @brush_attention;
		}
		/*  mobilvisning */
		.ls-meremenu-institution {
			color: @brush_attention;
		}
	}
}


FORM {
	text-align: left;
}

TABLE {
	@media @mobileportrait {
		table-layout: fixed;
	}

	border-collapse: collapse;
}

IMG {
	border: none;
}

INPUT,
OPTION,
SELECT,
BUTTON,
TEXTAREA {
	font-size: 1em;
	/*font-family: helvetica, arial, sans-serif;*/
	font-family: Roboto, 'helvetica neue',helvetica, arial, sans-serif;
	;
	font-weight: normal;
	box-sizing: border-box;
}

.input-base-style {
	background-color: #ffffff;
	box-sizing: border-box;
	padding-top: 0.083em;
	padding-right: 0.25em;
	padding-bottom: 0.083em;
	padding-left: 0.25em;
	min-height: @input-size;
	cursor: text;
	outline: none;
}

.input-disabled-style {
	background-color: @brush_disabled_light;
	border-color: @input-border-color-disabled;
	color: @brush_disabled_dark;
	cursor: default;
	outline: none;
}

.input-dropdown-button {
	background: white @icon_nav_down no-repeat top right;
	background-size: 1.33em;

	&:focus,
	&:hover {
		background: white @icon_nav_down_hover no-repeat top right;
		background-size: 1.33em;
		cursor: pointer;
	}

	&[disabled],
	&[disabled]:hover {
		background: @brush_disabled_light @icon_nav_down_disabled no-repeat top right;
		background-size: 1.33em;
	}
}

input[type="radio"] {
	box-sizing: border-box;
	cursor: pointer;
	vertical-align: middle;
	width: 1.16em;
	height: 1.16em;
	margin: 0.22em;

	&:focus {
		.ls-focus-base-style;
	}
}

input[type="checkbox"] {
	-webkit-appearance: none;
	cursor: pointer;
	box-sizing: border-box;
	width: 1.16em;
	height: 1.16em;
	vertical-align: middle;
	border-radius: 0.16em;
	border: 1px solid @input-border-color;
	margin: 0.22em;
	outline: none;
	background-color: #fff;
	padding-top: 0;

	&:checked:before {
		font-weight: bold;
		color: @color_lectio_Black;
		content: '\2713';
		-webkit-margin-start: 0;
		margin-left: 0.3em;
		font-size: 0.8em;
		top: -0.08em;
		position: relative;
	}

	&::-ms-check {
		color: @color_lectio_Black;
		border-color: @input-border-color;
		border-radius: 0.16em;
		padding: 0.08em;
		box-sizing: border-box;
		vertical-align: middle;
	}

	&[disabled="disabled"] {
		.input-disabled-style;
	}
}

[type="checkbox"] + LABEL {

	&:hover {
		cursor: pointer;
	}
}
[type="checkbox"] + LABEL {
	&:hover {
		cursor: pointer;
	}
}
INPUT[type="checkbox"][disabled="disabled"] + LABEL {
	&:hover {
		cursor: default
	}
}

INPUT[type="radio"] + LABEL {
	vertical-align: middle;
	min-height: 1.75em;

	&:hover {
		cursor: pointer;
	}
}
INPUT[type="radio"][disabled="disabled"] {
	.input-disabled-style;
}
INPUT[type="radio"][disabled="disabled"] + LABEL {
	&:hover {
		cursor: default
	}
}

input[type="text"], input[type="tel"],
input[type="password"] {
	.input-base-style;
	height: @input-size;
	margin-right: 0.16em;
}

textarea {
	.input-base-style;
}


.select-base-style {
	&::-ms-expand {
		display: none;
	}
	-webkit-appearance: none;
	-moz-appearance: none;
	-o-appearance: none;
	appearance: none;
	.input-base-style;
	background-image: none;
	cursor: pointer;
	overflow: auto;

	&:hover {
		cursor: pointer;
	}
}

select {
	.select-base-style;
	.input-dropdown-button;
	padding-right: 1.5em;
}

select[size],
select[multiple] {
	.select-base-style;
}


option {
	.input-base-style;
	cursor: pointer;

	&:hover {
		cursor: pointer;
	}
}


textarea,
INPUT[Type="Text"], input[type="tel"],
INPUT[Type="Password"],
INPUT[Type="checkbox"],
select {
	border: 1px solid @--md-sys-color-outline-variant;
	border-radius: 0.25em;


	&:focus,
	&:hover {
		border-color: @color_lectio_LightBlue;

		&::-ms-check {
			border-color: @color_lectio_LightBlue;
		}
	}


	&[disabled],
	&[disabled]:hover,
	&[disabled]:focus {
		.input-disabled-style;

		&::-ms-check {
			.input-disabled-style;
		}
	}
}


TEXTAREA[lectio-behavior~="autoresize"] {
	overflow-y: auto;
	overflow-x: hidden;
}




/* Dato-/ugevælger*/
.ls-datepicker {
	display: inline-flex;
	justify-content: flex-start;

	a:hover {
		background: transparent
	}

	.buttonicon {
		box-sizing: border-box;
		margin-bottom: 1px;
		margin-top: 1px;
	}

	.buttonicon a img {
		height: 19px;
		vertical-align: middle;
	}
}

.ls-datepickerbox {
	.input-dropdown-button;
	padding-right: 1.5em;
	width: 14em;
}

/*** DateTimeControlButton  *******************************************************/
.datecontrolouterspan{display:inline-flex}
.ls-datetime-button {
	background: white @icon_nav_down no-repeat center center;
	box-sizing: border-box;
	display: inline-block;
	width: @input-size;
	height: @input-size;
	vertical-align: middle;
	border-radius: 0.25em;
	border: solid 1px @--md-sys-color-outline-variant;
	padding: 1px;


	&:hover {
		background: white @icon_nav_down_hover no-repeat center center;
		cursor: pointer;
		border:solid 1px @color_lectio_LightBlue;
	}

	&[disabled],
	&[disabled]:hover {
		background: @brush_disabled_light @icon_nav_down_disabled no-repeat center center;
		border-color: @input-border-color-disabled;
		cursor: default;
	}
}

INPUT[disabled].datebox {
	background-color: Transparent;
	color: Black;
	border-style: none;
	border: none;
	width: auto;
}

.ls-listbox {
	overscroll-behavior: contain;
}

.ls-listbox,
.ls-timebox,
.ls-masterviewbox {
	.input-base-style;
	overflow: auto;

	option {
		.input-base-style;
		cursor: pointer;

		&:checked,
		&:hover {
			background-color: @color_lectio_BasicBlue;
			color: white;
			cursor: pointer;
		}
	}
}

.ls-masterviewbox {
	.select-base-style;
	border: none;

	&:focus {
		border: none;
		background-color: white;
		.ls-focus-base-style;
	}

	option {
		background-color: transparent;
		font-size: 1.1em;
		padding: 0.3em;
		margin: 0.3em;
	}
}

/* ************************************************** */
/* MASTER ************************************************ */
/* ************************************************** */
.ls-master-header {
	background-color: #dae4e9;
	background-image: url('/lectio/img/gradheader.png');
	background-position: left top;
	background-repeat: repeat-x;
	margin: 0;
	overflow: hidden;
	padding: 0.3em;
	height: 4em;
	display: flex;
	justify-content: space-between;
}


.ls-master-container1 {
	/*.surface;*/
	.background;
	text-align: left;
	padding: 0;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	display: table;
}

.ls-master-container2 {
	display: table-cell;


	.ls-master-header-institution {
		font-family: Verdana;
		font-weight: bold;
		color: @brush_text_heading;
		font-size: 0.9em;
		padding: 0.4em 0.4em 0.4em 0.4em;
		overflow: hidden;
		word-break: break-all;
		white-space: normal;
		display: block;
		background: transparent;
	}

	a.ls-master-header-logo, a.ls-master-header-logo:hover, a.ls-master-header-logo:focus {
		background-image: url('/lectio/img/logo.auto?v=1');
		background-position: right top;
		background-repeat: no-repeat;
		cursor: pointer;
		background-color: transparent;
		width: 7rem;
		height: 2rem;
		display: block;
		outline: none;
		border: none;
	}
}
.outerContentFrame {
	min-height: 80vh;
	vertical-align: top;
}



.ls-master-pageheader {
	margin-bottom: 1em;
	display: table;

	.thumber {
		display: table-cell;
		vertical-align: top;
		white-space: normal;

		IMG {
			margin-right: 0.6em;
			margin-bottom: 0.4em;
			width: 36px;
			height: 48px
		}

		.ls-identity-container {
			display: block;
			vertical-align: top;
			white-space: normal;
		}
	}

	.ls-subnav-container {
		display: block;
		vertical-align: top;
		white-space: normal;
		margin-bottom:0.5em;
	}
}



.ls-content {
		display: table-cell;
		padding: @ls-padding-page-container-top @ls-padding-page-container-right @ls-padding-page-container-bottom @ls-padding-page-container-left;
	}


.ls-master-pageheader {
	padding: @ls-padding-page-container-top @ls-padding-page-container-right @ls-padding-page-container-bottom @ls-padding-page-container-left;
}


.maintitle {
	font-weight: bold;
	font-size: @ls-h1;
	margin-bottom: 0.2em;
	color: @brush_text_heading;
	text-decoration: none;
	vertical-align: text-top;
}

.ls-master-footer {
	border-top: solid 1px #d3dae0;
	padding-top: 0.25em;
	color: @brush_text_heading;
	font-size: 0.9em;
	margin-top: 3em;
	margin-bottom: 4.3em;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

/*target screen < 1280 px */
.ls-master-container1,
.ls-master-container2 {
	width: 100%;
}

/*target screen > 1280px */
@media only screen and (min-width: 1281px) {
	body.masterbody {
		overflow-y: scroll;
	}


	.ls-master-container1,
	.ls-master-container2 {
		width: 1280px;
	}
}

.ls-hidden-bigscreen {
	display: none;
}

/****************************************************************************************/
/* Mobile-device (portrait+landscape) */

.ls-mobile {
	html {
		font-size: 100%;
	}

	menutemplate .ls-std-rowblock {
		font-size: 85%;
	}

	body.masterbody {
		.body-small;
		overflow: hidden;
	}

	.ls-master-container2 {
		.outerContentFrame {
			.ls-master-pageheader {
				display: grid;
				grid-template-columns: auto auto 1fr auto;
				grid-template-rows: 1fr auto;
				grid-row-gap: 0.2em;
				grid-template-areas:
					'headleft pic head headfunc' 'sub sub sub sub';
				grid-area: header;
				transition: background-color 0.4s ease-in-out;

				.thumber {
					grid-area: pic;
				}

				.ls-identity-container {
					grid-area: head;
				}

				.ls-subnav-container {
					grid-area: sub;
					overflow-x: scroll;
					margin-bottom: 0;

					.ls-subnav1, .ls-subnav2 {
						padding-bottom: 0.5em;
						white-space: nowrap;
						margin-bottom: 0em;
					}
				}
			}
		}
	}
	// header bgcolor when scrolling
	.ls-mobile-scrolling {
		.surface-container;
		transition: background-color 0.4s ease-in-out;
	}

	.ls-hidden-bigscreen {
		display: initial;
	}

	.ls-hidden-smallscreen, .ls-hidden-smallscreen[class~="ls-hidden-smallscreen"] {
		display: none !important;
	}

	.ls-master-container1 .ls-master-container2 .outerContentFrame {
		padding: 0;
		margin: 0;
	}

	.ls-master-container2 .outerContentFrame .ls-master-pageheader {
		margin-bottom: 0;
		padding: 0.25rem 0.5rem;
		box-sizing: border-box;

		.header-function-buttons a:focus {
			outline: none;
		}
	}
	// Sørger for at modal-dialoger ikke fylder mere end skærmen er bred og høj,
	// og at der kan scrolles i content-delen af dialogen i tilfælde af overflow.
	// bruger "!important" her fordi jquery's .dialog laver styling direkte på elementet.
	.ui-dialog {
		max-width: calc(100dvw - 2rem) !important;
		max-height: 50dvh !important;

		.ui-dialog-content {
			max-height: 40dvh !important;
			overflow: auto;
		}
	}

	.header-left-button {
		display: table-cell;
		vertical-align: top;
		text-align: left;
		grid-area: headleft;
		align-self: center;
		padding-right: 0.35rem;

		.ls-fonticon {
			font-size: 32px;
			border-radius: 1rem;
		}
	}

	.header-function-buttons {
		display: table-cell;
		vertical-align: top;
		text-align: right;
		grid-area: headfunc;
		align-self: center;

		.ls-fonticon {
			font-size: 32px;
			padding-left: 4px;
			border-radius: 1rem;
		}
	}
	//https://m3.material.io/components/navigation-bar/overview
	.ls-mobil-menu {
		.surface;
		z-index: 12;
		bottom: 0dvh;
		left: 0dvw;
		right: 0dvw;
		//give nav it's own compsite layer
		will-change: transform;
		transform: translateZ(0);
		box-sizing: border-box;
		display: flex;

		.ls-mobil-menu-button a[href] {
			outline: none;
			border: none;
			width: 4rem;
			position: relative;

			&:link, &:visited, &:hover, &:focus, &:active {
				.label-small;
				background-color: transparent;
				display: flex;
				flex-direction: column;
				flex-flow: column wrap;
				align-items: center;
				justify-content: center;
				outline: none;
				border: none;
				.on-surface-text;

				.ls-fonticon {
					font-size: 1.6rem;
					padding: 0.1rem 0.2rem;
					width: 75%;
					text-align: center;
				}
			}

			&[current-page] {
				font-weight: 700; /*Markerer den current page i mobil menuen*/
				.on-surface-text;

				.ls-fonticon {
					background-color: #d0e6f3; /*Secondary container --md-ref-palette-secondary90:*/
					color: #0c1d29; /* on Secondary container --md-ref-palette-secondary10*/
					border-radius: 1rem;
					font-variation-settings: 'FILL' 1;
					font-weight: 300;
				}
			}

			&:active, &:focus {
				font-weight: 500;

				.ls-fonticon {
					.secondary-container;
					.secondary-container-text;
					border-radius: 1rem;
					font-variation-settings: 'FILL' 1;
					font-weight: 300;
				}
			}
		}
	}

	.ls-hidden-smallscreen, .ls-hidden-smallscreen[class~="ls-hidden-smallscreen"] {
		display: none;
	}

	.maintitle {
		.title-large;
		.on-surface-text;
		vertical-align: text-top;
		text-decoration: none;
		margin: unset;
	}

	.modal {
		display: none; /* Hidden by default */
		position: fixed; /* Stay in place */
		z-index: 13; /* Sit on top */
		width: 100%; /* Full width */
		height: 100%; /* Full height */
		overflow: auto; /* Enable scroll if needed */
		background-color: rgb(0,0,0); /* Fallback color */
		background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
	}
	//https://m3.material.io/components/bottom-sheets/guidelines
	.ls-mobil-mere-sheet-menu {
		.ls-hidden-bigscreen;
		.surface;
		border-top: solid 1px @--md-sys-color-outline-variant;
		display: flex;
		flex-direction: column;
		z-index: 13;
		position: fixed;
		top: 100dvh;
		bottom: 0dvh;
		//give nav it's own compsite layer
		will-change: transform;
		box-sizing: border-box;
		width: 100dvw;
		overflow: hidden;
		padding: 3% 3.5%;
		padding-bottom: 1rem;
		transform-origin: 0% 0%;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
		border-radius: 1.666rem 1.666rem 0 0; /* Curved corners only at the top */
		max-height: 60vh;
		min-height: 12vh;
		height: @mere_sheet_menu_height;

		.meremenu-headline-container {
			font-size: unset;
			line-height: unset;
			.surface;
			border-bottom: solid 1px @--md-sys-color-outline-variant; /* outline-variant-light */
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-block-start: 0;
			margin-block-end: 0;
			margin-bottom: 0.416rem;
			padding-bottom: 0.333rem;
			padding-top: 0.111rem;
			align-items: center;
			height: 10%;




			.ls-meremenu-institution {
				.title-small;
				
				white-space:nowrap;
				overflow-x:auto;
				
			
			}

			.ls-meremenu-settings-link {
				margin: auto 0.3rem;

				a[href] {
					.on-surface-text;
					display: flex !important;
					align-items: center;

					.ls-fonticon {
						font-size: 1.6rem;
					}
				}
			}
		}
	}

	.show-mere-sheet-menu {
		transform: translateY(-100%);
	}

	.show-modal {
		display: block;
	}
	//# aktivtets forsiden
	nav.ls-std-rowblock {
		display: none; // navbar(tilbage, skemaktivitet, holdaktivitet) -> skal nok ind i mobil topbaren.
	}

	.ls-mobil-col {
		display: flex;
		flex-direction: column;
	}
	/*#endregion */

	.mobile-page-drawer {
		.ls-card-outlined;
		position: fixed;
		top: 0;
		left: -@mobil-page-drawer-width;
		min-width: @mobil-page-drawer-width !important;
		max-width: @mobil-page-drawer-width !important;
		height: 100vh !important;
		padding: 20px;
		overflow: auto !important;
		transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0);
		z-index: 13;
	}

	.show-mobile-page-drawer {
		transform: translateX(@mobil-page-drawer-width);
	}
}



/****************************************************************************************/
/* Mobile-device-portrait */
@media @mobileportrait {
	.ls-mobile;

	// For js func
	:root {
		--LectioJSUtils_Mobil: Portrait;
	}

	.ls-mobil-menu {
		flex-direction: row;
		align-items: center;
		height: @mobil-menu-height;
		justify-content: space-between;
		width: 100dvw;
		padding-left: 0.8rem;
		padding-right: 0.8rem;
		border-top: solid 1px @--md-sys-color-outline-variant;
	}

	/* Fixed block Footer and header ************************/
	.ls-content-container {
		// scroll i contentcontaineren
		.surface;
		height: 100dvh;
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: auto 1fr auto;
		grid-template-areas:
			'header' 'main' 'nav';

		.ls-master-pageheader {
			grid-area: header;
		}

		.ls-content {
			grid-area: main;
			overflow: auto;
		}

		.ls-mobil-footer {
			grid-area: nav;
		}
	}
	//blå (Gem, Annuler, Anvend) kontrol bar
	div#fixedBlock, div.fixedBlock, div.fixedBlockMobileOnly {
		bottom: @mobil-menu-height;
		width: 98dvw;
		/*left: 2dvw;*/
		z-index: 10;
	}

	.ls-hidden-smallscreen-portrait {
		display: none;
	}
}

/* test *************************/
//blå (Gem, Annuler, Anvend) kontrol bar
div.fixedBlockMobilePortraitOnly {
	@media @mobileportrait {
		position: fixed;
		bottom: calc(@mobil-menu-height + 10px);
		width: 96dvw;
		z-index: 10;
	}
}

/* End Mobile devices portrait orientation ***********************************************/
/****************************************************************************************/
/****************************************************************************************/
/* Mobile-device-Landscape ***********************************************/


@media @mobilelandscape {
	.ls-mobile;

	// For js func
	:root {
		--LectioJSUtils_Mobil: Landscape;
	}

	.ls-mobil-menu {
		height: 100dvh;
		flex-direction: column;
		justify-content: space-between;
		width: @mobil-menu-width;
		flex-wrap: nowrap;
		border-top: none;
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		border-right: solid 1px @--md-sys-color-outline-variant;
		overflow-y: auto;

		div:last-child {
			order: -1;
		}

		.ls-mobil-menu-button a[href] {
			margin: 0.3rem 0.6rem;

			&:link, &:visited, &:hover, &:focus, &:active {

				.ls-fonticon {
					width: 75%
				}
			}
		}
	}

	.ls-mobil-mere-sheet-menu {
		max-height: 70vh;
		height: 70vh;
	}

	/* Fixed block Footer and header ************************/
	.ls-content-container {
		// scroll i contentcontaineren
		height: 100dvh;
		display: grid;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto 1fr auto;
		grid-template-areas:
			'nav header' 'nav main';
		.surface;

		.ls-master-pageheader {
			grid-area: header;
		}

		.ls-content {
			grid-area: main;
			overflow: auto;
		}

		.ls-mobil-footer {
			grid-area: nav;
		}
	}

	.ls-hidden-smallscreen-landscape {
		display: none;
	}

	div#fixedBlock, div.fixedBlock {
		left: calc(5dvw + @mobil-menu-width);
		width: calc(90dvw - @mobil-menu-width);
		z-index: 10;
	}
}

.outerContentFrame, .ls-content {
	margin-top: 0;
	padding-top: 0;
}





