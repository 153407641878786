.file-skemany-less {
	color: blue;
}

@import (reference) "constants.less";

/*****************************************************************************************************************************/
/* Skemabrikker visning andre steder end skema dvs. uden box *****************************************************************/
/*****************************************************************************************************************************/
/* Ændret brik visning uden box */
.s2skemabrik.s2changed {
	font-style: italic;
	.skemabrik-changed-text();
}

A[href].s2changed:hover .s2changed {
	color: #fff;
}
/* Aflyst brik visning uden box */
.s2skemabrik.s2cancelled {
	text-decoration: line-through;
	font-style: italic;
	.skemabrik-cancelled-text();
}

A[href].s2cancelled:hover .s2cancelled {
	color: #fff;
}


/* skemabrik i TOC på forløbsforside  **/
.s2excluded,
A.s2skemabrik.s2excluded {
	/* brik der er ekskluderet fra et forloeb. */
	.on-surface-text;
	font-style: italic;
}

.s2excluded::after,
A.s2skemabrik.s2excluded::after {
	/* brik der er ekskluderet fra et forloeb. */
	background-color: lightgoldenrodyellow;
	.on-surface-variant-text;
	font-style: italic;
	content: " (Er ikke med i forløb)";
	font-size: 0.75rem;
}

/**********************************************************************************************************/
/*  SKEMA  ********************************************************************************************/
/**********************************************************************************************************/
.s2skema {
	background-color: transparent;


	TD, TH {

		border: solid 1px @--md-ref-palette-neutral80;
		padding-left: 0;
		padding-right: 0;
	}

	.s2weekHeader {
		td {
			.on-surface-variant-text;
			text-align: center;
			vertical-align: middle;
			padding: 0.5em;
			border-bottom: solid 1px @--md-ref-palette-neutral80;
			/***/
			padding: 0 0 0.25em 0;
			background-image: none;
			background-color: transparent;
			border: none;
			.title-small;
		}
	}

	.s2dayHeader {
		TD {
			.surface;
			.on-surface-variant-text;
			text-align: center;
			font-weight: bold;
			padding-top: 0.15em;
			padding-bottom: 0.2em;
			.label-medium;
		}
	}
}

/* skema-top */
.s2skema .s2infoHeader {
	background: @--md-ref-palette-custom498;
	.on-surface-text;
	border-bottom: solid 1px @--md-ref-palette-neutral80;
	vertical-align: text-top;
	padding-right: 0.3em;
}

/*skematop separator */
.s2skema-top-separator {
	display: grid;
	grid-template-areas: "dot value" "dot undervalue";
	grid-template-columns: auto minmax(0px, 1fr);
	grid-column-gap: 2px;

	.separator-dot {
		grid-area: dot;
	}

	.separator-value {
		grid-area: value;
	}

	.separator-undervalue {
		grid-area: undervalue;
	}
}

/* skema celle (dagskolonne) baggrund */
.s2skemabrikcontainer {
	.surface-container;
}


.s2SkemaDivider {
	/* Måske dette kan fjernes ? udkommenteres indtil videre */
	/* Border mellem anden aktivitet og selve skemaet */
	/*border: 0;
	height: 0;
	background-color: transparent;*/
}

.s2skema HR {
	/* spørgsmål? hvad bruges det til  */
	width: 100%;
	margin: 0;
	margin-top: -1px;
	border: none;
	height: 1px;
	border-bottom: dotted 1px #808080;
	border-color: transparent;
}

.s2module-bg {
	/* skema modul-række  baggrundsfarve */
	.surface;
	border-top: solid 1px @--md-ref-palette-neutral90;
	border-bottom: solid 1px @--md-ref-palette-neutral90;
}


.s2time-off {
	/* Skemaets modul-baggrundsfarve for ikke-skoledage */
	.not-schoolday;
}

.s2module-info {
	/* skema modul nr.- tid tekst */
	vertical-align: text-top;
	color: #475968;
	.on-surface-variant-text;
}

.s2time {
	/* default klokkeslæt i skema*/
	color: #999999;
	font-size: 1em;
}



/**********************************************************************************************************/
/*  SKEMABRIK  ********************************************************************************************/
/**********************************************************************************************************/

/* Start standard link skemabrik ***********************************************/
/* container for hele skemabrikken  */
A.s2skemabrik {
	/* For at få brikkerne oven på tidslinier mv. */
	display: block;
	overflow: hidden;
	z-index: 1;
}
/* skematop skemabrikker der vises i dagsbemærkninger */
.s2skema .s2infoHeader {
	A.s2skemabrik {
		margin-top: 0.4em;
		margin-bottom: 0.4em;
		padding-left: 0.5em;
	}

	A[href].s2skemabrik:hover {
		background: unset;
		cursor: pointer;
	}

	A[href].s2normal:hover .s2skemabrikcontent {
		.skemabrik-normal-text();
		opacity: 0.68;
	}

	A[href].s2changed:hover .s2skemabrikcontent {
		.skemabrik-changed-text();
		opacity: 0.68;
	}

	A[href].s2cancelled:hover .s2skemabrikcontent {
		.skemabrik-cancelled-text();
		opacity: 0.68;
	}
}
/* skemabrikker der vises i skemaet */
.s2skemabrikInnerContainer
{
	/* skemabrik container omkring header og content*/
	display: block;
	text-align: left;
	margin: 0;
	height: 100%;
	padding: 0.15em 0 0 0.25em;
	box-sizing: border-box;

	.s2skemabrikcontent {
		/* vigtig - eftersom der benyttes float på ikoner, som der skal kunne klikkes på*/
		display: inline;
	}

	.s2timeline {
		/* vigtig - eftersom der benyttes float på ikoner, som der skal kunne klikkes på*/
		display: inline;
	}

	.s2skemabrikIcons {
		/* vigtig - eftersom der benyttes float på ikoner, som der skal kunne klikkes på*/
		display: inline;
	}
}
/* skemabrik ikoner uanset hvor de vises */
.s2skemabrikIcons {
	float: right;
	margin-right: 0.15em;
	margin-top: 0.15em;

	.ls-fonticon {
		font-size: 1rem;
		font-weight: 300;
		font-variation-settings: 'FILL' 1;
	}

	.ls-note {
		color: @--md-ref-palette-custom495;
		-webkit-text-stroke-width: 0.05rem;
		-webkit-text-stroke-color: @--md-sys-color-outline;
	}

	.ls-lektier {
		color: white;
		-webkit-text-stroke-width: 0.05rem;
		-webkit-text-stroke-color: @--md-sys-color-outline;
	}

	.ls-privataftale {
		color: @--md-ref-palette-neutral50;
	}

	.ls-rediger {
		.primary-text;
		font-size: 1.2rem;
		border-radius: 0.2rem;

		&:hover {
			background: #fff;
			opacity: 0.68;
		}
	}
}


.s2skemabrik-std-title {
	white-space: nowrap;
}
/************************************************************************/
/* Skygge-skemabrik viser ledige positioner for læreres hold ************/
/************************************************************************/
A.s2ambient {
	.on-surface-variant-text;

	&:hover {
		.on-surface-variant-text;
		background: unset;
	}
}

.s2skyggeSkemabrik {
	.skemabrik-box();
	border-left: solid 2px @--md-ref-palette-neutral80;
	display: block;
	overflow: hidden;
	z-index: 1;
	/* For at få brikkerne oven på tidslinier mv. */
	margin-bottom: 0.2em;
	height: 100%;

	&:hover {
		z-index: 2;
		opacity: 1;
		/* for at kunne få vist alt indhold ved hover når der er mange hold */
		height: auto !important;
	}
}

.s2skyggeSkemabrikInnerContainer {
	display: block;
	padding: 0.1em;
	text-align: left;
	margin: 0;
	height: 100%;
	background-color: @--md-ref-palette-neutral95;
	.on-surface-text;

	&:hover {
		z-index: 3;
	}
}
/*******************************************************************************/
/* Skemabrik typer normal, ændret, aflyst, eksamen *****************************/
/*******************************************************************************/
A.s2skemabrik.s2bgbox.s2normal {

	.skemabrik-normal();


	.s2timeline {
		.skemabrik-normal-text();
	}
}
A.s2skemabrik.s2bgbox.s2optional {
	.skemabrik-optional();


	.s2timeline {
		.skemabrik-optional-text();
	}
}
A.s2skemabrik.s2bgbox.s2changed {
	.skemabrik-changed();

	.s2timeline {
		.skemabrik-changed-text();
	}
}

A.s2skemabrik.s2bgbox.s2changed.s2optional {
	.skemabrik-optional();
	.skemabrik-changed-text();

	.s2timeline {
		.skemabrik-changed-text();
	}
}


A.s2skemabrik.s2bgbox.s2cancelled {
	.skemabrik-cancelled();

	.s2timeline {
		.skemabrik-cancelled-text();
	}
}

A.s2skemabrik.s2bgbox.s2bgboxeksamen {
	.skemabrik-exam();

	.s2timeline {
		.skemabrik-exam-text();
	}
}

A.s2skemabrik.s2bgbox.s2scenario {
	.skemabrik-scenario();

	.s2timeline {
		/*.skemabrik-scenario-text();*/
	}
}

A.s2skemabrik.s2bgbox.s2selected {
	.skemabrik-selected();
}
A.s2skemabrik.s2bgbox.s2defocus {
	.skemabrik-defocus();
}


/* ved hover ********************************************************/
A[href].s2skemabrik.s2bgbox .s2skemabrikInnerContainer:hover {
	background: none;

	.s2timeline {
		opacity: 0.68;
	}

	.s2normal {
		.skemabrik-normal-text();
		opacity: 0.68;
	}

	.s2changed {
		.skemabrik-changed-text();
		opacity: 0.68;
	}

	.s2cancelled {
		.skemabrik-cancelled-text();
		opacity: 0.68;
	}

	.s2bgboxeksamen {
		.skemabrik-exam-text();
		opacity: 0.68;
	}

	.s2scenario {
		.skemabrik-scenario-text();
		opacity: 0.68;
	}
}

/*****************************************************************************************************************************/
/* skema marking - Rediger aktivitet - Skema moduler - Hvilke moduler der er optaget/ikke optaget af lærer, elev, lokale *****/
/*****************************************************************************************************************************/
.s2marking {
	cursor: pointer;
}

.s2markingstudent {
	background-color: #f13f31;
	background-image: -webkit-gradient(linear, 0% 0%, 0% 72%, from(#f13f31), to(#ff4e33));
	background-image: -moz-linear-gradient(0% 72% 90deg, #ff4e33, #f13f31);
}

.s2markingteacher {
	background-color: #ffa91a;
	background-image: -webkit-gradient(linear, 0% 0%, 0% 72%, from(#ffa91a), to(#ffb31a));
	background-image: -moz-linear-gradient(0% 72% 90deg, #ffb31a, #ffa91a);
}

.s2markingroom {
	background-color: #ffdb09;
	background-image: -webkit-gradient(linear, 0% 0%, 0% 72%, from(#ffce0a), to(#ffdb09));
	background-image: -moz-linear-gradient(0% 72% 90deg, #ffdb09, #ffce0a);
}

.s2markingstudent,
.s2markingteacher,
.s2markingroom {
	border-radius: 0.2em;
	box-shadow: -0.1em -0.1em 0.1em rgba(250, 250, 250, 1);
}

.s2bgbox[class~="s2marking"]:hover {
	opacity: 0.68;
}


/*****************************************************************************************************************************/
/*Dette er ift. skemaUger */
/*****************************************************************************************************************************/
A.s2skemabrik.s2scenario {
	.skemabrik-scenario();
}
/*****************************************************************************************************************************/
/* Vælg fravælg entiteter til aktivitet / skemabrik */
/*****************************************************************************************************************************/

.ls-skemabrik-choose-entity-table {
	width: 100%;

	td {
		min-width: 10rem;

		&.ls-skemabrik-choose-entity-tablecell-suggestions {
			min-width: 15rem;
			max-width: 50%;
			padding-right:0.35rem;
			.ls-skemabrik-choose-entity-tablecell-suggestions-container {
				max-height: 12rem;
				overflow-y: auto;
				overscroll-behavior-y: contain;

			}
		}

		box-sizing: border-box;
		padding: 0.25em 0;
		vertical-align: text-top;

		.ls-skemabrik-choose-entity-table-entitylist {
			box-sizing: border-box;
			overflow-x: hidden;

			ul {
				padding-bottom: 0.25rem;

				li {
					a:hover {
						opacity: 0.8;
					}
				}
			}


			&.ls-skemabrik-choose-entity-table-entitylist-hold ul > li {
				.prepend-fonticon-hold;

				.ls-fonticon {
					visibility: hidden;
				}

				&:hover .ls-fonticon {
					visibility: visible;
					color: @color_lectio_Red;
				}
			}

			&.ls-skemabrik-choose-entity-table-entitylist-teacher ul > li {
				.prepend-fonticon-teacher;

				.ls-fonticon {
					visibility: hidden;
				}

				&:hover .ls-fonticon {
					visibility: visible;
					color: @color_lectio_Red;
				}
			}

			&.ls-skemabrik-choose-entity-table-entitylist-student ul > li {
				.prepend-fonticon-student;


				.ls-fonticon {
					visibility: hidden;
				}

				&:hover .ls-fonticon {
					visibility: visible;
					color: @color_lectio_Red;
				}
			}

			&.ls-skemabrik-choose-entity-table-entitylist-room ul > li {
				.prepend-fonticon-room;

				.ls-fonticon {
					visibility: hidden;
				}

				&:hover .ls-fonticon {
					visibility: visible;
					color: @color_lectio_Red;
				}
			}

			&.ls-entity-suggestions-list {
				margin-bottom: 0.2rem;

				ul {
					overflow-x: hidden;

					li {
						white-space: nowrap;
					}
				}
			}
		}
	}
}