.file-lectio-less {
	color: blue;
}

@import "buttons.less";
@import "utilities.less";
@import "lc-new.less";
@import "framing.less";
@import "diagrams.less";

img.login-mitid-logo {
	margin-top: 60px;
}
div.buttonicon a:link:has(img.login-mitid-logo) {
	text-align: center;
	margin-left: -0.125em;
	display: block;
	height: 0;

	&:focus {
		outline: none;
	}
}


/***Progress bar******************************************/
.ui-progressbar {
	height: 22px;
	overflow-x: hidden;
	overflow-y: hidden;
	box-sizing: border-box;
	border-radius: 3px;
	border: none;
	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.09);
}

	.ui-progressbar .ui-progressbar-value {
		background-color: @color_lectio_Green;
		background-image: url('/lectio/img/pbar-ani.gif');
		margin: 0 !important;
		box-sizing: border-box;
	}
	/***END Progress bar******************************************/
	/* Lists ****************************************************/
	/* Unordered List bullit style */
	.ls-ul-bullit {
		margin-left: 1rem;

		li {
			list-style-type: none;
			line-height: 1.333em;
			margin-top: 0.25rem;
			margin-bottom: 0.25rem;
			padding-left: 0.5rem;
			background-image: none;

			&::marker {
				.ls-fonticon;
				.ls-fill-fonticon;
				content: "fiber_manual_record";
				font-size: 0.9rem;
			}
		}
	}



	UL.ls-std-list-nobullit {
		margin-left: 0;
	}

	UL.standardlist {
		.ls-ul-bullit;

		li {
			&::marker {
				color: @brush_infotext;
			}
		}
	}


	UL.infoText {
		.ls-ul-bullit;

		LI {
			&::marker {
				color: @brush_infotext;
			}
		}
	}

	UL.attention {
		.ls-ul-bullit;

		LI {
			&::marker {
				color: @brush_attention;
			}
		}
	}

	UL.alert,
	UL.warning {
		.ls-ul-bullit;

		LI {
			&::marker {
				color: @brush_alert;
			}
		}
	}



	UL.linklist {
		.ls-ul-bullit;

		LI {
			&::marker {
				.primary-text;
			}
		}
	}
	/* imgage bullit skal tilrettes på et tidspunkt, anvendes på UVB docs  */
	UL.linklist-img LI {
		list-style-type: none;
		background-repeat: no-repeat;
		background-position-y: top;
		background-size: 1.333em 1.333em;
		line-height: 1.333em;
		vertical-align: middle;
		margin-top: 0.2em;
		margin-bottom: 0.2em;
		padding-left: 1.5em;

		A {
			vertical-align: middle;
		}
	}

	ul.linklist-horizontal {
		max-width: 75em;
		display: flex;
		flex-wrap: wrap;

		li:not(:first-child) {
			list-style-type: none;
			line-height: 1.333em;
			display: inline-flex;
			align-items: center;

			&::before {
				.ls-fonticon;
				.ls-fill-fonticon;
				content: "fiber_manual_record";
				font-size: 0.9rem;
				padding-left: 0.6em;
				padding-right: 0.6em;
			}
		}
	}
	/* End Lists ************************************************************/
	/* Column list ****************************************************/
	.ls-columnlist {
		.surface;
		box-sizing: border-box;
		min-width: 70em;
		border-top: solid 1px #d3dae0;
		column-count: 3;
		column-gap: 22px;
		margin-bottom: 3em;
		display: inline-block;

		@media @mobile {
			border-top: none;
			margin-bottom: unset;
		}
	}

	.ls-columnlist.mod-narrow {
		column-count: 6;
	}


	.ls-columnlist > li {
		-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
		page-break-inside: avoid; /* Firefox */
		break-inside: avoid; /* IE 10+ */
		overflow: hidden;
	}

	.ls-columnlist.mod-onechild > li > * {
		margin: 0;
		padding: 0.4em;
		border: solid 1px #d3dae0;
		border-top: 0;
		display: block;

		@media @mobile {
			border-color: @--md-ref-palette-neutral95;
			border-top: none;
			border-left: none;
			border-right: none;
		}
	}


	@media (max-width: 800px) {
		.ls-columnlist {
			min-width: 50em;
			column-count: 2;
		}
	}

	@media (max-width: 800px) {
		body.ls-print .ls-columnlist {
			column-count: 3;
		}
	}

	@media (max-width: 1000px) {
		.ls-columnlist.mod-narrow {
			column-count: 4;
		}
	}

	@media (max-width: 700px) {
		.ls-columnlist.mod-narrow {
			min-width: 290px;
			column-count: 2;
		}
	}

	@media (max-width: 700px) {
		body.ls-print .ls-columnlist.mod-narrow {
			column-count: 3;
		}
	}

	@media (max-width: 479px) {
		.ls-columnlist {
			min-width: 290px;
			max-width: 99%;
			column-count: 1;
		}
	}
	/* End Column list ************************************************************/
	/* **** SELECT BOX **** */
	.isadmin {
		background-color: #8080ff;
	}

	.groupbuiltin {
		background-color: @brush_bg_highlight;

		&.ac_over {
			background-color: #005fb3;
		}
	}

	.groupuser {
		background-color: @color_lectio_LightGreen;
	}
	/********************************************/
	/* SAMLET HOLDRAPPORT & VIKARFINDER		*/
	tr.rapAfvig2 td,
	.rapAfvig2,
	tr.rapAfvigukendt td,
	.rapAfvigukendt {
		background-color: @color-warning-high;
		color: #000000;
	}

	tr.rapAfvig1 td,
	.rapAfvig1 {
		background-color: @color-warning-low;
		color: #000000;
	}

	tr.rapAfvig0 td,
	.rapAfvig0 {
		background-color: @color-warning-no;
		color: #000000;
	}
	/***********************************************/
	/* Anvendes i Frie lærere, lokaler, ressourcer*/
	TABLE TR TD.ledige_emner_ledig {
		background-color: @color-ledig;
		width: 1.2em;
		padding: 0.25em;
		text-align: center;
	}

	TABLE TR TD.ledige_emner_dobbeltbooked {
		background-color: @color-dblbook-prio1;
		width: 1.2em;
		padding: 0.25em;
		text-align: center;
	}

	TABLE TR TD.ledige_emner_overbookcapacity {
		background-color: @color-dblbook-prio3;
		width: 1.2em;
		padding: 0.25em;
		text-align: center;
	}

	TABLE TR TD.ledige_emner_optaget {
		/*color: @color_lectio_LightBlue;*/

		width: 1.2em;
		padding: 0.25em;
		text-align: center;
	}
	/***********************************************/

	.ls-tidsreg-gray {
		background-color: @color_lectio_LightGray;
	}

	.ls-tidsreg-green {
		background-color: @color_lectio_Green;
	}

	.ls-tidsreg-dagsdato {
		td {
			font-weight: bold;
		}
	}


	pre {
		font-family: Consolas;
	}


	SPAN.radioGreen {
		background: @color_lectio_Green;
	}


	.ls-font-monospace,
	.ls-font-monospace * {
		font-family: Consolas, "Courier New", Courier, Monaco, Monospace;
	}
	/* Start - Horisontal linkliste ******************************* */
	UL.entitylinklistH {
		border-collapse: collapse;
		margin: 0em;
		padding: 0em;
		width: 100%;
		max-width: 75em;

		li {
			list-style-type: none;
			line-height: 1.333em;
			margin-top: 0.25rem;
			margin-bottom: 0.25rem;
			padding-left: 0.5rem;
			background-image: none;
			display: inline;
			border-collapse: collapse;

			a {
				text-decoration: none;
				padding: 0em 0.55em 0em 0em;
			}
		}

		IMG {
			margin: 0em 0.45em 0.3em 0em;
			vertical-align: middle;
			width: 0.8em;
			height: 0.8em;
		}
	}
	/* End - Horisontal linkliste **********/
	/***************************************************************/
	/********************************************/
	/* KONSULTATIONER General */
	DIV#toggleCss tr.afvig2 td,
	DIV#toggleCss .afvig2,
	DIV#toggleCss tr.afvigukendt td,
	DIV#toggleCss .afvigukendt
	/*  Ønske uopfyldt*/ {
		background-color: @color_lectio_Yellow;
		color: #000000;
	}


	DIV#toggleCss tr.afvig0 td,
	DIV#toggleCss .afvig0
	/* - Ønske opfyldt*/ {
		background-color: @color_lectio_Green;
		color: #000000;
	}
	/* KONSULTATIONER EDITMODE , VIEWMODE */
	DIV.editCons tr.afvig0 td,
	DIV.editCons .afvig0,
	DIV.editCons .slotItem,
	DIV.editCons tr.afvig2 td,
	DIV.editCons .afvig2,
	DIV.editCons tr.afvigukendt td,
	DIV.editCons .afvigukendt
	/* konsultationer i edit mode */ {
		cursor: pointer;
	}

	DIV.viewCons tr.afvig0 td,
	DIV.viewCons .afvig0,
	DIV.viewCons .slotItem,
	DIV.viewCons tr.afvig2 td,
	DIV.viewCons .afvig2,
	DIV.viewCons tr.afvigukendt td,
	DIV.viewCons .afvigukendt {
		/* konsultationer i view mode */
		cursor: help;
	}


	.SelectedItem {
		/* valgt element */
		background-color: @color_BlueWeakStronger !important;
		cursor: move !important;
	}

	.ConsWait {
		cursor: wait;
	}

	.DragOver {
		/* træk slip element */
		background-color: @brush_linkBackgroundHover !important;
	}
	/********************************************/




	.ls-user-name {
		padding: 0.4em 0.8em 0.4em 0.8em;
		font-weight: normal;
	}
	/* ******************************************** */
	/* Studieplan	*/
	/* ******************************************** */
	/* Ved standardvisning */
	.ganttTable {
		border: solid 1px #c2c8cc;
		border-collapse: collapse;
	}

	.ganttHeader {
		border: solid 1px #c2c8cc;
		height: 2em;
		background-image: @gradient_bg_discrete;
		background-repeat: repeat-x;
		background-position: left top;
	}

	.ganttHeader TH {
		border-left: solid 1px #c2c8cc;
		border-right: solid 1px #c2c8cc;
	}



	TR.Week TD {
		border: solid 1px #c2c8cc;
		text-align: center;
	}

	TR.GanttRow TD {
		height: 2em;
		vertical-align: top;
		margin-right: 0;
		border-top: solid 1px #c2c8cc;
	}
	/* holdrækker */
	.RowTitle {
		white-space: nowrap;
		border: solid 1px #c2c8cc;
	}
	/* hold titel kolonne */

	.ganttPhaseTxt {
		position: absolute;
		padding-left: 0.1em;
		white-space: nowrap;
		overflow: hidden;
		text-align: left;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
	}

	.ganttExercise {
		cursor: help;
		margin-top: 1.2em;
		position: absolute;
	}

	.StartOfWeek {
		border-left: dotted 1px #9da2a6;
	}
	/*ugekolonne start*/


	TD.EndOfInterval {
		border-left: solid 1px #9da2a6;
	}
	/* markering af slut på forløb */

	TD.DeviateN
	/* Grafisk visning af forløb, baggrund*/ {
		background-color: #ebf4ff;
	}

	TD.Overlap
	/* mørkgrå - ugedag subcelle flere samtidige forløb grå baggrundsfarve */ {
		background-color: #727272;
		background-image: @gradient_bg_darkGray;
		background-repeat: repeat-x;
		background-position: left top;
	}
	/* Ved vis afvigelse */
	/* lysgrå - hvis der ikke er angivet lektionsestimat for forløbet*/
	TD.Interval {
		background-color: #e0e0e0;
		background-image: @gradient_bg_lightGray;
		background-repeat: repeat-x;
		background-position: left top;
	}

	TD.DeviateA {
		background-color: #00ff00;
	}
	/*0 - 1 lektioners afvigelse*/
	TD.DeviateB {
		background-color: @color_lectio_Yellow;
	}
	/*2 - 3 lektioners afvigelse. */
	TD.DeviateC {
		background-color: @color_lectio_Red;
	}
	/* 4+ lektioners afvigelse.  */
	/* ******************************************** */
	/* Studieretninger Administrations-del styles 	*/
	/* ******************************************** */

	#krop #studieTabel {
		min-width: 78%;
		max-width: 700px;
		border-collapse: collapse;
	}

	#krop img {
		border: none;
	}

	#krop a {
		color: #000000;
	}

	.admBoxRekke {
		margin: 5px;
	}

	.side {
		font-size: 1.4em;
		text-align: center;
		width: 620px;
	}

	.rekkeTop,
	.rekke,
	.rekkeTop *,
	.rekke * {
		text-align: center;
		font-style: normal;
		width: 50px;
		border: #999999 1px solid;
		background-color: #eeeeee !important;
	}

	select.fagDropDown,
	select.fagDropDown * {
		width: 100%;
		background-color: #ffffff;
	}

	.noStyle a {
		text-decoration: none;
		font-size: 1em;
	}

	.noStyle a:hover {
		background-color: #0e65ba;
		/* H208 S100 B70 */
		text-decoration: none;
		font-size: 1em;
		color: #ffffff !important;
	}

	.valg {
		height: 20px;
	}

	TD.fagSelectlist SELECT {
		/*width:280px;*/
		width: 100%;
		box-sizing: border-box;
	}
	/* ************************************************** */
	/* SLUT PÅ Studieretninger Administrations-del styles */
	/* ************************************************** */
	/* ---------------------------------------------------------------------------- */
	/* START		Mapper + Beskeder + Dokumenter */
	/* ---------------------------------------------------------------------------- */


	.ls-documentchooser-container {
		margin: 0;
		border-width: 0;
		border-top: 0px solid @brush_border_light;
		padding: 0;
		min-width: 86em;
		padding-bottom: 0.4em;
		.floatContainer;
		vertical-align: text-top;
	}

	.documentchooser-foldertree-col {
		float: left;
		height: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		overflow-y: visible;
		overflow-x: hidden;
		vertical-align: top;
		min-width: 210px;
		width: auto;

		@media @mobile {
			// sørg for at træstrukturen ikke fylder for meget
			overflow: auto;
			max-width: 30%;
		}
	}

	.ls-documentchooser-splitter {
		background: @brush_border_light;
		width: 1px;
		float: left;
		margin-top: 1em;
		height: 95%;
	}

	.documentchooser-foldercontent-col {
		padding-left: 2em;
		padding-right: 2em;
		overflow-y: auto;
		overflow-x: auto;
		height: 100%;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
	/*start lectiofoldertree2*/
	.ls-foldertree2 {
		vertical-align: text-top;
		text-align: left;
		margin-left: 0;
		padding-right: 0.8em;
		padding-top: 1.55em;
		padding-bottom: 1.45em;
	}


	.ls-foldertree2 .TreeNode-container {
		white-space: nowrap;
		padding: 0.35em 0;
	}


	.ls-foldertree2 .TreeNode-indent {
		display: inline-block;
		height: 16px;
		vertical-align: middle;
	}

	.ls-foldertree2 .TreeNode-sublist:hover {
		background: transparent;
	}

	.ls-foldertree2 .TreeNode-chevron {
		vertical-align: middle;
	}

	.ls-foldertree2 .TreeNode {
		padding: 0.2em 0;
	}

.ls-foldertree2 .TreeNode:hover {
	/*background-color: @color_lectio_DarkBlue;*/
}

	.ls-foldertree2 .TreeNode-icon {
		vertical-align: middle;
		width: 1.25em;
	}

	.ls-foldertree2 .TreeNode-title {
		display: inline-block;
		padding: 0.1em 0.3em;
		vertical-align: middle;
	}

	.ls-foldertree2 .selectedFolder,
	.ls-foldertree2 .selectedFolder:hover {
		color: @color_lectio_DarkRed;
		background-color: transparent;
		cursor: default;
	}
	/*end foldertree2*****************************************/
	/* start file doc gridview list *****************************************/
	.ls-doclist-col-title {
		max-width: 20em;
		text-overflow: ellipsis;
		overflow: hidden;
		white-space: nowrap;
		vertical-align: middle;
	}
	/* end file doc gridview list *****************************************/
	/* start beskeder gridview list *****************************************/
	/*ulæste beskeder*/
	.unread,
	.unread A,
	.unread A:hover,
	.unread div.buttonlink A,
	.unread div.buttonlink A:hover {
		font-weight: 800;
	}

	DIV#documentContainer TD.documentFolderContent {
		border: none;
		border-left: 1px dotted @color_lectio_GrayLightBlue1;
		padding: 0em;
	}

	SPAN.documentFolderContentTitle {
		font-weight: bold;
		color: @color_lectio_GrayBlue1;
		vertical-align: top;

		@media @mobile {
			.title-medium;
			.on-surface-text;
			display: inline-flex;
		}
	}

	.documentFolderContent TABLE {
		margin-top: 2em;
	}
	/* END - Besked / Message folder content area - END  *****************/
	/************************************************************/
	/************************************************************/
	/* START - Besked / Message View, Create, Edit, Reply - START ********/
	/************************************************************/
	Table.showmessage2 {
		margin-left: 0em;
	}

	Table.showmessage2Threads {
		margin-left: 0em;
		width: 100%;
	}

	Table.showmessage2Threads TR TD.messagespacer {
		border-width: 0em;
		width: 0.9em;
		background-image: none;
		background-color: transparent;
		padding-bottom: 2.7em;
	}

	Table.showmessage2Threads TR TD {
		/* Container 1 besked  */
		border-bottom: 1px dotted #a1abb3;
		padding: 1.45em 0.55em 0.55em 0.55em;
		background-color: #ffffff;
	}

	TABLE.ShowMessage2Inner {
		/* Container 1 besked afsender Header  */
		border-collapse: collapse;
		border-bottom: solid 1px @color_lectio_Gray;
		border-right: solid 1px @color_lectio_Gray;
		border-top: solid 1px @color_lectio_GrayLightBlue1;
		border-left: solid 1px @color_lectio_GrayLightBlue1;
		margin-bottom: 0.9em;
		width: 99%;
	}

	TABLE.ShowMessage2Inner TR TD {
		border: none;
		border-collapse: collapse;
		background-color: @color_lectio_SkyBlue;
		padding: 0.4em;
	}

	TABLE.ShowMessageRecipients {
		width: 100%;
		border-collapse: collapse;
		border: none;
		margin-left: 0em;
		padding: 0em;
	}

	TABLE.ShowMessageRecipients TR TD {
		white-space: normal;
		margin-left: 0;
		padding: 0;
	}
	/* END - Besked / Message View, Create, Edit, Reply - END ********/
	/************************************************************/
	/* ********************************************************* */
	/*	   Dokument oversigt mobil								 */
	/* ********************************************************* */

	@media @mobile {
		.document-list-container {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-column-gap: 10px;
			grid-template-rows: auto 1fr;
			grid-row-gap: 0px;
			grid-template-areas: "titel dato" "navn rediger";
			padding-top: 10px;

			.document-list-filename {
				grid-area: titel;
				.label-large;
				text-overflow: ellipsis;
				width: 65dvw;
				overflow: hidden;
				padding-left: 3px;
			}
			// bottom-left
			.document-list-lastchanged {
				grid-area: navn;
				.label-small;

				span {
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.document-list-timeex {
				display: flex;
				grid-area: dato;
				justify-content: end;

				.document-list-datetime {
					display: inline;
					.body-small;
					font-size: 0.8rem;
					color: @--md-ref-palette-neutral70;
					margin-right: 0.5rem;
				}
			}

			.document-list-edit {
				grid-area: rediger;
				text-align: right;
			}
		}
	}
	/* ********************************************************* */
	/*	   END Dokument oversigt mobil								 */
	/* ********************************************************* */


	TR.dayrow TH {
		padding-top: 1px;
		color: @brush_text_heading;
		vertical-align: bottom;
		text-align: center;
	}

	TH.weeknum {
		vertical-align: middle !important;
		padding-bottom: 1px !important;
		color: @brush_text_heading !important;
	}
	/* ********************************************************* */
	/*	   Text headlines									 */
	/* ********************************************************* */
	H1,
	.h1 {
		font-size: @ls-h1;
		color: @brush_text_heading;

		@media @mobile {
			.title-medium;
			.on-surface-text;
		}
	}

	H2,
	.h2 {
		font-size: @ls-h2;
		color: @brush_text_heading;

		@media @mobile {
			.title-small;
			.on-surface-text;
		}
	}

	H3,
	.h3 {
		/*  Overskrifter inde i bokse med eller uden faneblade*/
		font-weight: bold;
		font-size: @ls-h3;
		color: @brush_text_heading;
		line-height: 1.0;

		@media @mobile {
			.label-small;
			.on-surface-text;
		}
	}

	H4,
	.h4 {
		/*  Overskrifter over eller inde i tabeller SOM er inde i bokse med eller uden faneblade*/
		font-weight: bold;
		font-size: @ls-h4;
		color: @brush_text_heading;
		margin: 0;
		padding: 0;

		@media @mobile {
			.body-small;
			.on-surface-text;
		}
	}

	label:not([for]) {
		font-weight: bold;
		white-space: nowrap;
	}

	label.ls-label-block {
		padding-top: 0.3em;
		padding-bottom: 0.3em;
		vertical-align: middle;
		display: block;
	}

	label.ls-label-normal {
		padding-top: 0.3em;
		padding-bottom: 0.3em;
		vertical-align: middle;
		font-weight: 100;
	}




	UL {
		margin-left: 4px;
		list-style-type: none;
		margin: 0;
		padding: 0;
	}
	/* ****************************************************************** */
	/* ****** Fremhævelse af bestemt information ********** */
	/* ****************************************************************** */
	.attention {
		color: @brush_attention !important;
	}
	/* bemærk, NB ... */
	.alert,
	TR.alert TH,
	TR.alert TD,
	.wizarderrortxt,
	.ValidationError,
	.warning {
		color: @brush_alert;
	}
	/* advarsel , fejl*/
	.validator {
		position: absolute;
	}

	r .validator .alert {
		background-color: #FFF;
		border: @brush_alert solid 1px;
		position: relative;
		top: -5px;
		left: -50px;
		-ms-border-radius: 4px;
		border-radius: 4px;
		-ms-border-bottom-left-radius: 0px;
		border-bottom-left-radius: 0px;
		padding: 1px 4px;
	}

	.alert[class~=validator] {
		position: relative;
		top: -2px;
	}
	/* Stjernen ved paakraevede felter. */
	.validator .attention {
		font-size: smaller;
	}

	.valerror {
		border-color: @brush_alert;
		/* aht. IE 11, der ellers giver border-width 2px naar der vises fejl?? */
		border-width: 1px;
	}

	input.valerror {
		border: 1px solid @brush_alert;
		padding: 2px;
	}
	/* Safari, Chrome... */
	input.valerror::-webkit-input-placeholder {
		color: @brush_alert;
	}
	/* Firefox 18- */
	input.valerror:-moz-placeholder {
		color: @brush_alert;
	}
	/* Firefox 19+ */
	input.valerror::-moz-placeholder {
		color: @brush_alert;
	}
	/* IE++ */
	input.valerror:-ms-input-placeholder {
		color: @brush_alert;
	}
	/* vejledende tekst, information etc...*/
	.infoText,
	.infoTextItalic {
		color: @brush_infotext;
	}

	.infoTextItalic {
		font-style: italic;
	}


	.noRecord,
	.deactivated,
	.noRecordItalic {
		color: @color_lectio_DarkGray;
		cursor: default;
	}

	.noRecordItalic {
		font-style: italic;
	}
	/* Opgaver: Registrering af skriftligt fravær * Og Alm. fravær*/
	.delivered_0, .absence_0 {
		background-color: @color-fv-0;
	}
	/*yellow*/
	.delivered_50, .absence_50, .delivered_100 {
		background-color: @color-fv-50;
	}
	/*red*/
	.notDelivered_100, .absence_100 {
		background-color: @color-fv-100;
	}

	tr:hover TD.notDelivered_100, tr:hover TD.absence_100 {
		background-color: @color-fv-100;
	}
	/* gray */
	.noDelivery {
		background-color: @color-fv-not-relevant;
	}
	/* gray */
	.AbsenceNotReleased {
		.inactive-period;
	}

	.ls-delivery-absent-table {
		min-width: 100%;
		max-width: 100%;

		th {
			min-width: 3.5rem;
			text-align: center;
		}

		td {
			text-align: center;

			& > span {
				text-align: center;
			}
		}
	}

	.ls-absence-grid-row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(2.5em, max-content));
		justify-items: center;
		align-items: center;
		max-width: 600px;
	}

	.ls-absence-grid-row > DIV,
	.ls-absence-grid-row > SPAN {
		border-radius: 0.2em;
		text-align: center;
		align-content: center;
	}

	.ls-absence-fill {
		display: block;
		width: 2em;
		margin: auto;
		border-radius: 0.2em;
	}
	/*Opgaver: plagiat min,med,max*/
	.plagiat_maximum {
		color: @color-plagiat-high;
		font-weight: bold;
	}
	/*red*/
	.plagiat_medium {
		color: @color-plagiat-medium;
		font-weight: bold;
	}
	/*orange */
	.plagiat_minimum {
		color: @color-plagiat-low;
		font-weight: bold;
	}
	/*yellow*/
	.highlighttext {
		text-decoration: none;
		color: black;
		background: @color_lectio_Yellow;
	}
	/* Fravær Dagsbaseret ***************/
	.ls-dfv-100-IG, .ls-dfv-100-F {
		.ls-chip;

		A[href], A[href]:hover {
			background-color: @color-fv-100;
			color: @--md-ref-palette-custom210;
			display: block;
			border: none;
		}
	}

	.ls-dfv-0 {
		.ls-chip;

		A[href], A[href]:hover {
			background-color: @color-fv-0;
			color: @--md-ref-palette-custom340;
			display: block;
			border: none;
		}
	}
	/* ******************************************************************************* */
	/* ****** Fremhævelse af bestemt information med bg-farve i tabelceller ********** */
	/* ******************************************************************************* */
	.alertCell,
	tr.alertCell TD {
		background-color: @brush_alert !important;
	}

	.alertCell .alert,
	.alertCell TD .alert {
		color: @color_lectio_Black !important;
	}
	/* if cell red and text has alert style then text is set to black  */
	.attentionCell,
	.attentionCell TD {
		background-color: @color_lectio_Yellow;
	}

	.attentionCell .attention,
	.attentionCell TD .attention {
		color: @color_lectio_Black !important;
	}
	/* if cell yellow and text has attention style then text is set to black  */
	.normalCell,
	.ls-bg-white {
		background-color: RGB(255, 255, 255);
	}
	/* White*/
	.transparentCell {
		background-color: transparent;
	}

	.freeCell,
	.FreeSlot {
		background-color: #FFFFFF;
	}
	/**************************************************/
	/* White - FreeSlot anv. i konsultationer, vælg modul under opret lektion m.fl.)*/
	.occupiedConsultationCell,
	.occupiedConsultationCellCurrent {
		background-color: @--md-ref-palette-custom240 !important;
	}
	/* Dark red - OccupiedConsultationSlot anvendes i konsultation hvor cellen er
 * optaget af en anden lærergruppe med mindst en af de samme medlemmer */
	.alertConsultationCell,
	.alertConsultationCellCurrent {
		background-color: @brush_alert !important;
	}
	/* Red - AlertConsultationSlot anvendes i konsultationer til at indikere at den
 * valgte lærer er optaget af anden aktivitet */
	.discreteCell {
		background-color: #fcfcfc !important;
	}


	.sumCell {
		background-color: @brush_bg_highlight;
	}

	TD.numCell,
	TR.numCell TD,
	TD.numCell INPUT,
	TR.numCell TD INPUT,
	INPUT.numCell {
		text-align: right;
	}

	.locked {
		background-color: @color_lectio_Gray;
	}
	/* ************************************************ */
	/*		Table adjustments   						*/
	/* ************************************************ */
	/* dark tabel - til at få mørkere streg på tabels kanter hvis standard ikke er god nok*/
	Table.dark_streg {
		border: solid 1px #85898c;
		border-collapse: collapse;
		margin: 0.5em auto;
		background-color: #ffffff;
	}

	.dark_streg td,
	.dark_streg th {
		/* til indramning af celler i tabeller med data */
		border: solid 1px #85898c;
		border-collapse: collapse;
		padding: 0.1em 0.2em 0.1em 0.2em;
	}
	/**************************************************************/
	/**************************************************************/
	/************************************************* */
	/* Holdpaknings pakkeliste rækker */
	.packColor1 {
		background-color: #f7f9fa;
	}

	.packColor2 {
		background-color: #fff;
	}
	/************************************************* */
	/* Start uvb*/
	td.uvbtitle {
		width: 150px;
		vertical-align: top;
		white-space: normal;
	}

	td.uvbcontent {
		max-width: 450px;
		vertical-align: top;
		white-space: normal;
		word-break: break-word;
	}
	/* End uvb*/
	/************************************************* */
	/* START - for displaying date & time in table cells, e.g. used in documentlist,messagelist  */
	.timeUtil {
		width: 100px;
		white-space: nowrap;
		display: block;
	}

	.timeUtil .today {
		text-align: left;
		float: left;
	}

	.timeUtil .weekday {
		width: 22px;
		text-align: left;
		float: left;
	}

	.timeUtil .ddmm {
		width: 36px;
		text-align: right;
		float: left;
	}

	.timeUtil .hhmm {
		width: 36px;
		text-align: right;
		float: right;
	}

	.timeUtil .line {
		float: right;
		text-align: center;
		width: 8px;
		vertical-align: middle;
	}

	.timeUtil .yyyy {
		width: 36px;
		text-align: right;
		float: right;
	}

	.age0 {
		color: #000000;
	}

	.age1 {
		color: #333333;
	}

	.age2 {
		color: #444444;
	}

	.age3 {
		color: #666666;
	}
	/* End*********************************** */
	div.helptip-button.buttonlink {
		display: inline-block;
		padding: 0;
		line-height: 0.5em;
	}

	.helptip-wrap {
		display: block;
		text-align: right;
		padding-right: 0.25rem;
	}

	.helpContainer {
		.ls-card-elevated;
		.ls-elevation-level5;
		overflow-x: hidden;
		overflow-y: hidden;
		padding-right: 0.1em;


		iframe.helptip-iframe {
			height: 45em;
			width: calc(100% - 0.2em);
			margin: 0;
			border: none;
			display: block;
			padding: 0;
		}
	}

	.helptip-content {
		background: transparent;
		padding: 0.2em;
		padding-bottom: 2em;
	}
	/*	Field layout benyttes af Report designer */
	.fieldContainer {
		border-left: solid 1px #d3dae0;
		border-bottom: solid 1px #d3dae0;
		border-right: solid 1px #d3dae0;
		background-color: #fff;
	}


	.fieldHeader {
		background-image: @gradient_bg_header;
		background-repeat: repeat-x;
		background-position: left top;
		height: 2.25em;
		border-top: solid 1px #d3dae0;
		border-bottom: solid 1px #d3dae0;
		padding-top: 0.5em;
		text-align: center;
		font-weight: bold;
		color: @brush_text_heading;
	}

	.fieldset
	/* use as container for a collection of fields*/ {
		background-image: @gradient_bg_field;
		background-repeat: repeat-x;
		background-position: left top;
		border-left: solid 1px #ccdff0;
		border-right: solid 1px #ccdff0;
		border-bottom: solid 1px #b8c3cc;
		min-height: 2.5em;
		font-weight: bold;
		color: @brush_text_heading;
		padding-right: 0.6em;
		padding-left: 0.2em;
		margin-top: 0.1em;
		cursor: pointer;
	}

	.field {
		background-image: @gradient_bg_field;
		background-repeat: repeat-x;
		background-position: left top;
		border-left: solid 1px #ccdff0;
		border-right: solid 1px #ccdff0;
		border-bottom: solid 1px #ccdff0;
		min-height: 2em;
		padding: 0em 0.5em 0em 0.4em;
		margin: 0.2em 0.2em 0em 0.8em;
		cursor: pointer;
		color: #475059;
		border-top: solid 0px transparent;
	}
	/* End*******************************************************************/
	/** MACOM helpers *****************************************************/
	BODY.macomtest {
		background-color: #8000f0;
	}
	/*, BODY.macomtest #fixedBlock*/
	/* UDV view only*/
	.debug {
		border: solid 1px #FF00FF;
	}

	.nodisplay {
		display: none;
	}
	/* hiding stuff */
	.inlinedisplay,
	.inlinedisplay A,
	.inlinedisplay DIV,
	.inlinedisplay SPAN {
		display: inline !important;
	}
	/*	Til at markere område som kun kan ses af MaCom workers */
	#macomonly_base {
		background-image: none !important;
		background-color: @color_lectio_macomonly_bg !important;
		border-color: @color_lectio_macomonly !important;
		color: @color_lectio_macomonly !important;
	}
	/*
* Indfoert aht. markdown, dvs. pt. docs.
* Burde maaske vaere paa `pre`-elementer, men 1) der er i forvejen noget
* styling paa, og 2) inline-tekst har ikke `pre`-element udenom.
*/
	code {
		font-family: Consolas, Courier New;
	}

	.macomonly,
	.macomonly .island {
		#macomonly_base;


		a,
		input.button,
		div.button,
		.button a,
		div.button a,
		span.button a,
		a.button,
		li a {
			#macomonly_base;
		}

		.buttonHover,
		.button A:hover,
		.button:hover,
		ul li a:hover,
		ul li:hover,
		a:hover,
		a span:hover,
		.islandHeaderContainer,
		.islandHeader {
			background-image: none !important;
			background-color: @color_lectio_macomonly !important;
			border-color: @color_lectio_macomonly !important;
			color: #ffffff !important;
		}
	}
	//Studiekort
	/*@keyframes flip {
	0% {
		transform: rotateY(0deg);
	}

	100% {
		transform: rotateY(179deg);
	}
}*/

	.flip-studiekort-img {
		transform: rotateY(180deg);
		//animation: 0.8s ease 0s 1 flip;
	}

	.ls-studiekort-outercontainer {
		width: 100%;
		height: 100%;

		.ls-studiekort {
			width: 128.4mm; //85.6mm
			height: 80.97mm; // 53.98mm
			perspective: 1000px; /* Remove this if you don't want the 3D effect */

			.ls-studiekort-container {
				position: relative;
				box-sizing: border-box;
				display: grid;
				width: 100%;
				height: 100%;
				.ls-elevation-level3;
				border-radius: 3.18mm;
				padding: 20px;
				background-color: @color_lectio_LightGray;
				grid-row-gap: 10px;
				grid-column-gap: 10px;
				grid-template-rows: auto 1fr;
				grid-template-columns: auto 1fr;
				grid-template-areas:
					"titel imgcontainer" "text imgcontainer" "text imgcontainer" "cr knap";

				.StudieKortTitel {
					grid-area: titel;
					text-anchor: end;
					/*border-bottom: double;
			background-color: #dae4e9;
			border-radius: 3.18mm 3.18mm 0 0;*/
					.title-large;
				}

				.StudieKortText {
					grid-area: text;
					display: grid;
					grid-template-areas:
						"skole" "navn" "alder" "logo";
					grid-template-rows: auto auto 1fr;
					overflow-wrap: anywhere;
					grid-row-gap: 10px;

					.Studiekortskole {
						grid-area: skole;
						.label-large;
						font-weight: bold;
					}

					.Studiekortnavn {
						grid-area: navn;
						.label-large;
					}

					.Studiekortalder {
						grid-area: alder;
						.label-large;
					}
				}

				.ls-studiekort-logo-container {
					display: flex;

					.ls-studiekort-logo {
						position: absolute;
						max-height: 32%;
						max-width: 21em;
					}
				}


				.ls-studiekort-img {
					position: relative;
					width: 100%;
					height: 100%;
					transition: transform 0.8s;
					transform-style: preserve-3d;
					grid-area: imgcontainer;
					display: flex;
					justify-content: center;


					.StudPic, .StudieKortQR {
						position: absolute;
						-webkit-backface-visibility: hidden;
						-moz-backface-visibility: hidden;
						backface-visibility: hidden;
						justify-self: center;
						grid-area: img;
						border-radius: 3.18mm;
						max-width: 100%;
						max-height: 100%;
					}

					.StudPic {
						align-self: end;
					}

					.StudieKortQR {
						transform: rotateY(-180deg);
						align-self: center;
					}
				}



				.ls-studiekort-knap {
					position: relative;
					grid-area: knap;
					width: 100%;
					height: 100%;
					grid-area: knap;
					display: flex;
					justify-content: center;

					.StudiekortFlipPic, .StudiekortFlipQR {
						div {
							margin: unset;
							width: 150px;

							a {
								display: flex;
								justify-content: center;
								align-items: center;
							}
						}
					}
				}

				.LectioSKcr {
					.label-small;
					align-self: end;
					grid-area: cr;
					grid-row-end: none;
				}
			}

			.ls-studiekort-inaktiv {
				position: relative;
				box-sizing: border-box;
				display: flex;
				width: 100%;
				height: 100%;
				.ls-elevation-level3;
				border-radius: 3.18mm;
				padding: 20px;
				background-color: @color_lectio_LightGray;
				align-items: center;
				justify-content: center;


				.StudieKortTitel {
					text-anchor: end;
					.title-large;
				}
			}
		}
	}

	.studentexercisectrl-table {
		.surface;

		tr > th {
			font-weight: normal;
			vertical-align: text-top;
			max-width: 75px;
			white-space: nowrap;
			overflow-x: hidden;
			text-overflow: ellipsis;
			padding: 0.45em;

			a {
				.primary-text;
			}

			&:hover a {
				background-color: transparent;
				.primary-text;
				opacity: 0.92;
			}
		}

		tr th a, tr th a:hover {
			font-weight: normal;
		}

		tr td {
			padding: 0 0.45em;
			white-space: nowrap
		}
	}

	div.studentexercisectrl-container {
		height: 25px;
		width: 75px;

		a {
			width: 100%;
			height: 100%;
			opacity: 1;
			display: flex;
			flex-direction: row;
			align-items: center;
			flex-wrap: nowrap;
			text-align: center;
			justify-content: center;

			span {
				display: inline-block;
				width: 1.5rem;
				height: 1.5rem;
				margin: auto 0.1rem;
			}

			.studentexercise-grade {
				text-align: right
			}
			/******************/
			&:hover {
				.primary-container;
				opacity: 0.92;
			}
		}
	}

	@media @mobile {
		.ls-studiekort-outercontainer {
			.ls-studiekort {
				.ls-studiekort-container {
					.StudieKortTitel {
						.label-medium;
					}

					.StudieKortText {
						span {
							.label-medium;
						}
					}

					.ls-studiekort-knap {
						.StudiekortFlipPic, .StudiekortFlipQR {
							font-size: 13px;

							div {
								margin: unset;
								width: 150px;
								align-items: center;

								a {
									display: flex;
									justify-content: center;
								}
							}
						}
					}

					.LectioSKcr {
						font-size: 12px;
					}
				}
			}
		}


		img.login-mitid-logo {
			margin-top: 15px;
		}

		div.buttonicon a:link:has(img.login-mitid-logo) {
			width: 100%;
		}
	}

	@media @mobileportrait {
		.ls-studiekort-outercontainer {
			display: flex;
			justify-content: center;

			.ls-studiekort {
				max-width: 95vw;
				width: calc(75vh/1.586);
				height: 75vh;

				.ls-studiekort-container {
					// Default studiekort værdi
					grid-template-rows: auto 1.1fr auto 0.3fr 0.6fr;
					grid-column-gap: unset;
					grid-row-gap: 5px;
					grid-template-columns: unset;
					grid-template-areas:
						"titel" "imgcontainer" "qrtext" "knap" "text" "cr";

					.ls-studiekort-logo-container {
						justify-content: center;
						display: flex;
						height: 100%;

						.ls-studiekort-logo {
							position: absolute;
							bottom: 3rem;
							/*top: 4.4em;*/
							z-index: 10;
							max-height: 18%;
							max-width: 21em;
						}
					}

					.StudieKortText {
						text-align: center;
					}

					.ls-studiekort-knap {
						.StudiekortFlipPic, .StudiekortFlipQR {
							align-self: start;
							margin-bottom: 10px;
						}
					}

					.StudieKortTitel {
						justify-self: center;
						margin-bottom: 10px;
					}

					.LectioSKcr {
						align-self: end;
						justify-self: center;
					}
				}
			}
		}
	}

	//Studiekort QRValidaterings side
	.qrVerifyItems {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		align-items: center;

		.studentPic {
			padding-bottom: 1rem;
			border-radius: 10px;
		}

		.studentName {
			font-size: 25px;
		}

		.valResText {
			font-size: 18px;
		}

		#verifyIcon {
			padding: 0.5rem;
			&::before {
				font-size: 3rem;
				line-height: 3rem;
			}
		}

		#verifyIcon.prepend-fonticon-checkmark {
			color: limegreen;
		}

		#verifyIcon.prepend-fonticon-cancel {
			color: red;
		}
	}

	// OBS: Hvis man finder et sted hvor elementer med både OnlyMobile og OnlyDesktop bliver vist,
	//		så kunne man fristes til blot at sætte !Important på nedenstående display attributer ...
	//      Det ødelægger dog alt mobilvisning, da display: revert ikke længere fungerer.
	//		Det kan derfor ikke anbefales, og istedet nødsages man nok til at anvende css specificity.
	div .s2skemabrikInnerContainer .OnlyMobile,
	.OnlyMobile {
		display: none,
	}

	.OnlyMobilePortrait {
		display: none,
	}

	.OnlyMobileLandscape {
		display: none,
	}

	@media @mobile {
		.OnlyMobile {
			display: revert,
		}

		.OnlyDesktop {
			display: none,
		}
	}

	@media @mobileportrait {
		.OnlyMobilePortrait {
			display: revert,
		}

		.OnlyDesktop {
			display: none,
		}
	}

	@media @mobilelandscape {
		.OnlyMobileLandscape {
			display: revert,
		}

		.OnlyDesktop {
			display: none,
		}
	}

	@media @mobileportrait {
		.ls-fit-mobile-viewport {
			width: 95vw;
		}
	}

	@media @mobilelandscape {
		.ls-fit-mobile-viewport {
			width: 85vw;
		}
	}

	.macomInfoBox {
		height: 36.4em;
		overflow: scroll;
	}

	.vinfo TD,
	.vinfo TH,
	.vinfo H4 {
		text-align: left;
		vertical-align: top;
		color: #000000;
	}

	.vinfo TD {
		white-space: normal !important;
	}

	.vinfo TH {
		padding-right: 1em;
	}
	/* End*******************************************************************/
	/* Månedskalender er måske unødvendigt tjek om det kan fjernes******************************/
	SPAN.dayAlign {
		width: 1.09em;
		text-align: right;
		white-space: nowrap;
	}

	TH.calendarDateInfo {
		width: 2em;
	}
	/*******************************/

	.s2-mskema A.s2skemabrik,
	.s2-mskema A[href].s2skemabrik,
	.s2-mskema A.s2skemabrik[href]:hover {
		margin-top: 0.4em;
		margin-bottom: 0.4em;
		background-image: url('/lectio/img/disc_blue.png');
		background-position: 0em 0.45em;
		background-repeat: no-repeat;
		padding-left: 0.5em;
	}
	/* Usorteret: */
	/* ? TJJJJJJJJJJJJJJJJEKKKKKKKKKKKKKKKK */
	.exercisewait {
		color: #008800;
	}

	.exercisemissing {
		color: #cc0000;
	}

	.ValidationError {
		color: Red;
	}
	/* Print styles -Print from Browser settings *******(See also: "lectioPrint.css"
 * contains styles for print via Lectio print icon)******** */
	.breakAfter {
		/* use for print */
		page-break-after: always;
	}

	.breakBefore {
		/* use for print */
		page-break-before: always;
	}

	.printedfromtext {
		/* use for print */
		display: block;
		text-align: left;
	}

	body.ls-print {

		.noprint,
		IMG.noprint {
			visibility: hidden;
			display: none;
		}
	}
	/* udskriftslister members på hold, gruppe, klasse*/
	.afkrydsningslistetomttd {
		width: 4em;
		height: 1.7em;
	}
	/* END print styles ********************************************************/
	.rotate {
		/* Safari */
		-webkit-transform: rotate(-90deg);
		/* Firefox */
		-moz-transform: rotate(-90deg);
		/* Internet Explorer */
		filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
	}
	/* ************************************************************************/
	/* EDITOR STYLES *********/
	/* ****/
	component:after {
		content: attr(uri);
	}

	component {
		content: attr(uri);
		display: block;
		border: 1px solid red;
		height: 15px;
		width: 120px;
	}
	/**************************************************************************************/
	/* Dashboard - forside																												  */
	/**************************************************************************************/
	.dashboardLinkHeader {
		display: block;
		margin: 0.2em;
		margin-top: 1em;
		border-bottom: 1px dotted @color_lectio_GrayLightBlue1;
		height: 1.333em;

		@media @mobile {
			margin-top: 0.6em;
		}
	}


	.dashboardLinkHeader A {
		height: 1.333em;
		display: block;
		color: @color_lectio_Blue;
		font-weight: bold;
		cursor: pointer;
		white-space: nowrap;
		padding-left: 0.2em;
	}

	.dashboardLinkHeader A:hover {
		height: 1.333em;
		display: block;
		color: #ffffff;
		font-weight: bold;
		white-space: nowrap;
		padding-left: 0.2em;
		background-color: @color_lectio_DarkBlue;
	}

	.dashboardLinkHeaderText {
		float: left;
		display: inline;
		font-weight: bold;
	}

	.dashboardLinkHeaderInfoText {
		float: right;
		display: inline;
		font-weight: normal;
		color: @color_lectio_GrayBlue3;
	}

	.dashboardLinkHeaderAttentionText {
		float: right;
		display: inline;
		font-weight: normal;
		color: #b80000;
	}

	.dashboardLinkHeader A:hover .dashboardLinkHeaderInfoText,
	.dashboardLinkHeader A:hover .dashboardLinkHeaderAttentionText {
		color: #ffffff;
	}

	TABLE.dashboard {
		margin-top: 0.25em;
		margin-left: 0.15em;
		margin-right: 0.15em;
		margin-bottom: 0.4em;
		clear: both;
		width: 98%;
	}
	/*	Denne kolonnetype kun en linie */
	TABLE.dashboard TD.infoCol {
		white-space: nowrap;
		text-align: left;
		table-layout: fixed;
		overflow: hidden;
	}

	TABLE.dashboard TD.iconCol {
		white-space: nowrap;
		text-align: left;
		width: 1em;
	}

	@media @mobileportrait {
		TABLE.dashboard TD.infoCol {
			text-overflow: ellipsis;
		}

		TABLE.dashboard TD.nameCol {
			width: 4em;
		}
	}

	TABLE.dashboard TD.infoCol:hover {
		background: transparent;
	}
	/* Chrome fix- en del af link hover farve blev på baggrunden*/
	TABLE.dashboard TD.nameCol {
		white-space: nowrap;
		text-align: left;
		width: 9em;
		table-layout: fixed;
		overflow: hidden;

		span {
			vertical-align: middle;
		}

		span img {
			vertical-align: top;
		}
	}

	TABLE.dashboard TD.timeCol {
		width: 8em;
		white-space: nowrap;
		text-align: right;
		color: #466c80;
	}

	TABLE.dashboard TD.countCol {
		white-space: nowrap;
		text-align: right;
		width: 4.7em;
		color: #466c80;
	}



	TABLE.dashboard IMG {
		border: none;
	}
	/* For Aktuel Information kan denne kolonnetype godt have flere linier */
	#dashboardItemAI TD.infoCol {
		max-width: 34.46em;
		white-space: normal !important;
		text-align: left;
		table-layout: auto;
		overflow: hidden;
	}

	.ls-materialbox-container {
		max-width: 100%;

		.k1,
		.k2,
		.k3 {
			background-image: url('/lectio/img/bullit_normal16x16.auto');
			background-position: left 0.3em top 0.6em;
			list-style-type: none;
			background-repeat: no-repeat;
			line-height: 16px;
			vertical-align: middle;
			padding-left: 2em;
			cursor: pointer;

			a {
				display: block;
				padding-top: 0.6em;
				padding-bottom: 0.6em;
				box-sizing: border-box;
			}

			span {
				display: block;
				padding-top: 0.6em;
				padding-bottom: 0.6em;
				box-sizing: border-box;
				margin-top: 0px;
				margin-bottom: 0px;
				cursor: default;
			}
		}

		.k1 {
			margin-left: 0.6em;
		}

		.k2 {
			margin-left: 2.3em;
		}

		.k3 {
			margin-left: 4em;
		}

		.kstrong {
			background-color: @color_BlueWeakStronger;
			border-right: solid 3px @color_lectio_Blue;
		}

		.kweak {
			background-color: @color_BlueWeak;
		}

		.kunchecked {
			background-image: url('/lectio/img/checkbox_unchecked.auto');
		}

		.kchecked {
			background-image: url('/lectio/img/checkbox_checked.auto');
			background-color: @color_BlueWeakStronger;
		}

		.kautochecked {
			background-image: url('/lectio/img/checkbox_disabled_checked.auto');
			background-color: @color_BlueWeak;
		}
		/*.kunchecked:hover, .checked:hover{
		cursor: pointer;
		background-color: #0e65ba;
		color: #ffffff;

	}*/
		.kautochecked:hover {
			cursor: auto !important;
			background-color: @color_BlueWeak;
			color: #000000;
		}

		.btnButtom {
			float: right;
		}

		.btnFixed {
			position: fixed;
			/*top:96%;*/
			bottom: 2px;
			text-align: right;
		}
	}

	UL.ls-toc-side-list {
		font-size: 12px;
		line-height: 13px;
		width: 100%;


		&.ll-with-checkboxes {
			padding-left: 17px;
		}

		&:not(.ll-with-checkboxes) LI {
			/* Den plads som tjekboksen eller ville have reserveret til det ikon som
         * vi gerne vil have plads til, reserverer vi istedet her. */
			padding-left: 17px;
		}

		LI {
			display: flex;
			align-items: baseline;
			margin-bottom: 3px;
			vertical-align: middle;
			min-height: 16px;
		}

		LI IMG {
			padding: 0;
			margin: 0;
			vertical-align: middle;
		}

		LI > div:nth-of-type(1) {
			margin-right: 2px;
			white-space: nowrap;

			IMG {
				position: relative;
				top: -1px
			}
		}

		LI > div:nth-of-type(2) {
			/*line-height: 1.15em;*/
			vertical-align: middle;
			min-height: 16px;

			a {
				min-height: 16px;
			}
		}




		LI[data-value=resource-root] > div > img[data-role=cb] {
			visibility: hidden;
		}

		LI > .ls-toc-item-buttons {
			white-space: nowrap;
			margin-left: auto;
		}

		LI[data-tocitem-variant] {
			background-repeat: no-repeat;
		}

		LI[data-tocitem-variant=h1],
		LI[data-tocitem-variant=h2],
		LI[data-tocitem-variant=h3] {
			background-image: url(/lectio/img/emptydoc.auto);
		}

		LI[data-tocitem-variant=h2] {
			margin-left: 1.2em;
		}

		LI[data-tocitem-variant=h3] {
			margin-left: 3.2em;
		}

		LI[data-tocitem-variant=h4] {
			margin-left: 5.2em;
		}

		LI[data-tocitem-variant=h5] {
			margin-left: 7.2em;
		}
		/*
		Bruger her attribut data-tocitem-variant, der minder meget om data-lc-header-variant.
		Holder dem lidt adskilte for ikke at koble toc/lectio-gui for meget
		sammen med lc-indhold, der gemmes i brugernes dokumenter.
	*/
		LI[data-tocitem-variant=activity] {
			background-image: url(/lectio/img/lesson.png);
			background-position-y: -2px;
			background-position-x: -1px;
		}

		LI[data-tocitem-variant=resources] {
			background-image: url(/lectio/img/folder.auto);
			background-position-y: -1px;
		}

		LI[data-tocitem-variant=presentation] {
			background-image: url(/lectio/img/presentation.auto);
			background-position-y: -1px;
		}

		LI[data-tocitem-variant=homework] {
			/*background-image: url(/lectio/img/emptydoc.auto);*/
			background-image: url(/lectio/img/doc-homework.auto);
			background-position-y: -1px;
		}

		LI[data-tocitem-variant=other] {
			/*background-image: url(/lectio/img/emptydoc.auto);*/
			background-image: url(/lectio/img/doc-not-homework.auto);
			background-position-y: -1px;
		}

		LI[data-tocitem-variant=teacherfeedback] {
			background-image: url(/lectio/img/question_bubble.auto);
			background-position-y: -1px;
		}

		LI[data-tocitem-variant=link] {
			background-image: url(/lectio/img/link.auto);
		}

		LI[data-tocitem-variant=holdmaterial] {
			background-image: url(/lectio/img/book.auto);
		}

		LI[data-tocitem-variant=lectiofile] {
			background-image: url(/lectio/img/file16x16.auto);
		}
	}

	.ls-materialboxToc-foldablelist {
		display: none;
	}

	.ls-toc-side-list:not(.ls-toc-with-homework-note) .ls-homework-note {
		display: none;
	}

	.ls-materialboxToc-ownedlist {
		padding-bottom: 0.5em;
	}

	.ls-materialboxToc-indentedlist {
		margin-left: 1.4em;
		word-break: break-word;
	}

	[data-role=toggle-expansion] {
		height: 16px;
		width: 16px;
		display: inline-block;
		background-position: center;
		background-repeat: no-repeat;
		cursor: pointer;
	}

	[data-role=toggle-expansion][data-expansion-action=expand] {
		background: url('/lectio/img/expandall.png');
		border: solid 1px orange;
	}

	[data-role=toggle-expansion][data-expansion-action=collapse] {
		background: url('/lectio/img/collapseall.png');
		border: solid 1px orange;
	}

	[data-role=toggle-expansion]:hover {
		opacity: 0.8;
	}

	.ls-materialboxToc-section-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
		margin: 0.2em 0;
	}
	/* Homework ****************************************/

	.ls-homework-toc {
		font-size: 12px;
		line-height: 13px;
	}

	.ls-homework {
		word-break: break-word;
		overflow-wrap: normal;
	}

	.ls-homework a {
		text-indent: -1.8em;
		padding-left: 1.8em;
		display: inline-block;
	}
	/*
.ls-homework-toc span {
	vertical-align: middle;

}*/
	.ls-homework img,
	.ls-homework a img,
	.ls-homework-toc img {
		vertical-align: middle;
		padding-right: 0;
	}

	.ls-homework-note {
		white-space: pre-wrap;
		text-indent: 0;
		word-break: break-word;
		word-wrap: normal;
		margin-left: 0;
		padding: 0.4em 0.3em 0.3em 0.5em;
		font-size: 0.9em;
		line-height: 1.1;
		min-width: 10em;
		background-color: #fdfdf9;
		border: 1px solid #ededed;
		color: #666;
	}

	.ls-homework .ls-homework-note {
		margin-left: 2.2em;
	}

	.ls-homework-sysinfo {
		.infoTextItalic;
		margin-left: 1.7em;
	}
	/* ****************************************/
	/* Queationaire / Quiz ****************************/
	@ls-questionnaire-element-width: 440px;

	.ls-questionnaire-answer-feedback-points {
		text-align: right;
		line-height: 3em;
	}

	.ls-questionnaire-question-text,
	.ls-questionnaire-answer-text,
	.ls-questionnaire-question-explanation {
		.breakword;
		max-width: @ls-questionnaire-element-width;
		width: 100%;
		height: auto;
	}

	.ls-questionnaire-answer-text,
	.ls-questionnaire-question-explanation {
		margin-bottom: 1em;
	}

	.ls-questionnaire-question-explanation {
		.infoTextItalic;
	}


	.ls-questionnaire-answer-text textarea,
	.ls-questionnaire-question-text textarea,
	.ls-questionnaire-question-explanation textarea {
		resize: vertical;
		max-height: 300px;
		min-width: @ls-questionnaire-element-width;
		width: @ls-questionnaire-element-width;
		max-width: @ls-questionnaire-element-width;
	}

	.ls-questionnaire-answer-option {
		clear: both;
		max-width: @ls-questionnaire-element-width;
		height: auto;

		@media @mobile {
			display: flex;
		}
	}

	.ls-questionnaire-answer-option input {
		float: left;
		margin-top: 0.2em !important;
		margin-right: 0.6em;
	}

	.ls-questionnaire-answer-option label {
		/*display:table;*/
		margin-left: 0.4em;
		word-break: break-word;
		word-wrap: normal;
	}

	.ls-questionnaire-answer-option label:hover {
		cursor: pointer;
		opacity: 0.9;
	}

	.ls-questionnaire-answer-option-text,
	.questionnaireanswer {
		display: table-cell;
		max-width: 340px;
		padding-bottom: 1em;
		.breakword;
	}

	.ls-questionnaire-answer-option-correct,
	.questionnairecorrectanswer {
		display: table-cell;
		padding-left: 0.4em;
		text-align: right;
		.infoTextItalic;
		font-weight: bold;
	}
	/* Queationaire feedback ****************************/
	@ls-questionnaire-element-width-feedback: 110px;

	.ls-questionnaire-assessment {
		.ls-card-filled;
		display: table-cell;
		padding-top: 0.3em;
	}

	.ls-questionnaire-feedback,
	.ls-questionnaire-answer-feedback {
		height: auto;
		text-align: left;
		max-width: @ls-questionnaire-element-width-feedback;
		box-sizing: border-box;
		.breakword;

		& textarea {
			resize: vertical;
			max-height: 300px;
			max-width: @ls-questionnaire-element-width-feedback;
		}
	}

	.ls-questionnaire-answer-feedback-points {
		& .alert[class="alert"] {
			clear: both;
			display: block;
		}

		& img {
			width: 30px;
		}
	}


	.ls-questionnaire-question-section {
		display: block;
		overflow: hidden;
		margin-left: 2em;

		@media @mobile {
			margin-left: 0.5em;
		}
	}

	.ls-questionnaire-question-section-content {
		float: left;

		& p:first-child {
			margin-top: 0;
		}
	}

	.ls-questionnaire-question-section-feedback {
		float: right;
	}

	.ls-questionnaire-hotspot-image {
		/*border: solid 1px @brush_border;*/
		padding: 0;
		box-sizing: content-box;
		background-repeat: no-repeat;
		max-height: 600px;
		max-width: 600px;
		background-size: contain;
	}

	.ls-questionnaire-hotspot-points-editable {
		cursor: crosshair;
	}

	.ls-questionnaire-hotspot-point {
		stroke-width: 4px;
		stroke: black;
		fill: white;
	}

	.ls-questionnaire-hotspot-edgepoint {
		stroke-width: 1px;
		stroke: black;
		fill: red;

		&.lc-comb-first {
			stroke-width: 2px;
		}
	}

	.ls-questionnaire-hotspot-drawing {
		cursor: crosshair;
	}

	.ls-questionnaire-hotspot-points-editable .ls-questionnaire-hotspot-point {
		/*cursor: alias;*/
		cursor: pointer;
	}

	.ls-questionnaire-hotspots-editable .ls-questionnaire-hotspot-path {
		/*cursor: alias;*/
	}

	.ls-questionnaire-hotspot-path {
		stroke-dasharray: 5;
		animation: ls-questionnaire-hotspot-dash 5s linear;
		animation-iteration-count: infinite;
		stroke: white;
		stroke-width: 2px;
		fill: transparent;
	}

	.ls-questionnaire-hotspot-pathbg {
		fill: transparent;
		stroke: gray;
		stroke-width: 1px;
	}

	@keyframes ls-questionnaire-hotspot-dash {
		to {
			stroke-dashoffset: 100;
		}
	}
	/* Mobile Queationaire/Quiz answer question page */
	@media @mobileportrait {

		@ls-questionnaire-element-width: 300px;

		.ls-questionnaire-question-section-content,
		.ls-questionnaire-question-section-feedback {
			float: none;
			display: block;
		}

		.ls-questionnaire-assessment {
			max-width: @ls-questionnaire-element-width;
			display: block;
		}

		.ls-questionare-question,
		.ls-questionnaire-question-text,
		.ls-questionnaire-answer-text,
		.ls-questionnaire-question-explanation {
			width: 100%;
			max-width: @ls-questionnaire-element-width;
		}

		.ls-questionnaire-question-text textarea,
		.ls-questionnaire-answer-text textarea,
		.ls-questionnaire-question-explanation textarea {
			min-width: 100%;
			width: 100%;
			max-width: @ls-questionnaire-element-width;
		}
	}



	body[data-theme=dark] {

		*,
		.toolbar,
		.islandHeader,
		.ls-homework-note,
		.ls-texteditor-container,
		.ls-tocContainer-outer {
			background-color: #292929;
			color: #64ADD5;
		}

		a {
			color: #42D4C5 !important;
		}
		/* Disse skal lige have samme besked med lidt stoerre bogstaver */
		.button A,
		SECTION.island .islandContent,
		.lectioTabContent {
			background-color: #292929 !important;
			color: #64ADD5 !important;
		}

		.button a {
			background-image: none !important;
		}

		.ls-paper,
		.ls-paper *,
		.ls-phase-activity,
		.ls-phase-activity *,
		.lc-display-fragment,
		.lc-display-fragment * {
			background-color: #AE8357;
			color: black !important;
		}

		.lc-display-fragment blockquote[data-lc-role=note],
		.lc-display-fragment blockquote[data-lc-role=note] * {
			background-color: initial;
			color: inherit;
		}

		.macomonly,
		.macomonly * {
			background-image: initial !important;
		}
	}
	/***************************************************/
	/* log-visning */

	.ls-log-cell > div {
		text-indent: -2em;
		vertical-align: top;
		margin-left: 2em;
	}
	/***************************************************/
	/* Skolehjem */

	.ls-skh-b-cell() {
		overflow: hidden;
		white-space: nowrap;

		& > div {
			white-space: nowrap;
		}
	}
	/** "han" */
	.ls-skh-b-han {
		.ls-skh-b-cell;
		background-color: @color_BlueWeakStronger;
	}
	/** "hun" */
	.ls-skh-b-hun {
		.ls-skh-b-cell;
		background-color: @color_lectio_Pink;
	}
	/** "fejl" */
	.ls-skh-b-fe {
		.ls-skh-b-cell;
		background-color: @color_lectio_Red;
	}

	.ls-skh-icons-display {
		display: flex;
		align-items: center;
	}


	.ls-skh-b-gantt > gahry,
	.ls-skh-b-gantt > garlow {
		display: contents;
	}

	.ls-skh-b-gantt > gahry {
		font-weight: bold;
		text-align: center;

		gd {
			padding: 0.2rem;
		}
	}

	.ls-skh-b-gantt {
		display: grid;
		grid-row-gap: 0.3em;
		/* For at 'absolute' kan virke for weekendmarkeringer. */
		position: relative;
		background: white;

		garlow gd {
			padding: 0.2rem;
			vertical-align: middle;
		}
	}
	/* maanedsnavneraekke. */
	gahry:nth-child(1) > gd {
		background: @color_lectio_LightGray;

		&:nth-child(2n) {
			background: @color_lectio_LightGray1;
		}
	}
	/* weekender eller andet, der skal tegnes oven i raekkerne. */
	.ls-skh-b-gantt > div > gacolmark {
		position: absolute;
		background-color: rgba(230,230,230,0.2);
		pointer-events: none;
		width: 100%;
		height: 100%;
	}

	garlow.ls-skh-v-irrelevant > gd:nth-child(1) {
		color: @color_lectio_DarkGray;
		background-color: @color_lectio_LightGray;
	}

	garlow.ls-skh-v-lowrel > gd:nth-child(1) {
		background-color: lightgray;
	}

	garlow.ls-skh-v-empty > gd:nth-child(1) {
		background-color: @color-ledig;
		color: #333;


		&:hover {
			opacity: 0.7;
		}
	}

	garlow.ls-skh-v-g1 > gd:nth-child(1) {
		background-color: @color_GreenWeak;
	}

	garlow.ls-skh-v-g2 > gd:nth-child(1) {
		background-color: @color_lectio_LightGreen;
	}

	garlow.ls-skh-v-g3 > gd:nth-child(1) {
		background-color: @color_lectio_Yellow;
	}

	garlow.ls-skh-v-g4 > gd:nth-child(1) {
		background-color: @color_lectio_DarkGreen;
	}

	garlow.ls-skh-v-gs > gd:nth-child(1) {
		background-color: @color_lectio_Orange;
	}

	garlow gd[data-skh-book-infos] {
		cursor: pointer;

		&:hover {
			opacity: 0.7;
		}
	}
	/***************************************************/
	/* Forløbssøgning */
	.ls-fs-searchResult, .ls-fs-searchField {
		margin-left: 55px;
		margin-top: 15px;
		position: relative;
	}

	.ls-fs-searchField {
		margin-bottom: 25px;
		margin-top: 25px;

		.ls-fs-searchbtn {
			display: inline-block;
		}

		@media @mobile {
			margin-left: unset;

			.ls-fs-searchbtn {
				margin-top: 15px;
			}
		}
	}

	div.phaseNote {
		cursor: pointer;
		margin-top: 3px;
	}

	.ls-fs-hideMostContent {
		height: 2.3em;
		overflow: hidden;
	}

	.ls-fs-showMoreLessLink {
		background-color: white;
		bottom: 0;
		cursor: pointer;
		position: absolute;
		right: 0;
		z-index: 99;
	}

	.ls-fs-showMoreLessLink:before {
		background: linear-gradient(to left, white, white 30%, transparent);
		content: ' ';
		height: 100%;
		left: -30px;
		position: absolute;
		width: 30px;
	}

	.paddedColumns {
		margin-left: -15px;
	}

	.paddedColumns td {
		padding-left: 15px;
	}

	.publishedPhase {
		float: right;
		font-size: 1.2em;
		color: #1b5c9e;
	}

	.lc-fs-display-fragment.searchResult {
		padding: 0;
		margin: 0;
		padding-top: 1.4em;
		width: 600px;
	}

	.lc-fs-display-fragment em {
		font-style: inherit;
		font-weight: bold;
	}

	.ls-fs-cs-highlights {
		max-height: 4em;
		overflow-y: hidden;
		margin-top: 0;
		overflow-x: hidden;
	}

	h2.cs-phase-heading {
		margin-bottom: 2px;
	}

	h2.cs-phase-heading a {
		text-decoration: none;
	}

	.ls-fs-cs-metadata {
		color: #006621;
		margin-top: 0;
		margin-bottom: 0;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
	/***************************************************/
	/* Bogsøgning */

	.bs-textbox {
		margin-left: 10px;
	}

	@media @mobile {
		.ls-bs-search-div {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			row-gap: 8px;
		}

		.ls-bs-textbox {
			margin-left: unset;
		}
	}
	/***************************************************/

	ul.ls-skh-b-gantt-signature {
		.ls-skh-v-empty {
			background-color: @color-ledig;
		}

		.ls-skh-b-han {
			background-color: @color_BlueWeakStronger;
		}

		.ls-skh-b-hun {
			background-color: @color_lectio_Pink;
		}

		.ls-skh-b-fe {
			background-color: @color_lectio_Red;
		}

		.ls-skh-v-gs {
			background-color: @color_lectio_Orange;
		}


		.ls-skh-v-irrelevant {
			background-color: @color_lectio_LightGray;
		}

		.ls-skh-v-g3 {
			background-color: @color_lectio_Yellow;
		}

		.ls-skh-v-g4 {
			background-color: @color_lectio_DarkGreen;
		}
	}
	/* ******************************************** */
	/* Månedskalender	*/
	/* ******************************************** */
	.maanedskalender {
		margin-left: 0.75em;
		margin-right: 0.75em;

		.maanedskalender-tabel {
			table-layout: unset;
			width: 304px;

			caption {
				.title-small;
			}
		}

		@media @mobile {
			padding-left: unset;
			padding-right: unset;

			.maanedskalender-tabel {
				table-layout: fixed;
				width: 100%;
			}

			.maanedskalender-big-col {
				width: 40vw;
			}

			.maanedskalender-med-col {
				width: 15vw;
			}
		}
	}



	garlow[data-skh-p-info*="\"\]"] > gd:nth-child(1) {
		cursor: pointer;
	}

	.ui-dialog-copyclipboard {
		margin: 8px 0px 5px 8px;
		float: left;
	}
	/***************************************************/
	/* TilskudTabelCtrl */
	.TilskudTabelCtrl table {
		padding: 0.1px;
	}

	.TilskudTabelCtrl th {
		padding: 0;
		padding-right: 5px;
		text-align: left;
	}

	.TilskudTabelCtrl td {
		padding: 0;
		padding-right: 5px;
	}

	.TilskudTabelCtrl TR:hover TD {
		background-color: #f7f7f5;
	}

	.clu-completions {
		& > * {
			/*cursor: pointer;*/
		}
	}

	.clu-current-completion {
		font-weight: bold;
	}

	.vejledning-content {
		margin-bottom: 1rem;

		li {
			.lectioBorderBot;
			.marginBottom;
		}
	}

	.vejledning-container {
		margin-bottom: 1em;

		li img {
			margin-left: 1.2em;
			width: 22rem;
			border: solid 1px @color_lectio_Black;
		}
	}

	.adhs-modal {
		background-color: #F5F5F5;
		border-radius: 14px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
		padding: 33px 19px 32px 19px;
		margin: auto;
		margin-top: 56px;
		max-width: 400px;
		width: 83%;
		transition: opacity 0.3s ease-in-out;
		display: flex;
		flex-direction: column;
		line-height: normal;
	}

	.adhs-title {
		line-height: 1.2em;
		font-size: 27px;
		font-weight: 700;
		max-width: 330px;
		margin-bottom: 24px;
		margin-left: auto;
		margin-right: auto;
	}

	.adhs-list {
		display: flex;
		flex-direction: column;
	}


	.adhs-list-item {
		display: flex;
		flex-direction: row;
		align-items: start;
		justify-content: flex-start;
		min-height: 50px;
		margin: 0px 0px 5px 0px;
	}

	.adhs-number-container {
		flex-direction: column;
		display: flex;
		margin: 0px 5px 0px 0px;
	}

	.adhs-logo {
		display: flex;
		justify-content: space-around;

		img {
			width: 16rem;
			height: 5rem;
		}
	}

	.adhs-container {
		position: relative;
		display: none;
		margin: 0 auto;
	}

	.adhs-circle {
		font-size: 30px;
		height: 40px;
		width: 40px;
		border-radius: 100px;
		background-color: #979797;
	}

	.adhs-number {
		padding: 9px 0px 0px 15px;
		color: white;
		font-size: 20px;
		font-weight: 100;
	}

	.adhs-instruction {
		width: 100%;
		text-align: left;
		font-size: 20px;
		padding: 0px 0px 6px 9px;
		line-height: 27px;
	}

	.edge-add-btn {
		min-height: 100px;
	}

	.instruction-flex {
		display: flex;
		align-items: center;
	}

	.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-add-to-homescreen-button {
		height: 40px;
		background: white;
		margin: 0px 5px -9px 5px;
		box-shadow: 1px 1px 2px gray;
		border-radius: 4px;
		padding: 0px 7px 0px 4px;
	}

	.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-android-chrome-install-app {
		height: 40px;
		background: white;
		margin: 10px 5px -10px 5px;
		box-shadow: 1px 1px 2px gray;
		border-radius: 4px;
		padding: 0px;
	}

	.adhs-more-button,
	.adhs-add-to-homescreen-button,
	.adhs-install-app,
	.adhs-your-app-icon {
		height: 40px;
		background: white;
		margin: 0px 5px -7px 5px;
		box-shadow: 1px 1px 2px gray;
		border-radius: 4px;
	}


	.adhs-container.adhs-android.adhs-chrome .adhs-modal .adhs-list .adhs-list-item .adhs-instruction {
		line-height: 34px;
	}

	.til-lectio-knap {
		bottom: 1em;
		position: absolute;
		right: 1em;
	}


	.qrKode-container {
		position: relative;
	}

	.qrKode-image {
		max-height: 300px;
		top: 0;
		left: 0;
	}

	.margin-left-3em {
		margin-left: 3em;
	}

	.ls-document-splitter-borderleft {
		border-left: 1px solid #d3dae0;
	}

	@media only screen and (max-width: 420px) {
		.vejledning-container li img {
			width: 20rem;
		}

		.ls-visual-guide-container > :nth-child(5),
		.ls-visual-guide-container > :nth-child(4) {
			display: none !important;
		}

		.lsm-no-border {
			border: none;
		}

		.ls-content {

			.mobile-TextLeft {
				text-align: left;
			}

			.mobile-flex-column {
				flex-direction: column;
				align-items: flex-start;
			}
		}

		.mobile-unset-margin-left {
			margin-left: 0em;

			table {
				margin-left: 0em;
			}

			div {
				margin-left: 0.8em;
			}
		}

		.mobile-unset-line-height {
			line-height: normal;
		}

		th.vertical-text {
			white-space: normal; /* Allow text to wrap */
			writing-mode: vertical-lr; /* Set vertical writing mode */
			text-orientation: mixed;
		}

		.mobile-justify-content_flex-end {
			display: flex;
			justify-content: flex-end;
		}
	}


	.ls-visual-guide-container {
		padding: 1em;
		display: flex;
		justify-content: space-evenly;

		.ls-visual-guide-content {
			min-height: 64px;
			max-width: 10rem;
			display: inline-flex;
			flex-direction: column;
			align-items: center;
			text-align: center;

			span.ls-visual-guide-content-fonticon::before {
				font-size: 4rem;
				margin-bottom: 1rem;
			}

			> :nth-child(2) {
				margin-bottom: 8px;
			}
		}

		margin-bottom: 12px;
	}

	// KARAKTERSIDE Mobile
	.ls-grades-mobile-table {
		width: 100%;

		TH, TD {
			font-size: small;
			text-align: left;
		}
	}
	/* Start Student photo ******************/
	IMG.Photo
	/* lille thumb img style */ {
		cursor: pointer;
		border: solid 1px #f5f5f5;
		top: 2px;
	}

	DIV.Photo
	/* stor thumb img style */ {
		background-color: white;
		cursor: pointer;
		position: absolute;
		height: 240px;
		width: 180px;
		display: none;
		z-index: 2;
		border: solid 1px #f5f5f5;
		/*	padding: 10px;
	margin-top: 50px;*/
	}

	DIV.Photo:hover,
	IMG.Photo:hover {
		opacity: 1;
	}

	DIV.Photo IMG {
		/* stor thumb billedramme */
		.outline-variant;
		.ls-elevation-level2;
	}

	IMG.PhotoPrintHiRes {
		/* ved udskrift og valg af bedre billedkvalitet*/
		height: 96px;
		width: 72px;
	}

	.screenHideThumb_printShowThumb IMG.Photo {
		display: none;
	}
	/*photo thumb will not be shown on screen but it will be printed*/
	.screenHide_printShow {
		display: none;
	}
	/* element is printed, but not shown on screen*/
	/* Start Student photo ******************/
	.ls-fotoupload-subtitle {
		border-top: 1px solid @--md-sys-color-outline;
		padding: 0.5rem 0 1rem 0;
		.title-small;
	}

	.ls-fotoupload-img {
		@media @mobileportrait {
			width: 88dvw;
		}

		.ls-elevation-level1;
	}

	.thumbViewerIcon {
		position: absolute;
		bottom: 0.5rem;
		right: 0.5rem;
		width: 3.33rem;
		height: 3.33rem;
		border-radius: 50%;
		line-height: 3.33rem;
		.secondary-container;
		.ls-elevation-level3;
		text-align: center;
		box-sizing: border-box;

		span {
			font-size: 2.5rem;
		}

		&:hover {
			opacity: 0.92;
		}
	}

	.ls-fotoupload-container {
		display: flex;
		flex-direction: column;
		justify-content: space-between; /* Space out elements dynamically */
		align-items: stretch; /* Center align horizontally */
		height: 70vh; /* Full viewport height */
	}
	
