.file-lcdocumenteditor-less {
	color: blue;
}
@import (reference) "constants.less";

/*override cke editor style theme;*/
span.cke_chrome {
	border: solid 1px #ced4d9;
	box-shadow: none;
}

/* Denne forhindrer editoren roede "magic line" i lejlighedsvist at skubbe hele dokumentindholdet ca. en linie nedad, mens den vises. */
.cke_wysiwyg_div.cke_wysiwyg_div > span:first-child {
	float: left;
}

span.cke_bottom {
	background: transparent;
}

span.cke_resizer {
	border-right-color: #ced4d9;
}

/*Editor toolbar background*/
div.cke_top {
	background: @solid_bg_canvas;
	background: linear-gradient(@solid_bg_canvas, #fafafa 2%, #fefefe 12%);
}

/*Editor balloon-toolbar*/
div.cke_balloon {
	/*border: solid 1px #d2d2d2;*/
	border-radius: 4px;


	.cke_balloon_content {
		box-shadow: 4px 4px 6px rgba(0, 0, 0, .1);
		background-color: #fff;
		border-radius: 4px;
	}

	&.cke_balloontoolbar {
		border: 1px solid @color_lectio_GrayBlue1;

		.cke_balloon_triangle_outer.cke_balloon_triangle_bottom,
		.cke_balloon_triangle_outer.cke_balloon_triangle_top {
			/*border-top-color: #000 !important;
			border-bottom-color: #000 !important;*/
			display: none;
		}

		.cke_balloon_triangle_inner.cke_balloon_triangle_bottom,
		.cke_balloon_triangle_inner.cke_balloon_triangle_top {
			/* border-top-color: #fff !important;
			border-bottom-color: #fff !important;
			*/
			display: none;
		}
	}
}




span.cke_toolbar_separator {
	background: #ced4d9;
}

/*tooglebutton toggled on background*/
.cke_button.cke_button_on {
	border-bottom: solid 1px #ccc;
	border-right: solid 1px #ccc;
	border-top: solid 1px #888;
	border-left: solid 1px #888;
	background-color: #dbdbdb;
}

span.cke_combo_text {
	line-height: 24px;
	width: 70px;
}

a.cke_combo_button:hover {
	background-color: white;
	cursor: pointer;
}


span.cke_combo_open {
	height: 24px;
}


.cke_toolgroup a {
	line-height: 16px;
	vertical-align: middle;
}

.lctoolbarlabel {
	float: left !important;
	display: inline-block !important;
	margin-top: 5px !important;
	margin-right: 4px !important;
	color: #999999 !important;
}

.lctoolbarsavestatus {
	display: inline-block !important;
	margin-top: 7px !important;
	color: @brush_infotext !important;
	color: @color_lectio_DarkGreen !important;
	font-style: italic !important;
}

.lceditornewlayout .cke_toolbar_separator {
	margin: 5px 9px 0;
	background: #d2d2d2;
}

.lceditornewlayout.cke_chrome {
	border: none;
	box-shadow: none;
}

.lceditornewlayout .cke_top {
	border-bottom-color: #d2d2d2;
}

/* Questionaire/Quiz */
.ls-questionnaire-question-text .cke_textarea_inline,
.ls-questionnaire-question-explanation .cke_textarea_inline {
	width: 480px;

	& img {
		max-width: 100%;
		height: auto !important;
	}
}

.cke_textarea_inline {
	&:focus {
		outline: none;
	}

	border: solid 1px #d2d2d2;
	padding-left: 5px;
}


.lceditornewlayout .cke_button {
	&:hover {
		cursor: pointer;
		background: #e5e5e5;
	}

	span {
		cursor: pointer;
	}

	&.cke_button_disabled,
	&.cke_button_disabled:hover,
	&.cke_button_disabled span:hover,
	&.cke_button_disabled span {
		cursor: default;
		background-color: transparent;
		opacity: 0.7;
	}
}

/*msie fix*/
/*.lceditornewlayout .cke_inner,*/
.lceditornewlayout .cke_wysiwyg_div,
.lceditornewlayout .cke_wysiwyg_frame {
	background: transparent
}

/* Forhindrer hover-/fokus-ramme på ydre og indre kasse. */
.cke_widget_wrapper > .lc-display-commentblock > .cke_widget_editable,
.cke_widget_wrapper:hover > .lc-display-commentblock > .cke_widget_editable {
	outline: none !important;
}

/* Forhindrer slides i at klaske sammen i editmode. */
/*.cke_widget_wrapper .lc-document-slide {
		margin-bottom: 1em;
	}*/
.cke_widget_wrapper_lc-document-slide {
	padding: 0
}


.lc-display-commentblock-errormessage {
	color: red;
	background-color: #fdfdf9;
	position: absolute;
	top: 30px;
	right: 40px;
	border: 1px solid red;
	padding: 4px;
	border-radius: 4px;
	display: none;
}

div.cke_combopanel__lectioformat,
div.cke_combopanel__lectioformat2 {
	height: 285px;
	width: 270px;
}


.cke_combo__lectioformat .cke_combo_text,
.cke_combo__lectioformat2 .cke_combo_text {
	width: 8em;
}

.lc-display-fragment.cke_wysiwyg_div {
	padding: 0px;
}
