.file-jquery-ui-customizations-less {
	color: blue;
}
@import (reference) "constants.less";
@import (reference) "buttons.less";
@import (reference) "framing.less";

/*override jquery styles*/
.ui-widget,
.ui-dialog,
.ui-widget BUTTON {
	font-family: Helvetica, Verdana, Arial, sans-serif;
	font-size: 1em;
}

.ui-dialog {
	background-color: #fff;
	border-radius: 2px;
	border: solid 1px @color_lectio_GrayLightBlue2;
	box-shadow: 0 2px 10px rgba(0, 0, 0, .2);
}

.ui-dialog,
.ui-datepicker {
	background: white;
	border: solid 1px #b8c3cc;
	border-top: none;
	padding: 0;
	z-index: 10000;
}


	.ui-widget-content a {
		color: #1b5c9e;
	}

	.ui-widget-header {
		background: #2191c0 url(/lectio/img/gradheader.png) 0% 0% repeat-x;
		border: none;
		color: #667380;
		font-weight: bold;
		vertical-align: middle;
		margin: 0em;
		font-family: Verdana;
	}

	.ui-corner-left,
	.ui-corner-right,
	.ui-corner-all {
		-moz-border: none;
		-webkit-border: none;
		border: none;
		border-radius: 0px;
	}
	/* Icons
----------------------------------*/
	/* states and images */
	.ui-icon,
	.ui-widget-content .ui-icon,
	.ui-widget-header .ui-icon,
	.ui-state-default .ui-icon,
	.ui-state-hover .ui-icon,
	.ui-state-focus .ui-icon,
	.ui-state-active .ui-icon,
	.ui-state-highlight .ui-icon,
	.ui-state-error .ui-icon,
	.ui-state-error-text .ui-ico {
		background-image: none;
	}




	.ui-state-default,
	.ui-widget-content .ui-state-default {
		color: #1b5c9e;
		background-color: #ecf6ff;
		background-image: url('/lectio/img/gradbrick.png');
		background-repeat: repeat-x;
		background-position: left top;
		border: solid 1px #d9e7ff;
	}

	.ui-state-hover,
	.ui-widget-content .ui-state-hover,
	.ui-state-focus,
	.ui-widget-content .ui-state-focus {
		color: #ffffff;
		background-color: #0b72d8;
		background-image: url('/lectio/img/gradhover.png');
		background-repeat: repeat-x;
		background-position: left top;
		border: solid 1px #d9e7ff;
	}

	.ui-state-active,
	.ui-widget-content .ui-state-active {
		color: #000000;
		background-color: #fcfcf5;
		background-image: none;
		background-repeat: repeat-x;
		background-position: left top;
		border: solid 1px #e5dfcd;
		cursor: text;
	}

	.ui-widget-header .ui-state-default,
	.ui-widget-header .ui-state-hover,
	.ui-widget-header .ui-state-focus,
	.ui-widget-header .ui-state-active {
		background: none;
		border: none;
	}

	.ui-dialog {
		.ui-dialog-titlebar {
			border-bottom: solid 1px #b8c3cc;
		}

		.ui-dialog-titlebar-close {
			border: none;
			background-color: transparent;
			background-image: url(/lectio/img/close.png);
			background-position: center center;
			background-repeat: no-repeat;
			cursor: pointer;
			padding: 0;
		}


		.ui-dialog-content > div:first-of-type {
			max-height: 60vh;
			overflow-y: auto;
		}
	}


	.ui-icon-closethick {
		display: none
	}
	/******************/


/* kalender header, forrige og næste knap */
.ui-datepicker {

	.ui-widget-content {
	}

	.ui-datepicker-header {
		border-bottom: solid 1px #b8c3cc;
	}

	.ui-datepicker-prev {
		.prepend-fonticon-arrow-left;
		/*background-image: url(/lectio/img/chevron_left_dark.auto);
		background-position: center center;
		background-repeat: no-repeat;
		*/
		left: 2px;
		cursor: pointer;
	}

	.ui-datepicker-prev-hover {
		/*background-image: url(/lectio/img/chevron_left_blue.auto);*/
		top: 2px;
		.prepend-fonticon-arrow-left;
		.primary-text;
		opacity: 0.88
	}

	.ui-datepicker-next {
		/*background-image: url(/lectio/img/chevron_right_dark.auto);
		background-position: center center;
		background-repeat: no-repeat;*/
		cursor: pointer;
		right: 0px;
		.prepend-fonticon-arrow-right;
	}

	.ui-datepicker-next-hover {
		/*background-image: url(/lectio/img/chevron_right_blue.auto);*/
		top: 2px;
		.prepend-fonticon-arrow-right;
		.primary-text;
		opacity: 0.88
	}
}
	/* Button styling
----------------------------------*/
.ui-dialog .ui-dialog-buttonpane .ui-state-default,
.ui-dialog .ui-dialog-buttonpane .ui-button {
	.ls-button;
	}

.ui-dialog .ui-dialog-buttonpane .ui-state-hover,
.ui-dialog .ui-dialog-buttonpane .ui-button:hover {
		.ls-button-hover;
}

	.ui-dialog .ui-dialog-buttonpane {
		border-width: 0em;
		padding: 0.2em;
		margin: 0em;
		border-top: solid 1px #d3dae0;
	}

	.ui-dialog .ui-dialog-buttonpane .ui-button .ui-button-text {
		padding: 0em;
		line-height: inherit;
	}

	.ui-dialog {
		position: fixed;

		button:focus {
			.ls-focus-base-style;
		}
	}

	.ui-dialog-buttonpane button {
		.ls-button;
	}

	.ui-dialog-buttonpane button:hover {
		.ls-button-hover
	}

	.ui-dialog-buttonpane button[disabled] {
		.ls-button-disabled;
	}

	.ui-dialog-buttonpane button[disabled]:hover {
		.ls-button-disabled-hover
	}
	/* Flg. skyldes at jquery ui 1.11.4 som noget nyt har css der saetter font-family til verdana (?!).  */
	.ui-widget input,
	.ui-widget select,
	.ui-widget textarea,
	.ui-widget button {
		font-family: inherit;
	}


/* mobile **********/
@media @mobile {
.ui-reset {
		padding:unset;
		box-shadow: none;
		border-radius: unset;
		border-image-width: unset;
		cursor: unset;
		color: unset;
		text-decoration: unset;
		outline: none;
		transition: unset;
		background: none;
		border: none;
		font-weight: unset;
	}
	/*alertbox*/
	.ui-widget,
	.ui-dialog,
	.ui-widget-content {
		.ui-reset;
		.body-small;
	}

	/* titlebar */
	.ui-dialog-titlebar.ui-widget-header {
		.ui-reset;
		.title-medium;
	}

	/* contentarea*/
	.ui-dialog-content, .ui-widget.ui-widget-content {
		.ui-reset;
		.body-small;
	}

	/* buttons */
	.ui-dialog .ui-dialog-buttonpane {

		border-top: none;
	}


	.ui-dialog-buttonpane button, .ui-dialog-buttonpane button.ui-button.ui-widget {
		display: inline-block;
		margin: 0.0rem 0.05em 0.2em 0.05em;
		.ls-elevation-level0;
		.label-medium;
		border: none;
		white-space: nowrap;
		box-sizing: border-box;
		border-radius: 1.125rem;
		opacity: 1;
		padding: 0.5em 1em;
		display: inline-block;
		text-shadow: none;
		font-weight: 500;
		background: unset;
		.outline-variant;
		.primary-text;



		&:hover {
			opacity: 0.92;
			cursor: pointer;
		}

		&[disabled], &[disabled]:hover {
			.ls-elevation-level0;
			.label-medium;
			border: none;
			white-space: nowrap;
			box-sizing: border-box;
			border-radius: 1.125rem;
			padding: 0.5em 1em;
			opacity: 0.62;
			cursor: default;
			text-shadow: none;
			display: inline-block;
			font-weight: 500;
			outline: solid 1px @--md-sys-color-surface-variant;
			.on-surface-variant-text;
		}
	}
	/**************************************************************/
	.ui-dialog.ui-widget {
		.ls-card-filled;
		.ls-elevation-level3;
	}
	/* datepicker*/
	.ui-datepicker.ui-widget {
		background: white;
	}
}