@import (reference) "constants.less";


@bd-color:@--md-sys-color-outline-variant;
@bg-color: transparent;


@aktplan-width: 98vw;
@aktplan-details-width: 575px;

@aktplan-kalender-width: calc(@aktplan-width - @aktplan-details-width - 85px);

.display-grid-aktivitetsplan-oversigt {
	display: grid;
	width: @aktplan-width;
	margin-top: 0.5rem;
	grid-template-columns: minmax(auto, 2fr) @aktplan-details-width;
	grid-template-rows: auto;
	grid-template-areas: 'visning details';
	grid-column-gap: 0.875rem;
	align-items: start;
	min-height: 150px;

	.aktivitetsplan-visning-container {
		grid-area: visning;
		overflow-x: hidden;

		.aktivitetsplan-visning-kalender {
			background-color: @bg-color;
			display: inline-grid;
			justify-content: start;
			grid-template-areas: 'entity kalender' ' entity indhold';
			font-size: 0.9em;
			max-height: 68vh;
			max-width: @aktplan-kalender-width;
			overflow-x: auto;

			.aktivitetsplan-visning-entity-container {
				grid-area: entity;
				display: inline-grid;
				position: sticky;
				left: 0;
				background-color: white;


				div:nth-child(1) {
					top: 0;
					position: sticky;
					border-top: 1px solid;
					border-color: @bd-color;
					background-color: white;
				}

				div:nth-child(2) {
					top: 22px;
					position: sticky;
					background-color: white;
				}

				div:nth-child(3) {
					top: 43px;
					position: sticky;
					font-weight: bold;
					background-color: white;
				}

				.aktivitetsplan-visning-entity, .aktivitetsplan-visning-empty-entity {
					text-wrap: nowrap;
					border-left: 1px solid;
					padding: 0 5px 0 5px;
					border-color: @bd-color;
					.body-small;
					display: flex;
					align-items: center;
					justify-content: flex-start;
				}

				.aktivitetsplan-visning-empty-entity {
					min-width: 60px;
				}

				.aktivitetsplan-visning-entity {
					&:hover {
						.primary-container;
						.primary-container-text;
						cursor: pointer;


						&.prepend-fonticon-uddannelsesforloeb {
							&::before {
								.primary-container-text;
							}
						}
					}
				}

				.aktivitetsplan-visning-entity-selected {
					.secondary-container;
					.secondary-container-text;




					&.prepend-fonticon-uddannelsesforloeb {
						&::before {
							.secondary-container-text;
						}
					}
				}

				.aktivitetsplan-visning-headliner-entity {
					font-size: small;
					font-weight: bold;
					border-left: 1px solid;
					border-color: @bd-color;
					height: 30px;
					align-content: center;
					text-align: left;
					padding-left: 5px;
					border-right: 0px;
				}
			}

			.aktivitetsplan-visning-kalender-container {
				grid-area: kalender;
				display: inline-grid;
				position: sticky;
				top: 0;
				background-color: white;

				.aktivitetsplan-year-container {
					display: inline-grid;

					.aktivitetsplan-visning-year {
						border-top: 1px solid;
						border-color: @bd-color;
						.label-small;
						display: flex;
						align-items: center;
						justify-content: center
					}
				}

				.aktivitetsplan-month-container {
					display: inline-grid;

					.aktivitetsplan-visning-month {
						.label-small;
						display: flex;
						align-items: center;
						justify-content: center
					}
				}

				.aktivitetsplan-week-container {
					display: inline-grid;

					.aktivitetsplan-visning-week {
						.label-small;
						display: flex;
						align-items: center;
						justify-content: center
					}
				}
			}

			.aktivitetsplan-visning-indhold-container {
				grid-area: indhold;
				display: inline-grid;
				background-color: @bg-color;

				DIV {
					display: inline-grid;
					align-content: center;
					justify-content: start;
				}

				.aktivitetsplan-visning-week-in-entity-interval {
					.tertiary-container;

					&:not([title=""]) {
						cursor: help;

						&:hover {
							opacity: 0.8;
						}
					}
				}

				.aktivitetsplan-visning-lukke-week {
					.not-schoolday;
				}


				.aktivitetsplan-visning-week-title, .aktivitetsplan-visning-week-title-last {
					height: 30px;
				}

				.aktivitetsplan-visning-week-title {
					border-right: 0px;
				}
			}

			.aktivitetsplan-visning-indhold-container > div,
			.aktivitetsplan-visning-entity-container > div,
			.aktivitetsplan-visning-kalender-container > div > div {
				//padding: 2px;
				border-right: 1px solid;
				border-bottom: 1px solid;
				text-align: center;
				align-self: center;
				height: 20px;
				border-color: @bd-color;
				padding-left: 2px;
				padding-right: 2px;
			}
			//Celle bredde for uge.
			.aktivitetsplan-visning-indhold-container > div, DIV.aktivitetsplan-visning-week, .aktivitetsplan-visning-week-title {
				width: 10px;
			}

			DIV.aktivitetsplan-visning-week {
				/*overflow: hidden;
				writing-mode: vertical-rl;
				text-orientation: upright;
				letter-spacing: -4px !important;
				text-indent: -1px;*/
				/*&:nth-child(odd) {
					span {
						transform: rotate(-90deg);
					}
				}*/
				span {
					margin-top: 2px
				}

				&:nth-child(even) {

					span {
						/*transform: rotate(-90deg);*/
						color: transparent;
					}
				}
			}

			.hightlight-light-hold {
				background: @--md-ref-palette-custom398;

				&:not([title=""]) {
					cursor: help;

					&:hover {
						opacity: 0.8;
					}
				}
			}
		}
	}

	.aktivitetsplan-detailes {
		grid-area: details;

		.ls-aktivitetsplan-detailes-uf-hold {
			max-height: 60vh;
			overflow-y: auto;
			padding-right: 0.5rem;
			margin-bottom: 0.2rem;
		}

		.ls-aktivitetsplan-detailes-uf-hold-search input {
			width: 100%;
			min-width: 18em;
		}
	}
}

/*.aktivitetsplan-activeperiod {
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}*/

.aktivitetsplaninfo-hold {
	appearance: none;
	background-color: #ffffff;
	box-sizing: border-box;
	padding-top: 0.083em;
	padding-right: 0.25em;
	padding-bottom: 0.083em;
	padding-left: 0.25em;
	min-height: 1.75em;
	cursor: text;
	outline: none;
	background-image: none;
	cursor: pointer;
	overflow: auto;
	border: 1px solid @--md-sys-color-outline-variant;
	border-radius: 0.25em;
	height: 200px;
	width: 18em;
}


/****** AktplanOversigt ********/

.ls-aktplanoversigt-colorbox {
	height: 20px;
	width: 20px;
	margin-bottom: 15px;
	border: 1px solid black;
}
