.file-framing-less {
	color: blue;
}
@import (reference) "constants.less";


DIV.ls-container,
TABLE.form,
DIV.form,
DIV.panel,
TABLE.panel {
	box-sizing:border-box;
	border: 1px dotted @brush_border;
	vertical-align: top;
	padding: 0.8em;
	margin: 0;
	margin-top: 0.35em;
	margin-bottom: 0.35em;
	overflow: visible;
	height: 100%;
	background-color: #fefefe;
	border-radius: 0.4em;
}

/********* Island Detail bokse NEW ********/
.ls-blockbase {
	box-sizing: border-box;
	border: none;
	vertical-align: top;
	display: table;
	min-width: 20em;
	/*standard margin omkring øer */
	margin-top: 0.5rem;
	margin-right: 0;
	margin-left: 0;
	margin-bottom: 1rem;

	@media @mobile {
		margin-bottom: 0.5rem;
	}
}

.defaultBlock {
	.ls-blockbase;
}

/* default if no value */
.smallBlock {
	.ls-blockbase;
	min-width: 27.1em;
}

.smallBlockFixed {
	.smallBlock;
	width: 27.1em;
}

/*ca. 33%_ofLectioScreenWidth	*/
.mediumBlock {
	.ls-blockbase;
	min-width: 41.5em;
}

.mediumBlockFixed {
	.mediumBlock;
	width: 41.5em;
}

/* ca. 50%_ofLectioScreenWidth	*/
.largeBlock {
	.ls-blockbase;
	min-width: 55.5em;
}

.largeBlockFixed {
	.largeBlock;
	width: 55.5em;
}

/* ca. 66%_ofLectioScreenWidth	*/
.xlargeBlock {
	.ls-blockbase;
	min-width: 84.0em;

}

.xlargeBlockFixed {
	.ls-blockbase;
	width: 84em;
	max-width: 100vw;
}

//STM 36988 - XlargeBlocks style for islands when printing from IEx to avoid get the content cutting off
@media print (max-width: 768px) {
	.ls-print .xlargeBlock {
		width: 100% !important;
	}
}

//STM 31624, IE havde svært ved shrink-to-fit
.ls-print .xlargeBlock {
	width: auto;
}

/* ca. 100%_ofLectioScreenWidth	*/
.largeBlockContainer {
	border: none;
	vertical-align: top;
	width: 72.7em;
}

.xlargeBlockContainer {
	vertical-align: top;
	max-width: 86.3em;
	display: block;
	display: table;
}

/********* Kolonner ********/
.ls-colminwidth5 {
	min-width: 5em;
}

/********* Island Detail bokse Heights ********/
.defaultBlockHeight,
.island.defaultBlockHeight .islandContent {
	min-height: 6em;
}


.smallBlockHeight,
.island.smallBlockHeight .islandContent {
	min-height: 10em;
}


.mediumBlockHeight,
.island.mediumBlockHeight .islandContent {
	min-height: 14em;

	@media @mobile {
		min-height: unset;

		.dashboardLinkHeader:not(:first-child) {
			margin-top: 1em;
		}
	}
}



.largeBlockHeight,
.island.largeBlockHeight .islandContent {
	min-height: 24em;
}

.xlargeBlockHeight,
.island.xlargeBlockHeight .islandContent {
	min-height: 36em;
}

/*************************************/
/********* LectioDetailIsland ********/
@island-background-color: #ffffff;

SECTION.island {
	background-color: @island-background-color;
	border: 1px solid @brush_border;
	box-shadow: 0.2em 0.2em 0.2em rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	border-radius: 0.5em 0.5em 0 0;
	display: table;
	border-collapse: separate;
	padding: 0;

	.islandHeaderContainer {
		border-bottom: 1px solid @brush_border;
		height: 2.25em;
		line-height: normal;
		background-image: url('/lectio/img/gradheaderCenter2.png');
		background-repeat: repeat-x;
		background-size: auto 100%;
		border-radius: 0.5em 0.5em 0 0;
		border-top: none;
		display: flex;
		justify-content: space-between;

		@media @mobile {
			height: fit-content;
		}
	}

	.islandHeader {
		display: block;
		padding-top: 0.5em;
		padding-bottom: 0.6em;
		padding-left: 0.8em;
		padding-right: 0.8em;
		font-weight: bold;
		white-space: nowrap;
		vertical-align: middle;
		margin: 0;
		color: #475968;
		font-family: Verdana, sans-serif;
		text-align: left;
		@media @mobile {
			text-wrap: balance;
		}
	}

	.islandHeaderRight {
		display: block;
		vertical-align: middle;
		padding-top: 0.5em;
		padding-right: 0.8em;
	}

	.islandContent {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		clear: both;
		display: block;
		background-color: @island-background-color;
		padding: 1em 0.8em 1em 0.8em;

		@media @mobile {
			padding-top: 0em;
		}
	}

	.islandSendto {
		background-color: @island-background-color;
		text-align: right;
		padding-top: 0.6em;
		padding-right: 0.6em;
	}
}

/* END***** LectioDetailIsland **/
/* ************************************************* */
/* paging style in GridView lists (tables) ********* */
/* ************************************************* */
.paging {
	border: none;
	border-collapse: collapse;
	vertical-align: middle;
	margin: 0;

	TR TD {
		text-align: center;
		border: none;
	}

	TR TD TABLE {
		margin-left: auto;
		margin-right: auto;
	}

	A,
	SPAN {
		padding: 0.4em;
	}
}

/* ************************************************* */
/* table basic styles in GridView lists (tables) ********* */
/* ************************************************* */

@std-table-padding: 0.45em;
@std-table-heading: font-weight: bold;


/**********************************************************/
/* ls-table-layout (tabellen uden border) **/
/**********************************************************/
.ls-table-layout,
table.ls-table-layout {
	border: none;

	tr,
	tr td,
	tr th {
		border-collapse: collapse;
		border: none;
	}

	tr td,
	tr th {
		padding: @std-table-padding;

		table table td,
		table table th {
			padding: 0;
		}
	}
}

/**********************************************************/
/* ls-table-layout1 (standard tabellen) **/
/**********************************************************/
.list,
.ls-table-layout1 {
	background-color: #ffffff;
	border: 1px solid @brush_border_light;
	border-collapse: collapse;
	margin: 0;
	margin-top: 0.4em;
	margin-bottom: 0.4em;

	TR.paging > td {
		background-color: white;
		border: 1px solid @brush_border_light;
	}

	TR {
		border: solid 1px @brush_border_light;
	}

	TH {
		border-left: 1px solid @brush_border_light;
		border-right: 1px solid @brush_border_light;
		padding: @std-table-padding;
	}

	TH.macomonly {
		border-top: 1px solid;
	}

	TH,
	TH A,
	TH A:hover {
		font-weight: bold;
		text-align: center;
	}

	TH[scope="row"] {
		text-align: left;
	}

	TD {
		border: 1px solid @brush_border_light;
		padding: @std-table-padding;
	}
}

/**********************************************************/
/* SUB Table -  til indramning undertabelceller i tabeller med data */
/**********************************************************/
.ls-table-layout4 {
	border: dotted 1px @brush_border;
	border-collapse: collapse;
	margin: 0.09em 0.09em 0.09em 0.09em;

	TH,
	TD {
		padding: 0.2em 0.2em;
		border: 1px dotted @brush_border !important;
		border-collapse: collapse !important;
	}
}

/**********************************************************/
/* inputList, layout af tabeller med indtastningsfelter  */
/**********************************************************/
TABLE.ls-std-table-inputlist {

	& > TBODY > TR > TD,
	& > TR > TD {
		font-weight: normal;
		padding: 4px 4px;
		vertical-align: middle;
		vertical-align: top;
		& > label:first-child {
			display:none;
		}
	}

	& > TBODY > TR > TH,
	& > TR > TH {
		text-align: left;
		white-space: nowrap;
		font-weight: bold;
		padding: 4px;
		vertical-align: top;
	}
}
@media @mobile {
	TABLE.ls-std-table-inputlist.ls-std-table-inputlist-mobile {
		& > TBODY > TR > TD,
		& > TR > TD {
			& > label:first-child {
				display: block;
				font-weight: bold;
			}

			padding-bottom: 0.5rem;
		}

		& > TBODY > TR > TH,
		& > TR > TH {
			display: none
		}
	}
}
	/* nestede inputlist tabeller */
	TD TABLE.ls-std-table-inputlist {

		& > TBODY > TR > TD,
		& > TR > TD {
			padding: 0.3em 0.2em;
			font-weight: normal;
		}

		& > TBODY > TR > TH,
		& > TR > TH {
			padding: 4px;
		}
	}

	TABLE.ls-table-heading-lite > tbody > tr > th,
	TABLE.table-heading-lite > tr > th {
		font-weight: normal;
	}

	/**********************************************************/
	/* Avoid inheritance of border for tables in tables in tables..etc..*/
	.list TABLE TR,
	.list TABLE TD,
	.list TABLE TH,
	.ls-table-layout1 TABLE,
	.ls-table-layout1 TABLE TR,
	.ls-table-layout1 TABLE TD,
	.ls-table-layout1 TABLE TH,
	.ls-table-layout4 TD TABLE TD,
	.ls-table-layout4 TH TABLE TD {
		border: none;
	}

/**********************************************************/
/* ls-table-layout2 - MD */
/**********************************************************/
.ls-table-layout2 {
	.body-small;
	margin: 0.4em 0;
	min-width: 100%;
	border-radius: 0.5rem;
	border-spacing: 0;
	border-collapse: separate;
	border: solid 1px @--md-sys-color-outline-variant;

	tr {
		th {
			vertical-align: middle;
			/*padding: @std-table-padding;*/
			padding: 0.875rem 0.875rem;
		}

		th:not(:last-child) {
			border-right: solid 1px @--md-sys-color-outline-variant;
		}

		td {
			/*padding: @std-table-padding;*/
			padding: 0.875rem 0.875rem;
		}

		td:not(:last-child) {
			border-right: solid 1px @--md-sys-color-outline-variant;
		}
	}

	thead > tr:not(:last-child) > th, thead > tr:not(:last-child) > td, tbody > tr:not(:last-child) > th, tbody > tr:not(:last-child) > td, tfoot > tr:not(:last-child) > th, tfoot > tr:not(:last-child) > td, tr:not(:last-child) > td, tr:not(:last-child) > th, thead:not(:last-child), tbody:not(:last-child), tfoot:not(:last-child) {
		border-bottom: solid 1px @--md-sys-color-outline-variant;
	}

	.paging table tr td {
		border: none;
	}
}

/**********************************************************/
/* ls-table-layout5 **/
/**********************************************************/
.ls-table-layout5 {
	border: none;
	border-collapse: collapse;
	margin: 0;
	margin-top: 0.4em;
	margin-bottom: 0.4em;
	min-width: 100%;
}

.ls-table-layout5 TR {
	line-height: 1.5em;
	vertical-align: middle;
}

	.ls-table-layout5 TR TH {
		border: none;
		padding: @std-table-padding;
		border-bottom: solid 1px @brush_border_light;
		vertical-align: middle;
	}

	.ls-table-layout5 TR TD {
		border: none;
		border-top: solid 1px @brush_border_light;
		border-collapse: collapse;
		padding: @std-table-padding;
	}

	//Rettet padding på liste elementer globalt på telefonen. kunne skabe problemer. men blev gjort for beskeder og dokument lister med 10 sider.
	@media @mobileportrait {
		.ls-table-layout5 TR TD {
			border: none;
			border-top: solid 1px @brush_border_light;
			border-collapse: collapse;
			padding: 0;
		}
	}

	.ls-table-layout5 .paging TD {
		border-top: none;
	}



	.ls-table-layout5 TD img,
	.ls-table-layout5 TD A img {
		vertical-align: middle
	}

	.ls-table-layout5 TH,
	.ls-table-layout5 TH A,
	.ls-table-layout5 TH A:hover {
		font-weight: bold;
		text-align: center;
	}

	.ls-table-layout-altrow {
		tr > td {
			border-top: 1px solid #d3dae0;
			border-bottom: 1px solid #d3dae0;
		}

		tr:nth-child(even) > td {
			background-color: @brush_bg_highlight;
		}

		tr:nth-child(odd) > td {
			background-color: white;
		}
	}
	/**********************************************************/
	/* ls-table-layout6 (Tabellen anvendes ift. fraværårsager) **/
	/**********************************************************/

	table.ls-table-layout6 {
		border: none;
		width: 100%;

		tr,
		tr td,
		tr th {
			border-collapse: collapse;
			border: none;
			text-align: center;
			.label-medium;
		}

		tr th {
			font-weight: bold;
			padding-top: @std-table-padding;

			table table td,
			table table th {
				padding: 0;
			}
		}
	}


/* ********************************************************* */
/*       Table (lectiogridview styles)
/* ********************************************************* */
.separationCell,
.separationCell TD,
.separationCell TH {
	background-color: @brush_bg_highlight;

}

tr.newRow,
tr.newRow > td,
tr.newRow:nth-child(odd) > td,
tr.newRow:nth-child(even) > td {
	background-color: @brush_bg_highlightWeak;
}


tr.disabledRow * {
	text-shadow: none;
	color: @brush_disabled;

	&[data-lectiocontextcard]::before {
		opacity: 0.62;
	}
}

/* highlight table row on mouseover */
.highlightarea TR:hover TD {
	background-color: @brush_bg_highlightWeak;
	border-bottom-color: @brush_border_dark;
}

.highlightborder TR:hover TD {
	border-bottom-color: @brush_border_dark;
}

TH.row,
TR.row TH,
TABLE.row TH
/* til rækkeoverskrifter */ {
	font-weight: bold;
	color: Black;
	text-align: left;
	vertical-align: middle;
	border-collapse: collapse;
	white-space: nowrap;
	margin-left: 0;
}


TD.rowmid,
TH.rowmid,
TR.rowmid TD,
TR.rowmid TH,
SPAN.rowmid SPAN,
SPAN.rowmid SPAN A,
SPAN.rowmid SPAN A IMG,
SPAN.rowmid IMG {
	text-align: left;
	vertical-align: middle;
	border-collapse: collapse;
}

TH.rowtop,
TR.rowtop TH
/* til rækkeoverskrifter */ {
	text-align: left;
	vertical-align: top;
	white-space: nowrap;
}

TR.rowtop TD,
TD.rowtop {
	text-align: left;
	vertical-align: top;
}

.ls-new-row,
.ls-new-row TD {
	background-color: @color_lectio_Green !important;
}


/****************************************************/
/* Remove table styles ********************************************/
/****************************************************/
.noFrame,
.noFrame tr th,
.noFrame tr td,
.noBorder,
TABLE.noFrame,
TABLE.noFrame TR,
TABLE.noFrame TR TD,
TABLE.noFrame TR TH {
	border: none;
	border-width: 0;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
}



TD.noBorder,
table.noBorder,
table.noBorder td {
	border: none !important;
}

	/* fixed tabel header ************************************************************************/
	.fixedTableHeaderRow > TD,
	.fixedTableHeaderRow > TH,
	.fixedTableHeaderRow > GD {
		position: relative;
		box-sizing: border-box;
		background-image: -webkit-linear-gradient(top, #fcfcfc, #fafafa);
		background-image: -moz-linear-gradient(top, #fcfcfc, #fafafa);
		background-image: -ms-linear-gradient(top, #fcfcfc, #fafafa);
		background-image: -o-linear-gradient(top, #fcfcfc, #fafafa);
		background-image: linear-gradient(top, #fcfcfc, #fafafa);
		-moz-box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
		box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
		z-index: 1;
		border-style: solid;
		border-width: 1px;
		border-color: @brush_border_light;
		background-color: @brush_border_light;
	}

	.ls-scroll-fix-header {
		position: sticky;
		top: 0;
		z-index: 1;
		background-color: #fff;
	}
	/*************************************************************************/
	/*******FANEBLADE*********************************************************/
	/*************************************************************************/

	/* Sørger for fremhævning af det bogstav der er genvej */
	.shortcutletter, span.shortcutletter {
		text-decoration: underline;
		vertical-align: baseline;
	}

	/*************************************************************************/
	/*******FANEBLADE - TabStyle1 (standard) +  TabStyle2 (speciel eg. akt.forside) + TabStyle3 (speciel eg. forløb) + TabStyle4 (simpel) *********************************************************/
	/*************************************************************************/
	.ls-tabs1[class=ls-tabs1],
	.ls-tabs2[class=ls-tabs2],
	.ls-tabs3[class=ls-tabs3],
	.ls-tabs4[class=ls-tabs4] {
		display: table;
	}

.ls-tabs1,
.ls-tabs2,
.ls-tabs3,
.ls-tabs4 {
	min-width: 30rem;
	width: 100%;
	box-sizing: border-box;

	.lectioTabToolbar {
		box-sizing: border-box;
		background-image: none;
		margin-top: auto;
		margin-left: auto;
		margin-right: auto;
		border-left: none;
		padding-left: 0.4em;
		padding-top: 0.3em;
		font-size: 1.1em;
		display: flex;
		border-bottom: solid 1px @brush_border;
		overflow: visible;


		.button {
			margin-bottom: -1px;

			A,
			A:hover {
				box-sizing: border-box;
				background-image: none;
				border: none;
				display: inline-block;
				padding: 0.4em 0.7em 0.3em 0.7em;
				border-radius: 2px 2px 0 0;
				border-top: solid 1px @brush_border;
				border-right: solid 1px @brush_border;
				border-bottom: solid 1px @brush_border;
				border-collapse: collapse;
				background-color: transparent;
			}

			A:hover {
				/*background: @brush_linkBackgroundHover;
				border-top: solid 1px @brush_linkBackgroundHover;*/
				.primary-text;
				opacity:0.92
			}



			A[disabled="disabled"],
			A[disabled="disabled"]:hover {
				text-shadow: none;
				color: #b20c00 !important;
				border-top: solid 1px @brush_border;
				border-left: solid 1px @brush_border;
				border-bottom: solid 1px @solid_bg_canvas;
				margin-left: -1px;
				background-color: @solid_bg_canvas;
			}
		}

		.button:first-of-type A {
			border-top-left-radius: 4px;
		}

		.button:last-of-type A {
			border-top-right-radius: 4px;
		}

		.button:last-of-type A {
			margin-right: 1em;
		}
	}

	.lectioTabContent {
		box-sizing: border-box;
		min-height: 22em;
		border-radius: 2px 2px 4px 4px;
		padding: 0;
		border-left: none;
		border-right: none;
		border-bottom: none;
		border-top: none !important;
		padding-right: 1em;
	}

	.lectioTabFooter {
		background-color: transparent;
		border: none;
		height: auto;
		background-image: none;
	}

	.shortcutletter {
		text-decoration: underline;
	}
}
	/*******FANEBLADE - TabStyle1 only*********************************************************/
	.ls-tabs1 {
		min-width: 55.5em;

		.lectioTabToolbar {

			.button:nth-of-type(1) A {
				border-left: solid 1px @brush_border;
			}


			.button {
				A[disabled="disabled"],
				A[disabled="disabled"]:hover {
					border-bottom: solid 1px @solid_bg_canvas_light;
					background-color: @solid_bg_canvas_light;
				}
			}
		}

		.lectioTabContent {
			min-height: 22em;
			padding: 1em;
			padding-top: 1.6em;
			border: solid 1px @brush_border;
			background-color: @solid_bg_canvas_light;
		}
	}

	/******************************************************/

	/*******FANEBLADE - TabStyle2 only*********************************************************/
.ls-tabs2 {
	.lectioTabToolbar {
		border: none;

		.button:nth-of-type(1) A {
			border-left: none;
		}

		div.button {
			border: none;
			background: unset;
			border: unset;
			border-radius: unset;
			padding: unset;
		}

		div.button A, div.button A:hover {
			padding: 0.4rem 0.7rem;
			outline: none;
			border: none;
			background: unset;
			border: unset;
			border-radius: unset;
			.on-surface-variant-text;
		}

		div.button A[disabled="disabled"], div.button A[disabled="disabled"]:hover {
			border: none;
			background: unset;
			border: unset;
			border-radius: unset;
			border-bottom: solid 3px @--md-sys-color-primary;
			.primary-text !important;
		}
	}

	.lectioTabContent {
		border: none;
		background-color: transparent;
		color: unset;
		padding: unset;
		padding-top: 0.5rem;
	}
}
	/*******FANEBLADE - TabStyle3 only*********************************************************/
	.ls-tabs3 {
		.lectioTabToolbar {
			.button:nth-of-type(1) A {
				border-left: solid 1px @brush_border;
			}
		}

		.lectioTabContent {
			border: 1px solid @brush_border;
			background: @solid_bg_canvas;
			padding-top: 1em;
		}
	}
	/* ******FANEBLADE - TabStyle4 only******************************************************** */
	.ls-tabs4 {
		.lectioTabToolbar {
			.button:first-of-type A {
				border-left: solid 1px transparent;
			}

			.button:first-of-type A[disabled="disabled"] {
				border-left: solid 1px @brush_border;
			}

			.button:first-of-type A {
				border-radius: 4px 4px 0 0;
			}

			.button:last-of-type A {
				border-radius: 4px 4px 0 0;
			}

			.button {

				A,
				A:hover {
					border: none;
					border-top: solid 1px transparent;
					border-left: solid 1px transparent;
					border-right: solid 1px transparent;
					border-radius: 4px 4px 0 0;
				}

				A:hover {
					border: solid 1px @brush_linkBackgroundHover;
				}

				A[disabled="disabled"],
				A[disabled="disabled"]:hover {
					border-top: solid 1px @brush_border;
					border-right: solid 1px @brush_border;
					/*border-bottom: solid 1px white;*/
					background-color: transparent;
				}
			}
		}

		.lectioTabContent {
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: none;
			background-color: transparent;
			padding: 1em;
		}
	}
	/* end all tabs ****************************************************/
	/* mobil tabs	*/
	@media @mobile {
		.reset-tabs {
			padding: unset;
			line-height: unset;
			box-sizing: unset;
			background: unset;
			border: unset;
			border-radius: unset;
			display: unset;
			cursor: unset;
			color: unset;
			text-decoration: unset;
			margin: unset;
			position: unset;
			top: unset;
			height: unset;
			overflow-clip-margin: unset;
			overflow: unset;
			transition: unset;
			border: none;
			min-height: unset;
			min-width: unset;
			width: unset;

			.button a {
				outline: none;
			}
		}



		.ls-tabs1[class=ls-tabs1],
		.ls-tabs3[class=ls-tabs3],
		.ls-tabs4[class=ls-tabs4] {
			.reset-tabs;
		}

		.ls-tabs1,
		.ls-tabs3,
		.ls-tabs4 {
			.reset-tabs;

			.lectioTabToolbar {
				.reset-tabs;

				.button {
					.reset-tabs;

					A, A:hover {
						.reset-tabs;
					}

					A[disabled="disabled"],
					A[disabled="disabled"]:hover {
						.reset-tabs;
					}
				}

				.button:first-of-type A {
					.reset-tabs;
				}

				.button:last-of-type A {
					.reset-tabs;
				}

				.button:last-of-type A {
					.reset-tabs;
				}
			}
		}
		/*set desktop tab3 style = tab2 style for mobile*/
		.ls-tabs1[class=ls-tabs1], .ls-tabs3[class=ls-tabs3], .ls-tabs4[class=ls-tabs4] {
			.ls-tabs2;
			min-width: calc(100dvw - (@ls-padding-page-container-right + @ls-padding-page-container-left));

			.lectioTabToolbar {
				overflow-x: scroll;
				overflow-y: hidden;
				width: 95dvw;
				padding-bottom: 0.75em;
				flex-wrap: unset;
			}
		}
		/* 231103 JTJ: Midlertidigt fix til aktivitetsforsiden, hvor ny mobil styling gør siden for bred så man kan scrolle horizontalt
		(Kun som lærer, da de har 3 tabs). Der bør nok gøres noget generelt for sider der har tabs, men som er tilpasset til ikke at scrolle sidelæns på mobil.*/
		/*.ls-toccontent-container .ls-tabs3 .lectioTabToolbar {
			font-size: 1em;
			padding: 0;

			div.button a {
				padding-left: 0.35em;
				padding-right: 0.35em;
				margin-right: 0em !important;
			}
		}*/
		/*--- Midlertidigt fix til aktivitetsforsiden SLUT --- */
	}

	.lectio_panelContainer {
		display: table;
		width: 86.0em;
		padding: 0.45em 0.45em 1em 0.45em;
		margin: 0.45em 0 1em 0;
		vertical-align: top;
		border: 1px dotted @brush_border;
	}
	/* ls-island2, ls-island3 **************************************************/
	.ls-island2 {
		padding: 1em 0.8em 1em 0.8em;
		line-height: 16px;
	}

	.ls-island3 {
		min-height: 10em;
		background-color: #ffffff;
		border: solid 1px @brush_border_light;
		border-radius: 0.2em;
		box-shadow: 0.2em 0.3em 1.2em #ddd;
		overflow: auto;
		background: white;
		padding: 2em 2em 6em 2em;
		margin-bottom: 2em;
		box-sizing: border-box;
		line-height: 16px;
	}


	.ls-island2 .islandHeaderContainer, .ls-island3 .islandHeaderContainer {
		display: flex;
		justify-content: space-between;
	}

	.ls-island2 .islandHeader,
	.ls-island3 .islandHeader {
		font-size: 1.4em;
		color: #475968;
	}
	/* lines, borders ******************************************/
	HR,
	.ls-hr-dotted,
	.ls-hr-solid,
	.ls-hr-solid-thick {
		display: block;
		border: none;
		height: 1px;
		border-bottom: dotted 1px @brush_border;
	}

	.ls-hr-solid,
	.ls-hr-solid-thick {
		border-bottom: solid 1px @brush_border_light;
	}

	.ls-hr-solid-thick {
		border-width: 6px;
	}

	.lectioBorder {
		border-top: solid 1px @brush_border_light;
		border-bottom: solid 1px @brush_border;
		border-left: solid 1px @brush_border;
		border-right: solid 1px @brush_border;
	}

	.lectioBorderTop,
	.lectioBorderTop TD,
	.lectioBorderTop TH {
		border-top: solid 1px @brush_border_light;
	}

	.lectioBorderBot,
	.lectioBorderBot TD,
	.lectioBorderBot TH {
		border-bottom: solid 1px @brush_border;
	}

	.lectioBorderLeft {
		border-left: solid 1px @brush_border;
	}

	.lectioBorderRight {
		border-right: solid 1px @brush_border;
	}
	/*** Context menu ******************************************/
	DIV[lectiocontextcard],
	A[lectiocontextcard],
	SPAN[lectiocontextcard],
	TD[lectiocontextcard],
	TH[lectiocontextcard],
	H4[lectiocontextcard],
	.s2contextmenu {
		cursor: context-menu;
		cursor: url('/lectio/img/context-menu.png'), context-menu;
	}

	#jqContextMenu {
		position: absolute;
		z-index: 500;
	}

	#jqContextMenu .lec-context-menu {
		display: block;
	}
	/*** END Context menu **************************************/
	/*pop ups*/
	.ui-dialog {
		text-align: left;
	}

	.stickyElement {
		position: fixed !important;
		top: 0;
	}
	/*** START Cards **************************************/
	.ls-card-base {
		.body-small;
		box-sizing: border-box;
		overflow: auto;
		border-radius: 0.5rem;
		padding: 0.875rem;
		padding-left: 1.333rem;
		padding-right: 1.333rem;

		div[role=heading] > span:first-of-type {
			.on-surface-text;
			.title-medium;
			margin-top: 0;
			margin-bottom: 0;
		}
	}


	.ls-card-elevated {
		.ls-card-base;
		border: none;
		.ls-elevation-level1;
		outline: 1px solid @--md-ref-palette-neutral95;
		.surface-container-low;
	}

	.ls-card-filled {
		.surface-container-highest;
		.ls-card-base;
		border: none;
		box-shadow: none;
	}

	.ls-card-outlined {
		.outline-variant;
		.ls-card-base;
		box-shadow: none;
		.surface-container-low;
	}

	.ls-card-nostyle {
		background-color: unset;
		border-top: unset;
		border-right: unset;
		border-bottom: unset;
		border-left: unset;
		border: none;
		background-image: none;
		box-shadow: none;
		.ls-card-base;
	}
	/*** END Cards **************************************/
	/* MD style islands **************************************/
	.ls-island-outlined, .ls-island-filled, .ls-island-elevated, .ls-island-nostyle {

		div.islandHeaderContainer[role=heading] {
			display: flex;
			justify-content: space-between;
			margin-bottom: 0.7em;

			span.islandHeader {
				.on-surface-text;
				.title-medium;
				margin-top: 0;
				margin-bottom: 0;
			}
		}
	}

	.ls-island-outlined {
		.ls-card-outlined;
	}

	.ls-island-filled {
		.ls-card-filled;

		&.noheader {
			div.islandHeaderContainer[role=heading] {
				display: none;
			}
		}
	}

.ls-island-elevated {
	.ls-card-elevated;

	&.noheader {
		div.islandHeaderContainer[role=heading] {
			display: none;
		}
	}
}

.ls-island-content-menu {
	display: none;
}

	.ls-island-nostyle {
		.ls-card-nostyle;

		&.noheader {
			div.islandHeaderContainer[role=heading] {
				display: none;
			}
		}
	}
	/*** END MD style islands **************************************/
	/* Er container til øer, der gør at de placeres ved siden af hinanden.  Sættes på ydre div (container), herved vil underliggende islands ø'er placeres ved siden af hinanden og alignes til top */
	.ls-std-island-layout-ltr {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		/* strækker island højde, kan bruges ved flere øer ved siden af hinanden for ens island højde */
		align-items: stretch;

		.island, .ls-island-outlined, .ls-island-filled, .ls-island-elevated, .ls-island-nostyle {
			margin-right: 1rem;
		}

		.smallBlockHeight, .mediumBlockHeight, .largeBlockHeight .xlargeBlockHeight {
			align-self: flex-start;
		}
	}


	@media @mobile {
		.island-reset {
			background-color: unset;
			border-top: unset;
			border-right: unset;
			border-bottom: unset;
			border-left: unset;
			border: none;
			background-image: none;
			box-shadow: none;
			padding-bottom: 0.25em;
			padding-top: 0.25em;
			padding-left: 0;
			padding-right: 0;
			outline: none;
		}

		SECTION.island, SECTION.ls-island-outlined, SECTION.ls-island-elevated, SECTION.ls-island-filled, SECTION.ls-island-nostyle {
			.island-reset;

			div.islandHeaderContainer {
				.island-reset;

				.islandHeader {
					.island-reset;
					padding: unset;
				}
			}

			.islandContent {
				.island-reset;
				/*padding: unset;*/

				.dashboardLinkHeader {
					.island-reset;

					a.dashboardItemTitle > .dashboardLinkHeaderText, .dashboardLinkHeaderText {
						.title-medium;
						.primary-text;

						@media @mobile {
							.title-small;
						}
					}
				}
			}

			.ls-card-nostyle;
			padding: 0;
			border-radius: unset;
			&:not(:last-child){border-bottom: solid 1px @--md-ref-palette-neutral80;}
		}


		DIV.ls-container,
		TABLE.form,
		DIV.form,
		DIV.panel,
		TABLE.panel {
			box-sizing: border-box;
			border: unset;
			vertical-align: unset;
			padding: unset;
			margin: unset;
			overflow: unset;
			height: unset;
			background-color: unset;
			border-radius: unset;
		}

		.ls-std-island-layout-ltr {
			flex-wrap: wrap;
			display: flex;
		}

		.island, .ls-island2, .island3, .ls-island-elevated, .ls-island-filled, .ls-island-outlined, .ls-island-nostyle {

			&.defaultBlock, &.smallBlock, &.smallBlockFixed, &.mediumBlock, &.mediumBlockFixed, &.largeBlock, &.largeBlockFixed, &.xlargeBlock, &.xlargeBlockFixed {
				min-width: 100%;
				margin-right: 0;
			}
		}
		/* ls-mobile-content-fullwidth - tilpasser sidens indhold til siden på mobil */
		.ls-mobile-content-fullwidth {
			.ls-std-rowblock > div {
				display: flex;
				flex-wrap: wrap;
			}

			SECTION.island {
				.islandHeaderContainer {
					height: unset;
				}

				table.ls-std-table-inputlist {
					width: 100%;
					table-layout: auto;
				}

				table.ls-table-layout1, table.list, table.ls-table-layout2 {
					width: 100%;
					table-layout: auto;
					background: transparent;

					tr:last-child td {
						border-bottom: none;
					}
				}

				.noRecord.nowrap {
					white-space: pre-wrap;
				}

				table.ls-table-layout1, table.list, table.ls-table-layout1 tr, table.list tr {
					border: none;

					th, th a {
						.label-large
					}

					th, td, .OnlyMobile, .OnlyDesktop {
						border-left-width: 0;
						border-right-width: 0;
						border-top-width: 0;
					}
				}
			}
		}
	}


	@media @mobile {
		/* tiltænkt sider, hvor det ser sidetitel og den efterfølgende island-titel er ens, næsten ens - sæt denne class: islandHeader-hidden-smallscreen på lectiodetailisland */
		SECTION.island[class~='islandHeader-hidden-smallscreen'] {
			.islandHeaderContainer {
				display: none;
			}
		}
	}

	.file-framing-less-end {
		color: blue;
	}