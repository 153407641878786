.file-contextcard-less {
	color: blue;
}
/*************************/
/* Context Card **********/
/*************************/
.closeContextCard {
	background-image: url('/lectio/img/close.png');
	background-repeat: no-repeat;
	background-position: right top;
	display: block;
	text-indent: -99999px;
	overflow: hidden;
	background-repeat: no-repeat;
	position: absolute;
	top: 27px;
	right: 7px;
	width: 100%;
	height: 16px;
}

.loadingContextCard {
	background-color: #f5f5f5;
	padding: 0.4em;
	display: block;
}



[data-lectiocontextcard]:hover {
	/*	opacity: 0.8;*/
	
	
}

a[data-lectiocontextcard]:hover {
	/*opacity: 1;*/
}

.ac_results [data-lectiocontextcard]:hover {
	/*opacity: 1;*/
}

/**** END Context Card ******/
