.file-lc-new-less {
	color: blue;
}
/*

Denne css-fil skal hverken rumme mere eller mindre, end det der skal til for
fuldstændigt at beskrive

hvorledes dokumenter skal vises. Det skal være muligt at vise dokumenter
udelukkende ved brug af dette stylesheet.

Dvs. at denne SKAL VÆRE UAFHÆNGIG af "normal" lectio-styling, og styling der
ikke bruges i forbindelse med dokumenter, skal ikke være defineret i dette
stylesheet.

Navngivningskonventioner:
- "lc-"-præfiks: Klasser, der bruges *i* dokumenter. Da de bliver gemt i dokumenter,
   har de større krav til bevaring af opførsel end andre klasser.
- "lc-display-"-præfiks: Klasser, der bruges til at pakke dokumenter ind. Disse
   har mindre krav krav til bevaring af opførsel.
- "lc-build-"-præfiks: less-byggeklodser, der ikke skal anvendes i markup, men
   kun anvendes af andre regler.

*/

@import (reference) "constants.less";
@import (reference) "framing.less";

@font-family: sans-serif;
@line-height: 1.4;
@font-size: 12px;
@lc-h3: @font-size + 4px;
@lc-h2: @lc-h3 + 2px;
@lc-h1: @lc-h2 + 4px;


a.xx {
	color: red;
}

/* .ls-section-group-heading (TOC: Oversigt, Forløbsmateriale, Quizzer, Aktiviteter) */
.ls-section-title-heading
/*(Content: Oversigt, Quiz, Aktivitetmodul)*/ {
	font-size: @lc-h1;
}

.ls-section-subgroup-heading
/*(lektier, øv.ind, lærer/elevnavn)*/ {
	margin: 0;
	padding: 0;
	border: 0;
	outline: 0;
	margin-top: 1em;
	margin-bottom: 1em;
	font-size: @ls-h1;
	font-weight: 700;
	color: @brush_text_heading;
}


.lc-display-commentblock-basic {
	background-color: #fdfdf9;
	border: 1px solid #ededed;
	color: #666;
}

@container-width: 100%;
@container-max-width: 1010px;


@paper-width: 708px;
@paper-max-width: 708px;
@paper-compact-threshold: 700px;

@paper-text-top-indent: 1.8rem;
@paper-text-left-indent: 4.4rem;
@paper-text-right-indent: 2.2rem;

@paper-text-bottom-indent: 1.8rem;
@paper-icon-left-indent: 2.4rem;
/*@paper-text-left-indent - 1.4rem*/
@paper-section-left-indent: 1.05rem;
/*@paper-text-left-indent - 2.75rem*/

@ul-ol-left-indent: 4rem;


.ls-paper-section-heading {
	margin-left: -@paper-section-left-indent;
	@media @mobile {
		padding-left: 1rem;
		margin-top: 8px;
		margin-bottom: 0.3rem;
	}
}
/*@media @mobile {
	.ls-section-title-heading.ls-section-title-heading {
		margin-left: 0;
	}
}*/

.ls-paper-section-heading .s2skemabrik-std-title, .ls-section-title-heading .s2skemabrik-std-title {
	white-space: normal;
	word-break: break-word;
	line-height:normal;
}

.ls-section-title-heading .s2skemabrik.s2cancelled {
	white-space: nowrap;

	@media @mobileportrait {
		white-space: unset;
	}
}


.ls-paper-section-heading-checkbox {
	display: inline-block;
	padding-top: 0.2rem;
	cursor: pointer;
	margin-left: -@paper-icon-left-indent;
}

.ls-paper-section-heading-icon {
	display: inline-block;
	margin-left: -@paper-icon-left-indent + 1.05rem;
	position: relative;
	top: 2px;
}

.ls-paper-section-heading-icon-small {
	display: inline-block;
	margin-left: -@paper-icon-left-indent + 1.25rem;
	position: relative;
	top: 4px;
}

.ls-paper-subsection-separator {
	margin-left: -@paper-section-left-indent;
	width: calc(100% + (calc(@paper-section-left-indent * 1rem)));
}

.ls-paper-section-heading-icon.ls-share-buttonicon {
	cursor: pointer;
	opacity: 0.8;

	&:hover {
		opacity: 1;
	}
}



/* Áktivitetstitel */
input.activity-title {
	background-image: none;
	background-color: #f8fcff;
	border: 1px solid #ededed;
	color: #333;
	padding: 1.3em 1.3em 1.3em 1.3em;
	display: block;
	line-height: 1.4em;
	min-height: 16px;
	border-radius: 0.4em;
	margin-top: 0.2rem;
	margin-left: 1rem;
	margin-right: 1rem;
	margin-bottom: 0.5rem;
	font-size: 12px;

	&::placeholder {
		font-style: italic;
		color: #666;
	}
}
/* Áktivitetsnote */
textarea.activity-note[disabled] {
	background-color: #fff;
	border: solid 1px #fff;
	color: #000;
	padding: 0;
	line-height: 1.4em;
	margin-top: 0;
	display: block;
	overflow-wrap: break-word;
	word-break: break-word;
	min-height: 16px;
	border-radius: 0.4em;
	margin-right: 1rem;
	margin-bottom: 1rem;
	overflow-y: hidden;
	resize: none;
	margin-left: -1.2rem;
	font-size: 12px;

	&.automargin {
		margin-left: auto
	}
}

textarea.activity-note:not([disabled]) {
	background-color: #fdfdf9;
	border: 1px solid #ededed;
	color: #333;
	padding: 1em 1.3em 1.3em 1.3em;
	display: block;
	line-height: 1.4em;
	border-radius: 0.4em;
	margin-top: 0;
	margin-left: 1rem;
	margin-right: 1rem !important;
	margin-bottom: 1rem;
	overflow-y: hidden;
	resize: none;
	font-size: 12px;

	&::placeholder {
		font-style: italic;
		color: #666;
	}

	&:hover {
		border-color: @color_lectio_LightBlue;
	}
}

/*** *************************************************************/
/*** Paper ***************************************************/
/*** *************************************************************/
.lc-display-fragment {
	background-color: white;
	font-family: @font-family;
	font-size: @font-size;
	line-height: @line-height;
	word-break: normal;
	word-wrap: break-word;

	&.lc-display-fragment-no-padding {
		padding: 0;
	}

	&.lc-display-editor-inline {
		margin: 8px;
		padding: 1em;
		box-shadow: 0 0 6px 1px rgba(153, 153, 153, 0.4);
		height: calc(100% - 2em);
		overflow: auto;

		&.lc-display-editor-dimmedtemplate {
			color: #999999;
		}
	}

	h1,
	h2,
	h3,
	h4 {
		color: @brush_text_heading;
		font-weight: 100;
		line-height: 1;
		margin-top: 1.2rem;
	}



	h1 {
		/*
			20180222: Flg. margin-bottom indfoert ifbm. at klassen lc-display-fragment flyttes fra morfar til foraelder for indholdet, og ikke laengere
			pakker indhold uden for slides ind in section-elementer:
			Browsere har det med at have en margin-bottom for h1-elementer paa 0.83em.
			Men derudover har i det mindste Chrome og FF en indbygget styling af "section section h1", der saetter -webkit-margin-after" til 1em.
			Indhold uden for slides plejede at blive ramt af denne regel, fordi dette indhold blev pakket ind i section-elementer for at kunne
			vises i fuldskarms-/slideshow-mode.
			Det er ikke den store forskel, her er nogle konkrete tal: margin-bottom gik i Chrome fra 22.8px til 18.92px.
			I IE11 gik den fra 18.92px til 15.27px, men det er uklart hvorfor, da IE ikke oplyser sine egne styles. Vi viste altsaa ikke indholdet ens paa tvaers
			af browsere...
			Naervaerende margin-bottom er indfoert for at standardisere styling paa tvars af browsere, omend det betyder at layoutet i chrome og FF
			aendrer sig en lille smule.
			https://stackoverflow.com/questions/6867254/browsers-default-css-for-html-elements/6867287#6867287
		*/
		margin-bottom: 0.83em;
	}



	h1 > img.header-checkbox,
	h2 > img.header-checkbox,
	h3 > img.header-checkbox,
	h4 > img.header-checkbox,
	h4 > img.header-checkbox {
		vertical-align: top;
		cursor: pointer;
		position: relative;
		top: 0;
		left: -2.4rem;
	}

	h1 > img.header-checkbox {
		top: 3px;
	}

	h1[data-lc-header-variant=activity] > img.header-checkbox,
	h1[data-lc-header-variant=resources] > img.header-checkbox {
		top: 6px;
	}

	h2 > img.header-checkbox {
		margin-top: 0;
	}

	.lc-display-heading {
		display: flex;
		align-items: stretch;
		justify-content: flex-start;

		.lc-display-swup-button {
			flex-grow: 1;
			text-align: right;
			min-width: 2.2rem;
		}
	}


	&.lc-display-withheadercopybuttons > h1,
	&.lc-display-withheadercopybuttons > h2,
	&.lc-display-withheadercopybuttons > h3 {
		&::before {
			content: "\2007";
			display: inline-block;
			float: right;
			background: url(/lectio/img/phase-send-to.auto) top right no-repeat;
			width: 1.3rem;
			cursor: pointer;
			margin-right: -1rem;
			margin-top: 0.15rem;
		}

		&:hover::before {
			opacity: 0.8;
		}
	}

	&.lc-display-withheadercopybuttons > h1[data-lc-header-variant=resources]:not(:first-child),
	&.lc-display-withheadercopybuttons > h1[data-lc-header-variant=activity]:not(:first-child) {
		&::before {
			margin-top: -20px;
		}
	}


	h1 {
		font-size: @lc-h1;
	}

	h2 {
		font-size: @lc-h2;
	}

	h3 {
		font-size: @lc-h3;
	}

	h4 {
		font-size: @font-size;
		font-style: oblique;
	}

	p,
	h1,
	h2,
	h3,
	h4 {
		/* Disse er "afsnits"-elementer. Flg. style aht. naar der kommer en tabel lige foer, idet tabeller har float: left. */
		clear: both;
	}

	& > a {
		/*fix visning af a-elementer der af historiske årsager var placeret i roden (uden for p-, eller headertags)*/
		display: inline-block;
		max-width: calc(@paper-width - (@paper-text-right-indent + @paper-text-left-indent));

		@media (max-width: @paper-compact-threshold) {
			max-width: calc(100vw - (@paper-text-right-indent + @paper-text-left-indent));
		}
	}

	a {
		text-decoration: underline;
	}

	a[data-lc-display-linktype]:not(.lc-display-nakedlink) {
		padding-right: 16px;
		background-position: center right;
		background-repeat: no-repeat;
	}

	a[data-lc-display-linktype=file]:not(.lc-display-nakedlink) {
		background-image: url(/lectio/img/file16x16.auto);
	}

	a[data-lc-display-linktype=external]:not(.lc-display-nakedlink) {
		background-image: url(/lectio/img/link.auto);
	}
	/* .lc-display-nakedlink: A-elementer der vises "simplificeret" i en overskrift, og ikke skal pyntes som lc- og lektie-links ellers
	 * maatte blive det. */
	a.lc-display-nakedlink {
		text-decoration: none;
	}

	ul {
		list-style-type: disc;

		li ul {
			list-style-type: circle;

			li ul {
				list-style-type: disc;

				li ul {
					list-style-type: circle;
				}
			}
		}
	}

	ul,
	ol {
		list-style-position: outside;
		padding-left: @ul-ol-left-indent;
	}

	blockquote {
		margin-bottom: 16px;
		padding: 1em 1.3em 1.3em 5em;
		border: 1px solid #f5f5f5;
		background: #fafafa;
		border-radius: 0.4em;
		background-image: url('/lectio/img/quote.auto');
		background-repeat: no-repeat;
		background-position: 6px 16px;
		background-size: 40px 40px;
		margin-left: 4rem !important;
		margin-right: 4rem !important;
	}
	/* Lektienoter */
	blockquote[data-lc-role=note] {
		background-image: none;
		background-color: #fdfdf9;
		border: 1px solid #ededed;
		padding-left: 1.3em;
		color: #666;
		/* Skal opfoere sig lidt mere som et tekstfelt: Ikke afstand mellem linierne. */
		> p {
			margin: 0;
		}
	}



	pre {
		/* safari */
		font-family: monospace;
		/* Blot en lille smule.. */
		padding-left: 1em;
	}

	iframe.ls--video, img.ls--video, img[data-lc-type="external"] {
		min-width: 420px;
		max-width: 100%;
		min-height: 236px;
		max-height: 100%;
	}

	@media only screen and (max-device-width: 414px) and (orientation: portrait) {
		iframe.ls--video, img.ls--video, img[data-lc-type="external"] {
			min-width: 320px;
			max-width: 90vw;
			min-height: 180px;
			max-height: 100vw;
		}
	}
	/* Flg. to (embed img og iframe) hører sammen. */
	img[data-lc-type="external"] {
		-o-mask-image: url(/lectio/img/play-overlay.svg);
		-o-mask-repeat: no-repeat;
		-o-mask-position: center center;
		-o-mask-size: contain;
		-moz-mask-image: url(/lectio/img/play-overlay.svg);
		-moz-mask-repeat: no-repeat;
		-moz-mask-position: center center;
		-moz-mask-size: contain;
		-ms-mask-image: url(/lectio/img/play-overlay.svg);
		-ms-mask-repeat: no-repeat;
		-ms-mask-position: center center;
		-ms-mask-size: contain;
		-webkit-mask-image: url(/lectio/img/play-overlay.svg);
		-webkit-mask-repeat: no-repeat;
		-webkit-mask-position: center center;
		-webkit-mask-size: contain;
		mask-image: url(/lectio/img/play-overlay.svg);
		mask-repeat: no-repeat;
		mask-position: center center;
		mask-size: contain;
		cursor: pointer;


		&:hover {
			cursor: pointer;
			opacity: 0.9;
		}
	}

	img[data-lc-type="audio"] {
		cursor: pointer;
	}
	/* Advarsel billeder sat ind fra clip board på Mac, da de kun gemmes lokalt på maskinen*/
	img[src*="webkit-fake-url://"] {
		border: 5px solid red;
		max-width: 25%;
		max-height: 25%;
	}

	blockquote[data-lc-role="note"] textarea {
		.lc-display-commentblock-basic;
		border: none;
		font-size: @font-size;

		&:focus {
			outline: none;
		}
	}

	textarea-rh--sourcebox {
		.lc-display-commentblock-basic;
		font-family: Consolas, 'Courier New';
		font-size: @font-size;
	}

	textarea.cke_source {
		font-family: Consolas, Courier New, Courier, monospace;
	}

	input {
		border-left-color: #444;
		border-top-color: #444;
	}
	/** Slides: Slides er defineret til at vaere 600px brede, inkl. padding. CSS 'width' angiver indholdets bredde ekskl. padding.
		Da vi gerne vil have noget margin paa slides, angiver vi 'width' til 600px - padding. Det er vigtigt at denne relation bevares,
		for ellers vil indhold paa nogle slides blive oedelagt.

		Afprøvning: Husk at komme rundt: fuldskærm/ikke-fuldskærm, slide/ikke-slide, blandet indhold i samme dokument (slide og ikke-slide).
	*/
	@slide-width: 600px;
	@slide-height: 450px;
	@slide-padding-left: 22px;
	@slide-padding-right: 22px;
	@slide-padding-top: 0;
	@slide-padding-bottom: 0;

	& section.lc-document-slide {
		box-sizing: border-box;
		padding-left: @slide-padding-left;
		padding-right: @slide-padding-right;
		padding-top: @slide-padding-top;
		padding-bottom: @slide-padding-bottom;
		width: @slide-width;
		/*- @slide-padding-left - @slide-padding-right;*/
		/*max-height: @slide-height;*/
		height: @slide-height;
		overflow-y: auto;
		font-size: @lc-h3 - 2px;
		background: white;
		border: 1px solid #999;
		border-radius: 4px;
		/* Vil gerne have top- og bundpadding på slides uafhængig af hvad første og sidste element er.
			Men vi kan dårligt angive ikke-nul-padding på slide-elementer, for de bliver lagt oven i første og sidste elements margin,
			saa padding ender med at vaere for stor og varierende.
		*/
		& > * {
			box-sizing: initial;
		}

		& > :first-child {
			margin-top: 20px;
		}

		& > :last-child {
			margin-bottom: 20px;
		}

		@media @mobile {
			width: auto;
			height: auto; //sæt evt. min højde?
			max-height: 800px;
			//margin-left: -22px;
			//margin-right: 2px;
			ul, ol {
				padding-left: 2.5rem;
			}
		}
	}

	& section {
		/* Denne margin får (også) indholdet til at blive vist centreret i fuldskærm. */
		/*margin: 0 auto;*/
	}

	& section:not(.lc-document-slide):not(.lc-display-commentblock) {
		/* Skal skal ikke være for bred, aht. læsbarhed. Desuden så smal at skift hen til
			sektionen ikke forårsager at knapperne i toppen rykker til siden.
		*/
		margin: 0 0;
		max-width: @paper-width;
	}

	&.lc-display-slidemode > section.lc-document-slide {
		height: @slide-height;
	}
	/* Flg. to er blot for at give lidt afstand. */
	&:not(.lc-display-slidemode) section.lc-document-slide {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	/*
	&.cke_editable section.lc-document-slide {
		margin-bottom: 1em;
	}*/
	// "knap" ud for headere.
	&.cke_editable.lc-display-editor-withhomeworkheadingbuttons > h1,
	&.cke_editable.lc-display-editor-withhomeworkheadingbuttons > h2,
	&.cke_editable.lc-display-editor-withhomeworkheadingbuttons > h3 {
		margin-top: 3rem;

		&::before {
			content: attr(data-lc-display-header-info);
			right: 0rem;
			position: absolute;
			// rem er lidt paenere: Aendrer ikke paa pladsen naar man skifter ml. h1, h2 og h3.
			font-size: 0.8rem;
			white-space: pre;
			text-align: right;
			cursor: pointer;
			/*********************************/
			height: 1rem;
			border: 1px solid @color_lectio_GrayBlue1;
			padding-bottom: 0.1rem;
			padding-top: 0.3rem;
			padding-left: 0.4rem;
			padding-right: 1.3rem;
			border-radius: 0.2rem;
			color: @color_lectio_GrayBlue1;
			background: url(/lectio/img/chevron_down_dark.auto) top right no-repeat;
			background-color: white;
			background-position-x: calc(100% - 2px);
			background-position-y: 0.1rem;
			margin-top: -1.7rem;
			margin-right: 1rem;
		}

		&:hover::before {
			background: url(/lectio/img/chevron_down_blue.auto) top right no-repeat;
			background-position-x: calc(100% - 2px);
			background-position-y: 0.1rem;
			border: 1px solid @color_lectio_LightBlue;
			color: @color_lectio_LightBlue;
		}
	}

	.cke_editable.lc-display-editor-withhomeworkheadingbuttons > h1[data-lc-header-variant],
	.cke_editable.lc-display-editor-withhomeworkheadingbuttons > h2[data-lc-header-variant],
	.cke_editable.lc-display-editor-withhomeworkheadingbuttons > h3[data-lc-header-variant] {
		&::before {
			padding-left: 0.4rem;
			padding-right: 1.3rem;
		}
	}
	/* start h1-varianter ********************/
	& > h1[data-lc-header-variant=activity] {
		background: url(/lectio/img/lesson_large.auto) no-repeat;
	}

	& > h1[data-lc-header-variant=resources] {
		background: url(/lectio/img/folder.auto) no-repeat;
	}

	& > h1[data-lc-header-variant=resources] {
		background-size: 20px 20px;
	}
	/* ikoner ud for headere */
	& > h1[data-lc-header-variant=resources],
	& > h1[data-lc-header-variant=activity] {
		font-weight: 600;
		font-size: 2.5em;
		background-position-y: 1.60em;
		background-position-x: @paper-text-left-indent - 1.3rem;
		padding-top: 1.45em;
	}

	& > h1[data-lc-header-variant=resources]:not(:first-child),
	& > h1[data-lc-header-variant=activity]:not(:first-child) {
		border: 24px solid transparent;
		border-image: url(/lectio/img/pageshift.png) 100% 0 0 0 repeat;
		border-image-repeat: repeat;
		border-bottom: 0;
		border-left: 0;
		border-right: 0;
	}
	/* slut h1-varianter ********************/
	/* Adskillelse mellem headere af type aktivitet */
	& > h1,
	& > h2,
	& > h3 {
		&[data-lc-header-variant=presentation] {
			background: url(/lectio/img/presentation.auto) no-repeat;
		}

		&[data-lc-header-variant=teacherfeedback] {
			background: url(/lectio/img/question_bubble.auto) no-repeat;
		}

		&[data-lc-header-variant=homework] {
			background: url(/lectio/img/doc-homework.auto) no-repeat;
		}

		&[data-lc-header-variant=other] {
			background: url(/lectio/img/doc-not-homework.auto) no-repeat;
		}

		&[data-lc-header-variant] {
			background-position-x: @paper-text-left-indent - 1.1rem;
		}

		&[data-lc-header-variant=activity],
		&[data-lc-header-variant=resources] {
			/* aktivitets- og ressourcemappe-ikonerne er lidt stoerre end de andre ikoner, saa de har behov for lidt mere plads. */
			background-position-x: @paper-text-left-indent - 1.4rem;
		}
	}

	padding: 0;
	background: @solid_bg_paper;


	& > section:not(.lc-document-slide) > * {
		margin-left: @paper-text-left-indent;
		margin-right: @paper-text-right-indent;
	}
	/* ls-acr-display refererer til beregnet anvendelse: actcontentrowdisplay.ascx. Samt aktivitetsforside. */
	&.ls-acr-display > * {
		padding-left: @paper-text-left-indent + 0.5;
		padding-right: @paper-text-right-indent;
		/* 2018-07-06 RH HACK for at faa lektie/acr-visning til at kunne bruge samme ikon-css-struktur/-strategi som
		 * overskrifstyper i lc-indhold normalt benytter, isf. at komme med eget img-element m.m. */
		margin-left: -1 * @paper-text-left-indent;
		/* 2018-07-06 RH: Flg. er rip-off fra de normale lc-ikoner og deres styling. Her bruger vi ikoner lidt anderledes,
		 * mest af historiske aarsager. Mon ikke at vi faar ryddet op i det en dag...  */
		@media @mobile {
			padding-left: @paper-text-left-indent;
		}

		& > h1,
		& > h2,
		& > h3 {
			background-position-x: @paper-text-left-indent - 1.1rem;
			/*@paper-icon-left-indent+0.2rem;*/
		}
	}

	&.ls-acr-display {

		& > h1,
		& > h2,
		& > h3 {
			background-repeat: no-repeat;
			background-position-x: 3.3rem;
		}

		& > h1 {
			background-position-y: 0.15rem;
		}

		& > table {
			padding-left: 0;
			padding-right: 0;
			margin-left: 0;
			margin-right: 0;
			overflow: auto;
			display: block;
		}

		& section.lc-document-slide {
			margin-left: 0;
		}

		& section:not(.lc-document-slide) {
			margin-left: 0;
			padding-left: 0
		}

		& > ul,
		& > ol {
			padding-left: @ul-ol-left-indent + @paper-text-left-indent;

			@media @mobile {
				padding-left: 6.5rem;
			}
		}
	}

	&.ls-phase-material {
		& > * {
			/* paper margins: left, right */
			padding-left: @paper-text-left-indent;
			padding-right: @paper-text-right-indent;

			@media (max-width: @paper-compact-threshold) {
				padding-left: 1.3rem;
			}
		}


		& > h1,
		& > h2,
		& > h3 {


			&[data-lc-header-variant] {
				@media (max-width: @paper-compact-threshold) {
					background-position-x: 0.3rem;
				}
			}

			&[data-lc-header-variant="activity"],
			&[data-lc-header-variant="resources"] {
				@media (max-width: @paper-compact-threshold) {
					background-position-x: 0.1rem;
				}
			}

			&:not([data-lc-header-variant]) {
				background: url(/lectio/img/emptydoc.auto) no-repeat;
				background-position-x: @paper-text-left-indent - 1.1rem;

				@media (max-width: @paper-compact-threshold) {
					background-position-x: 0.3rem;
				}
			}
		}

		& > h1 {
			&:not([data-lc-header-variant="activity"]):not([data-lc-header-variant="resources"]) {
				background-position-y: 0.15rem;
			}
		}

		& > table,
		& > section {
			margin-left: @paper-text-left-indent;
			margin-right: @paper-text-right-indent;
		}

		& > ul,
		& > ol {
			margin-left: @paper-text-left-indent;
		}
	}

	&.ls-phase-material {
		& section:not(.lc-document-slide) {
			width: @paper-width;
			overflow: auto;
			box-sizing: border-box;
			box-shadow: inset 0 0 2px #333;
		}

		width: @paper-width;
		overflow: auto;
		box-sizing: border-box;
		box-shadow: inset 0 0 2px #333;
		-moz-box-shadow: inset 0 0 1px #333;
		box-shadow: inset 0 0 2px #333;
		min-height: 60vh;

		&.ls-material-selector {
			min-height: 14vh;
		}

		@media (max-width: @paper-compact-threshold) {
			width: calc(100vw - 30px);
		}
	}
}


.homeworkContainer,
.presentationcontainer,
.elevindholdContainer {
	.lc-display-fragment.cke_wysiwyg_div {
		border: none;
		box-shadow: none;
	}

	.lc-display-fragment.cke_wysiwyg_div > * {
		margin-left: 0;
		margin-right: 0;
	}

	.lc-display-fragment.cke_wysiwyg_div > h1,
	.lc-display-fragment.cke_wysiwyg_div > h2,
	.lc-display-fragment .cke_wysiwyg_div > h3 {
		padding-left: 0;
		padding-right: 0;
		margin-left: 0;
		margin-right: 0;
	}
}

article.lc-display-fragment {
	padding: 0;
	@media @mobile{
		padding-left: 1.5rem;
	}
}

*[data-local-id=topnav] a > img {
	/* aht. tablets. */
	padding: 5px;
}

.lc-display-fullscreen {
	background-color: black;
	overflow: auto;
	-webkit-overflow-scrolling: touch;

	.lc-display-fragment {
		padding: inherit;
		/* Container, der aktuelt viser en slide. */
		&.lc-display-slidemode.lc-display-has-slide {
			background-color: black;
		}
		/* Viser een slide ad gangen i fuldskaermstilstand. Alle andre elementer skal skjules. */
		&.lc-display-slidemode > * {
			display: none;
		}

		&.lc-display-slidemode > section.lc-document-slide {
			/* Fjern nogle indstillinger fra ovenfor, der er forkerte i fuldskaermsvisning. */
			border: none;
			border-radius: 0;
			margin: 0 auto;
			max-height: none;
			transform-origin: top;
			-webkit-transform-origin: top;
		}
		/* Ikke-slide-indhold. */
		section:not(.lc-document-slide) {
			/* Skal have margin/padding i fuldskærm, hvor der ikke er omgivelser til at give det. */
			padding: 2em;
			background: white;
			margin-left: auto;
			margin-right: auto;
		}
	}

	*[data-local-id=topnav] {
		background-color: #eeeeee;
		/* Flg. for at få knapperne til at blive på skaermen.	*/
		position: fixed;
		width: 100%;
		font-size: 1.3rem;
	}

	&:-webkit-full-screen {
		height: 100%;
		width: 100%;
	}
}

/* page content container - target default device screen */
.ls-toccontent-container {
	width: @container-width;
	max-width: @container-max-width;
	display: table;
}

/**********************************************************/
.ls-texteditor-toolbarContainer {
	/*min-width: 940px;*/
	width: @container-width;
	max-width: @container-max-width;
	/*width: 100%;*/
	display: block;
	z-index: 5;
}

/****************************************************/
/* toc and content area container view and edit mode*/
.ls-texteditor-container {
	background: @solid_bg_canvas;
	min-height: 80vh;
	@media @mobile{
		.surface;
	}
}

/* content side */
.ls-texteditor-paper-container .ls-paper-header {
	.ls-paper;
	min-height: auto;
	width: @paper-width;
}

.ls-texteditor-paper-container, .ls-texteditor-container {
	& :focus {
		outline: none
	}

	.ls-paper {
		min-height: 50vh;
		width: @paper-width;
	}

	@media @mobileportrait {
		.ls-paper, .ls-paper-header {
			width: 95dvw;
		}
	}

	@media @mobilelandscape {
		.ls-paper, .ls-paper-header {
			width: calc(97dvw - @mobil-menu-width);
		}
	}
}

	.cke_source {
		height: 300px !important;
		width: 100% !important;
	}

	.hiddenButtons {
		position: fixed;
		top: -100px;
		height: 0px;
		margin: 0px;
		padding: 0px;
	}

	.hiddenButtons .button {
		height: 1px;
		width: 1px;
	}
	/* Beregnet til at vise indhold i hele vinduet. */
	body.lc-display-maxview {
		overflow: hidden;

		.lc-display-fullscreen {
			position: fixed;
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
		}
	}


	.image a:hover {
		background: transparent;
		opacity: 0.9;
	}
	/* embedede billeder */
	.cke_widget_wrapper {
		max-width: 100%;
	}
	/*For at brugeren kan se "drag-handler" på øverste slide*/
	.cke_widget_lectioslide {
		padding-top: 15px;
	}

	.lc-display-fragment img {
		max-width: 100%;
		height: auto !important;
	}

	.lc-display-fragment table {
		max-width: 100%;
	}
	// Fixes af formaterings-dropdown'en
	div.cke_combopanel {
		width: 220px;
	}

	.cke_button_label.cke_button__lectionote_label {
		display: inline;
	}

	.cke_button_label.cke_button__lectioispublic_label {
		display: inline;
	}

	.cke_button.cke_button__lectioispublic {

		&.cke_button_disabled .cke_button_icon.cke_button__lectioispublic_icon {
			background-image: url('/lectio/img/checkbox_disabled_unchecked.auto');
			background-position: 1px center;
		}

		&.cke_button_off .cke_button_icon.cke_button__lectioispublic_icon {
			background-image: url('/lectio/img/checkbox_unchecked.auto');
			background-position: 1px center;
		}

		&.cke_button_on {
			border-bottom: 1px solid transparent;
			border-right: 1px solid transparent;
			border-top: 1px solid transparent;
			border-left: 1px solid transparent;
			background-color: transparent;

			.cke_button_icon.cke_button__lectioispublic_icon {
				background-image: url('/lectio/img/checkbox_checked.auto');
				background-position: 1px center;
				background-repeat: no-repeat;
			}
		}
	}


	html.cke_panel_container {
		font-family: sans-serif;

		h1,
		h2,
		h3 {
			font-weight: 100;
			color: @brush_text_heading;
		}

		pre {
			/* safari */
			font-family: monospace;
			/* Blot en lille smule.. */
			padding-left: 1em;
		}
	}
	/*******************************************/
	/* Styling af skabelon for editorindhold   */
	/*******************************************/
	.lc-placeholder {

		p,
		h1,
		li {
			color: #999999;
		}

		a,
		input,
		label,
		li {
			opacity: 0.4;
		}

		p {
			font-style: italic;
		}

		*:after {
			content: attr(data-lc-display-placeholder-text);
		}
		/** ckeditor eller browseren saetter et br-element ind, der, i kombination med :after tilfoejer
			en ekstra linie til hoejden af elementer med (:after-)tekst. */
		& br:only-child {
			display: none;
		}
	}

	.lc-math-tex {
		font-size: 13pt;
		z-index: 1;
	}
	// Flg. behoeves af katex-redigeringsdialogen:
	// Den viser preview som html og ikke som billede, og ckeditors css-reset
	// saetter to ting der smadrer previewet: text-align: left, og font.
	// text-align forsoeges fjernet her.
	.cke_dialog_ui_vbox_child .lc-math-tex * {
		text-align: unset;
	}
	/************************/
	.ls-alertbox {
		border: 1px solid @brush_attention;
		border-radius: 0.4em;
		padding: 1em;
		color: @brush_attention;
		font-size: @font-size;
		line-height: @line-height;
	}

	.ls-alertbox-stacktraceline {
		white-space: pre;
		overflow: auto;
		color: #888;
		display: contents;
	}

	.ls-alertbox-stacktraceline strong {
		color: initial;
	}

	.ls-alertbox h1 {
		color: @brush_attention;
		font-weight: 100
	}

	.ls-alertbox ul {
		margin-left: 1.5em;
	}

	.lc-display-swup-button {
		display: inline-block;
	}
	/* lc preview / read mode: akt.forside,forløbsforside,forløbsmateriale, materialevælger,spørgeskema/quizzer */
	.ls-paper {
		border-radius: @radius_border_paper;
		box-shadow: @shadow_border_paper;
		background: @solid_bg_paper;
		border: @border_paper;
		box-sizing: border-box;
		/*padding: 1.8em;*/
		padding-top: @paper-text-top-indent;
		padding-bottom: @paper-text-bottom-indent;
		/* paper margins: left, right */
		padding-right: @paper-text-right-indent;
		padding-left: @paper-text-left-indent;
	}

.ls-phase-activity {
	.ls-paper;
	width: @paper-width;
	overflow: auto;

	@media @mobile {
		padding-left: 8px;
		padding-right: 8px;
		padding-top: 0.2rem;
		padding-bottom: 0;
	}

	@media @mobileportrait {
		width: 95dvw;
	}

	@media @mobilelandscape {
		width: calc(97dvw - @mobil-menu-width);
	}
}

	.ls-phase-activity-title {
		padding: 0 0 0.8em 0;
		@media @mobile{
			padding-left: 1.5rem;
		}
	}

	.ls-phase-activity-content {
	}
	/* print forløbs aktivitet(er) */
	#printcontainerDiv {
		.ls-phase-activity {
			padding-left: @paper-text-left-indent - 2rem;
			padding-top: 0;
			margin-top: 0;
			box-shadow: none;
			border: none;
			border-bottom: solid 6px @brush_border;
		}

		:not(.ls-phase-activity) {
			~ .ls-phase-activity-title {
				padding-left: @paper-text-left-indent - 2rem;
			}

			~ .ls-phase-activity-content {
				padding-left: @paper-text-left-indent - 2rem;
			}
		}
	}
	/* end print forløbs aktivitet(er) */

.ls-questionaire-base {
	/*
	.ls-paper;
	padding-right: 1.8rem;
	padding-left: 1.8rem;

	*/
	.ls-card-outlined;
}

.ls-questionnaire-question {
	.ls-questionaire-base;
	width: @paper-width;
	overflow: auto;
	word-wrap: break-word;

	@media @mobile {
		width: 90dvw;
		padding: 0;
		padding-left: 0.5em;
		padding-right: 0.5em;
		padding-bottom: 1em;
	}
}


.ls-questionnaire-question-edit {
	.ls-questionaire-base;
	min-width: @paper-width;
	overflow: auto;
}

.ls-paper-container {
	margin: 2rem;
	max-width:@paper-max-width;
}

.ls-tocContainer-outer {
		background: #f7f7f7;
		padding-top: 0.4em;
		vertical-align: top;
		max-width: 280px;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 280px;
		flex-shrink: 0;

		@media @mobileportrait {
			.ls-tocContainer-outer, .ls-tocContainer-inner {
				max-width: 70dvw;
			}
		}

		@media @mobilelandscape {
			.ls-tocContainer-outer, .ls-tocContainer-inner {
				max-width: 40dvw;
			}
		}
	}
	/* TOC state: open/visible */
	.ls-tocContainer-inner {
		padding-right: 1.2rem;
		padding-left: 0;
		padding-bottom: 2rem;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		width: 100%;
		position: -webkit-sticky;
		position: sticky;
		/*Giv plads ovenover til foldeknappen. */
		top: 1em;
		overflow-y: auto;
		overflow-x: hidden;
		height:97vh ;


		@media @mobile {
			height: unset;
			padding-bottom: unset;
		}
	}
	/* Texteditor  toolbar *************** */
	.ls-texteditor-toolbarOuterContainer {
		position: relative;
		/* height: 69px; */
		/*width: 1008px;*/
		box-sizing: border-box;
	}

	.ls-texteditor-toolbarMenuContainer {
		background: #f7f7f7;
		position: relative;
		height: 28px;
		width: 1008px;
		box-sizing: border-box;
	}

	.ls-toolbarMenuInnerContainer {
		pointer-events: auto;
		padding-left: 6px;
		padding-right: 6px;
		line-height: 28px;
		margin-right: 2px;
		background: #f7f7f7;
		border-radius: 4px;
	}
	/****** måske fjernes  slut ***************************************************************************************************************/

	.ls-materialchooserpreview .ls-tocContainer-outer {
		position: -webkit-sticky;
		position: sticky;
	}

	.ls-materialchooserpreview {
		background: #f7f7f7;
		display: table;
		width: 1008px;
	}



	.toolbar {
		z-index: 2;
		background: transparent;
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		/* soerg for at man kan klikke paa tekst/flueben/whatever, der ligger under denne bjaelke */
		pointer-events: none;
	}

	.toolbar > * {
		/* soerg for at man kan klikke paa knapper, der ligger i denne bjaelke */
		pointer-events: auto;
	}

	.ls-tocandcontentparent {
		display: flex;
		padding-bottom: 2rem;
	}



	.ls-tocContainer-inner h3, .ls-tocContainer-inner h3 .tooltip {
		color: #475968
	}

.toggleTocElementImg {
	.prepend-fonticon-arrow-left;
	.primary-text;
	opacity: 1;
	padding-left:0.25rem;
}

.toggleTocElementImg.toggleTocElementImgClosed {
	.prepend-fonticon-arrow-right;
	.primary-text;
	opacity: 1;
	
}

.toggleTocElementImg:hover {
	.prepend-fonticon-arrow-left;
	.primary-text;
	opacity: 0.92;
	cursor: pointer;
}

.toggleTocElementImg.toggleTocElementImgClosed:hover {
	.prepend-fonticon-arrow-right;
	.primary-text;
	opacity: 0.92;
	cursor: pointer;
}
	/*#Mobil*/
	@media @mobile {
		// skjul normal toc-toggle knap på mobil
		.toggleTocElementImg {
			display: none;
		}

		.ls-paper-container {
			margin: 0;
			margin-bottom: 12px;
			margin-top: 6px;
		}

		.ls-texteditor-paper-container {
			padding-bottom: 12px;
		}

		.ls-tabs3 {
			width: 100%;
			height: auto;
			min-width: 200px;

			.lectioTabContent {
				padding-left: 8px;
				padding-right: 8px;
				padding-top: 4px;
				height: auto;
				width: 100%;

				.ls-texteditor-toolbarContainer, .ls-tocandcontentparent {
					width: auto;
				}
			}

			.ls-paper {
				padding-left: 8px;
				padding-right: 8px;
				padding-top: 0.2rem;
				padding-bottom: 0;
				/**************/
				border:none;
				box-shadow:none;
				background:transparent;
				/*********/


				.ls-section-title-heading {
					padding-left: 2.35rem;
					padding-top: 8px;
				}

				.ls-paper-section-heading {
					margin-top: 8px;
					margin-bottom: 0.3rem;
					.title-medium;
				}

				.ls-paper-subsection-separator {
					margin-left: 0;
					margin-top: 20px;
					margin-bottom: 20px;
					margin-left: 4px;
					margin-right: 12px;
				}

				.ls-hr-solid {
					border-width: 1px;
					margin-top: 10px;
					margin-bottom: 10px;
					margin-left: 26px;
					margin-right: 12px;
				}

				.ls-hr-solid-thick {
					border-width: 2.5px;
					border-bottom-color: #c6cbcf;
				}

				.activity-note {
					margin-left: 0;
					padding-top: 4px;
					width: auto;
					margin-bottom: 0;
					padding-left: 1rem;
				}
			}

			.lc-display-fragment, textarea, input[type=text] {
				font-size: 1rem;
			}

			.lc-display-fragment {
				padding-right: 0;
				background:transparent;

				h1 {
					font-size: 1.5em;
					margin-top: 10px;
					padding-right: 12px;
				}
			}
		}
	}
	/*#endregion */

	.ls-paper-subsection-separator {
		margin-left: 0;
		margin-top: 20px;
		margin-bottom: 20px;
	}

	.ls-phase-activity .ls-table-minwidth {
		min-width: 50em;

		@media @mobile {
			min-width: 50dvw;

			TD {
				padding-right: 0;
			}
		}
	}

