.file-jquery-autocomplete-css {
	color: blue;
}
.ac_results {
	padding: 0;
	background-color: #ffffff;
	overflow: hidden;
	z-index: 50;
	max-height: 40ex;
	overflow-y: scroll;
	-ms-overflow-y: scroll;
	border: solid 1px #d3dfeb;
	-webkit-border-radius: 0.4em;
	-moz-border-radius: 0.4em;
	-o-border-radius: 0.4em;
	border-radius: 0.4em;
	-webkit-box-shadow: 0.2em 0.2em 1.6em rgba(0, 0, 0, 0.1);
	-moz-box-shadow: 0.2em 0.2em 1.6em rgba(0, 0, 0, 0.1);
	-o-box-shadow: 0.2em 0.2em 1.6em rgba(0, 0, 0, 0.1);
	box-shadow: 0.35em 0.35em 1.3em rgba(0, 0, 0, 0.5);
}

	.ac_results ul {
		width: 100%;
		list-style-position: outside;
		list-style: none;
		padding: 0;
		margin: 0;
		text-align: left;
	}

	.ac_results iframe {
		display: none;
		/*sorry for IE5*/
		display
		/**/
		: block;
		/*sorry for IE5*/
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		filter: mask();
		width: 3000px;
		height: 3000px;
	}

	.ac_results li {
		margin: 0em;
		margin-top: 0.1em;
		cursor: pointer;
		display: flex;
		width: 100%;
		overflow: hidden;
		font: inherit;
		/*border-bottom: dotted 1px #c4c9cc;*/
		border-bottom: dotted 1px #c1c7ce;
		padding-top: 0.4em;
		padding-bottom: 0.4em;
		padding-right: 0.4em;
		padding-left: 0.7em;
		background-size: 1.33em 1.33em;
		background-position: 0.1em 0.2em;
		transition: background-color 0.08s, color 0.08s;
		-moz-transition: background-color 0.08s, color 0.08s;
		/* Firefox 4 */
		-webkit-transition: background-color 0.08s, color 0.08s;
		/* Safari and Chrome */
		-o-transition: background-color 0.08s, color 0.08s;
		/* Opera */
	}



		.ac_results li[ac-command].ac_over,
		.ac_over, .ac_over.groupuser,
		.ac_results .ac_inactive[class~=ac_over] {
			/*background-color: #0076d6;*/
			background-color: #006493;
			color: #ffffff;
		}


		.ac_results li[ac-command='Separator'],
		.ac_results li[ac-command='Separator'].ac_over {
			background-color: #F7F9FA;
			color: #444444;
			font-weight: bold;
			border-bottom-style: solid;
			height: 0.2em;
			cursor: default;
		}

		.ac_results li[ac-command='ShowSupplementary'] {
			position: relative;
			top: -0.1em;
			margin: 0em;
			display: block;
			width: 100%;
			overflow: hidden;
			font: inherit;
			border-top: solid 1px #d3dfeb;
			border-left: solid 1px #d3dfeb;
			border-right: solid 1px #b8c3cc;
			border-bottom: solid 1px #b8c3cc;
			padding-top: 0.4em;
			padding-bottom: 0.4em;
			padding-left: 0.3em;
			padding-right: 0.3em;
			vertical-align: middle;
			padding-left: 1.85em;
			color: #1b5c9e;
			background-color: #d5e3f2;
			background-image: url(/lectio/img/gradlink.png);
			background-repeat: repeat-x;
			background-position: left top;
			background-size: auto;
		}

			.ac_results li[ac-command='ShowSupplementary'].ac_over {
				border-top: solid 1px #1f76ea;
				border-left: solid 1px #1f76ea;
				border-right: solid 1px #0e65ba;
				border-bottom: solid 1px #0e65ba;
				background-color: #0e65ba;
				background-image: url(/lectio/img/gradhover.png);
				background-repeat: repeat-x;
				background-position: left top;
			}

	.ac_results.ac_supplementary_hidden .ac_supplementary {
		display: none;
	}

	.ac_results .ac_inactive {
		color: rgb(150, 150, 150);
	}

	/* lokale optaget (lokaleliste Rediger prøvehold) */
	.ac_results .ddoccupied {
		color: #a31515;
	}

		.ac_results .ddoccupied[class~=ac_over] {
			background-color: #a31515;
			color: #ffffff;
		}

	.ac_results .ddChosen,
	.ac_results .ddChosen[class~=ac_over] {
		background-color: rgb(220, 220, 220);
		color: #444444;
		cursor: default;
	}

	.ac_reset_btn {
		right: 0em;
		height: 100%;
		margin-right: 0.5em;
		margin-left: -2em;
	}

	.ac_reset_btn > div.buttonlink > a:link {
		padding: 0;
	}

	span.ac_has_delete_btn > INPUT[Type="Text"] {
		padding-right: 2em;
	}