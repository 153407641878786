

@import (reference) "constants.less";


div.grid-skolehjem-dagsoverblik {
	display: grid;
	grid-row-gap: 20px;
	min-height: 300px;
}

div.grid-skolehjem-dagsoverblik table.skolehjemoverblik {
	margin-top: 10px;
}

div.grid-skolehjem-dagsoverblik table.skolehjemoverblik > tbody {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	gap: 10px 15px;
}

div.grid-skolehjem-dagsoverblik table.skolehjemoverblik > tbody > tr.skolehjemoverblik-værelse {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
	outline: 1px solid #f0f0f3;
	padding: 2px 3px;
	min-width: 110px;
}

div.grid-skolehjem-dagsoverblik table.skolehjemoverblik > tbody > tr.skolehjemoverblik-værelse > td.skolehjemoverblik-værelse-navn {
}

div.grid-skolehjem-dagsoverblik table.skolehjemoverblik > tbody > tr.skolehjemoverblik-værelse > td.skolehjemoverblik-værelse-pladser {
	margin-left: auto;
	height: 32px;
}

div.grid-skolehjem-dagsoverblik > div {
	margin: 10px 0 5px 0;
}

div.grid-skolehjem-dagsoverblik > div > span {
	font-size: 2em;
}

div.skolehjemoverblik-plads[data-plads-status="ledig"],
div.skolehjemoverblik-farvekode[data-plads-status="ledig"] {
	background-color: @color-ledig;
}

div.skolehjemoverblik-plads[data-plads-status="optaget-mixed"],
div.skolehjemoverblik-farvekode[data-plads-status="optaget-mixed"] {
	background-color: @color_lectio_Purple;
}
div.skolehjemoverblik-plads[data-plads-status="optaget-dreng"],
div.skolehjemoverblik-farvekode[data-plads-status="optaget-dreng"] {
	background-color: @color_BlueWeakStronger;
}
div.skolehjemoverblik-plads[data-plads-status="optaget-pige"],
div.skolehjemoverblik-farvekode[data-plads-status="optaget-pige"] {
	background-color: @color_lectio_Pink;
}

div.skolehjemoverblik-plads[data-plads-status="lukket"],
div.skolehjemoverblik-farvekode[data-plads-status="lukket"] {
	background-color: @color_lectio_Orange;
}

div.skolehjemoverblik-plads[data-plads-status="spærret"],
div.skolehjemoverblik-farvekode[data-plads-status="spærret"] {
	background-color: @color_lectio_Yellow;
}

div.skolehjemoverblik-plads[data-plads-status="overlap"],
div.skolehjemoverblik-farvekode[data-plads-status="overlap"] {
	background-color: @color_lectio_Red;
	;
}

/*// TODO: Fjern efter beslutning om farve
div.skolehjemoverblik-plads[data-plads-status="spærret-mixed"],
div.skolehjemoverblik-farvekode[data-plads-status="spærret-mixed"] {
	background-image: linear-gradient(to bottom, @color_lectio_Yellow, @color_lectio_Yellow, @color_BlueWeakStronger, @color_BlueWeakStronger, @color_lectio_Pink, @color_lectio_Pink);
}

div.skolehjemoverblik-plads[data-plads-status="spærret-dreng"],
div.skolehjemoverblik-farvekode[data-plads-status="spærret-dreng"] {
	background-image: linear-gradient(to bottom, @color_lectio_Yellow, @color_lectio_Yellow, @color_lectio_Yellow, @color_BlueWeakStronger, @color_BlueWeakStronger, @color_BlueWeakStronger);
}

div.skolehjemoverblik-plads[data-plads-status="spærret-pige"],
div.skolehjemoverblik-farvekode[data-plads-status="spærret-pige"] {
	background-image: linear-gradient(to bottom, @color_lectio_Yellow, @color_lectio_Yellow, @color_lectio_Yellow, @color_lectio_Pink, @color_lectio_Pink, @color_lectio_Pink);
}*/


div.skolehjemoverblik-plads.highlight {
	box-shadow: 0px 0px 1px 1px black;
}

div.skolehjemoverblik-plads {
	width: 17px;
	height: 25px;
	border-radius: 10px;
	margin: 3px;
	display: inline-flex;
	align-items: center;
	border: 1px solid @color_lectio_DarkGray;
	vertical-align: middle;
}

div.skolehjemoverblik-farvekode-eksempler {
	padding-top: 20px;
}

div.skolehjemoverblik-farvekode,
div.skolehjemoverblik-iconkode {
	width: 15px;
	height: 15px;
	line-height: 15px;
	border-radius: 0px;
	margin: 3px;
	display: inline-block;
	vertical-align: middle;
}

div.skolehjemoverblik-farvekode {
	border: 1px solid @color_lectio_DarkGray;
}

div.skolehjemoverblik-iconkode {
	border: 1px solid transparent;
}

div.skolehjemoverblik-farvekode,
div.skolehjemoverblik-farvekode-beskrivelse,
div.skolehjemoverblik-iconkode,
div.skolehjemoverblik-iconkode-beskrivelse {
	vertical-align: middle;
	display: inline-block;
}

div.skolehjem-holdtilknytning-tilskudregel-grid {
	margin-top: 10px;
	display: grid;
	grid-template-areas: "Name Value Button";
	align-items: center;
	justify-content: space-between;
	grid-auto-columns: 135px 1fr 0.5fr;
	justify-items: start;
	grid-column-gap: 10px;

	div.tilskudregel-name {
		grid-area: Name;
		justify-self: start;
	}

	div.tilskudregel-value {
		grid-area: Value;
		white-space: pre-wrap;
		justify-self: center;
	}

	div.tilskudregel-button {
		grid-area: Button;
		justify-self: end;
	}
}

.skolehjem-booking-tilskudregel-grid {
	display: grid;
	grid-template-areas: "Name Value";
	align-items: center;
	justify-content: space-between;
	grid-auto-columns: 135px 1fr;
	justify-items: start;
	grid-column-gap: 10px;
	padding: 3px;

	div.tilskudregel-name {
		grid-area: Name;
		justify-self: start;
	}

	div.tilskudregel-value {
		grid-area: Value;
		white-space: pre-wrap;
		justify-self: center;
	}
}
 




/* END */
/************************************************* */
