.file-studypresentationcategories-less {
	color: blue;
}
/************************************************ */
/* Studieretningspræsentation oversigt/kategorier */
/************************************************ */
#krop .forsideTabel {
	color: #ffffff;
	font-family: Verdana, Arial, Helvetica, sans-serif;
	font-size: 1em;
	width: 36.5em;
	border-collapse: collapse;
}

#krop .forsideTabel TD {
	height: 13.5em;
	text-align: center;
	padding: 0.8em 0.8em 1.4em 0.8em;
}

#krop A.studietilbud,
A.studietilbud B,
A.studietilbud I {
	text-decoration: none;
	color: #ffffff;
}

#krop A.studietilbud:hover {
	background-color: #0e65ba;
}

#krop A.studietilbud I {
	text-decoration: none;
	color: #f5f5f5;
}

#krop .forsideHead {
	color: #000000;
	text-align: center;
	font-size: 1.2em;
	font-weight: bold;
	margin: 0.4em;
}



/******************************/

.forsideCell1,
.forsideCell2,
.forsideCell3,
.forsideCell4,
.forsideCell5,
.forsideCell6,
.forsideCell7,
.forsideCell8,
.forsideCell9,
.forsideCell10,
.forsideCell11,
.forsideCell12,
.forsideCell13,
.forsideCell14,
.forsideCell15,
.forsideCell16,
.forsideCell17,
.forsideCell20,
.forsideCell21 {
	border: solid 1px white
}

/*******************************************************************************************************/
/*BLÅGRÅ**********************************************************************************************/
/*HF, IB, EUD10 */
#krop .forsideCell,
#krop .forsideCell20,
#krop .forsideCell21 {
	background-color: #98c0c8;
}

/*******************************************************************************************************/
/*stx naturvidenskab grøn ******************************************************************************/
#krop .forsideCell1,
.forsideCell11 {
	background-color: #6C9E20;
}

/*HHX Internationalt orienteret */
#krop .forsideCell7 {
	/*background-color: #948A54; grønbrun */
	.forsideCell11;
}

/*******************************************************************************************************/
/* BLÅ **********************************************************************************************/
/*samfundfundsvidenskab*/
#krop .forsideCell2,
.forsideCell12 {
	background-color: #1184D6;
}

.forsideCell13 {
	/*background-color:#24d009;*/
	.forsideCell12;
}

/* HHX Økonomi og sprog */
#krop .forsideCell9,
.forsideCell19 {
	/*background-color: #3AB0F2; lyseblå*/
	.forsideCell12;
}

/* HTX Matematisk/It-orienteret */
#krop .forsideCell10 {
	/*	background-color: #66FFB6; neon-grøn*/
	.forsideCell12;
}

/*******************************************************************************************************/
/* RØD **********************************************************************************************/
/*sprog*/
#krop .forsideCell3,
.forsideCell14 {
	background-color: #E95118;
}

/*******************************************************************************************************/
/* ORANGE **********************************************************************************************/
/* STX kunst */
#krop .forsideCell4,
.forsideCell15 {
	background-color: #FCA307;
}

/*HTX teknologisk*/
#krop .forsideCell6,
.forsideCell16 {
	/*background-color:#F08406; orange*/
	.forsideCell15;
}

/*******************************************************************************************************/
/* LILLA *********************************************************************************************/
/* HHX Økonomi og marked */
#krop .forsideCell8,
.forsideCell18 {
	background-color: #8064B2;
}

/* HTX Kommunikationsteknisk */
#krop .forsideCell5,
.forsideCell17 {
	/*background-color: #FF66FF; magenta*/
	.forsideCell18;
}

/*******************************************************************************************************/
