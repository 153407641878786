.file-beskeder-less { color: blue; }
@import "utilities.less";
@import (reference) "framing.less";

.message-thread-container {
	width: auto;
	overflow: hidden;
	.ls-card-filled;
}

.message-thread-message {
	margin-bottom: 1.5rem;
	box-sizing: border-box;
	.ls-card-outlined;
}

.message-thread-message.viewed-persons-message {
	.secondary-container;
}

.message-reply-summary.viewed-persons-message {
	.secondary-container;
}

.message-container {
}
.message {
	word-break: break-word;
	hyphens: auto;
	display:block;
}
.message-menu {
	.buttonicon a {
		padding: 0;
	}
}
.message-thread-message-sender {
	.label-small;
	padding: 0 0.5rem;
}
.message-replysum-header-menu {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: start;



}

.message-thread-message-header {
	.label-medium;
	font-weight: 600;
}
.message-reply-summary {
	.body-small;
	opacity: 0.8;
	font-size: 0.9rem;
	.ls-card-filled;
	margin-bottom: 0.25rem;
}

.message-thread-message-content {
	.body-small;
}

.grayed-out {
	font-style: italic;
	color: @color_lectio_DarkGray;
	cursor: default;
	padding-top: 2px;
}

/*bruges denne?*/
.thread-title {
	.title-medium;
}

.flex-row-div {
	display: flex;
}

.thread-header-container {
	.body-small;
	display: flex;
	justify-content: space-between;
}
.thread-header-content {
	flex-wrap: wrap;
}

.md-fill {
	font-variation-settings: 'FILL' 1;
}

.md-unfill {
	font-variation-settings: 'FILL' 0;
}

.lpm-trunc {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}


.thread-header-buttons {
	.ls-std-rowblock;
	align-items: flex-start;
}

.message-thread-message-header .buttonicon{
	vertical-align: middle;
}

.message-buttons-options-container {
	display: inline-flex;
	flex-direction: row;
	justify-content: space-between;
	margin: 0.2em 0;
	flex-wrap: wrap;
	width: 100%;
	align-items: center;

	.message-options {
		display: inline-block;
		max-width: 50em;

		.message-reply-notify-dropdown {
			margin: 0.5rem 0rem 0.5rem 0.5rem;
		}
	}

	.message-buttons {
		.ls-std-rowblock-top;
		gap: 10px;
		justify-content: flex-end
	}
}

@media @mobile {
	.ls-documentchooser-container-foldercontent-col-header {

		h1 {
			.title-medium;
			.on-surface-text;
			display: inline-flex;
		}
		img {
			display: none
		}
	}

	//trådoversigts liste
	.ls-documentchooser-container,
	.documentchooser-foldercontent-col {
		min-width: unset;
		padding: 0;

		.ls-table-layout5 tr td {
			border-color: @--md-ref-palette-neutral95;
		}
	}


	tr.unread .message-list-thread-container .message-list-thread-from,
	tr.unread .message-list-thread-container .message-list-thread-datetime,
	tr.unread .message-list-thread-container a {
		font-weight: 700 !important;
	}

	.message-list-thread-container {
		width: 100%;
		display: grid;
		grid-template-areas: 'a b b' 'c d d';
		grid-template-columns: 1fr auto auto;
		padding: 0.5rem 0 0 0;
		margin: 0;


		.message-list-thread-from {
			grid-area: a;
			.label-large;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		// bottom-left
		.message-list-thread-subject {
			grid-area: c;
			.label-small;
			overflow: hidden;
			text-overflow: ellipsis;
			padding-left: 21px;

			div {
				width: 100%;
				overflow: hidden;

				a {
					color: unset;
					padding: unset;
					overflow: hidden;
					text-overflow: ellipsis;
					display:block;
				}
			}
		}

		.message-list-thread-timeex {
			display: flex;
			grid-area: b;

			.message-list-thread-datetime {
				display: inline;
				.body-small;
				font-size: 0.8rem;
				color: @--md-ref-palette-neutral70;
				margin-right: 0.5rem;
			}
		}

		.message-list-thread-menu {
			grid-area: d;
			text-align: right;
		}
	}

		.message-thread-message-header {
			margin-bottom: 0.5rem;
		}
		// HACK: pga. style i buttons.less:1033
		.ls-std-rowblock {
			.message-options {
				white-space: unset;
			}
		}


	.message-buttons-options-container {
		row-gap: 10px;

		.message-options {
			display: flex;
			//max-width: 12em;
			justify-content: space-between;
			white-space: unset;
			.label-small;
			font-size: 13px;
			flex-grow: 1;
			align-items:center;

			input[type="text"] {
				margin-bottom: 0.5rem;
				margin-left: unset;
			}

			div.buttonoutlined {
				margin: unset;
			}

			.message-reply-notify-dropdown {
				max-width: 12em;
				text-overflow: ellipsis;
				margin-top: 0.5rem;

				option {
					max-width: 12em;
				}
			}
		}

		.message-buttons {
			//width: 100%;
			.label-small;
			font-size: 13px;
			flex-grow: 1;
		}
	}
}

