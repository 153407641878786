.file-mobileskema-less {
	color: red; /*  marker, not meant to be used. */
}

@import (reference) "constants.less";

.mobilskema-reset-link-tags {
	A, A[href], .ClientButton, A.tooltip, A SPAN.tooltip, A[href]:hover,
	.ClientButton:hover,
	A.tooltip:hover,
	A:hover SPAN.tooltip,
	A[href].alert:hover,
	A[href].attention:hover
	{
		background-color: unset;
		color: unset;
		text-decoration: unset;
		cursor: unset;
	}
}

.mobilskema-reset-skemabrik {
	.reset {
		background-color: unset;
		background-image: none;
		background-repeat: unset;
		background-position: unset unset;
		border: unset unset unset;
		border-left-color: unset;
		border-left-width: unset;
		border-right-width: unset;
		display: unset;
		text-align: unset;
		height: unset;
		color: unset;
		margin-left: unset;
		margin-right: unset;
		margin-top: unset;
		margin-bottom: unset;
		padding-top: unset;
		padding-bottom: unset;
		padding-left: unset;
		padding-right: unset;
	}
}




.mobilskema-header-container div, .mobilskema-1dag-outerContainer div {
	box-sizing: border-box;
}


.mobilskema-header-container {
	.mobilskema-reset-link-tags;
	.mobilskema-reset-skemabrik;
	width: 100dvw;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;


	.mobilskema-1dag-header {
		div {
			box-sizing: border-box;
		}

		.surface;
		display: flex;
		flex-flow: row nowrap;
		width: fit-content;

		.week {
			display: flex;
			flex-flow: row nowrap;
			width: 100vw;
			text-align: center;
			border-bottom: solid 1px @--md-sys-color-outline-variant;
			scroll-snap-align: center;
			scroll-snap-stop: always;


			header {
				min-width: 100%;
			}

			.weekDay {
				box-sizing: border-box;
				min-width: calc(100%/8);
				.label-small;
				.on-surface-variant-text;
				margin-bottom: 0.6rem;
				display: block;
				align-items: center;
				display: flex;
				flex-direction: column;


				div:first-of-type {
					font-size: 0.7rem;
					display: block;
					color: @--md-ref-palette-neutral70;
					height: 1.2rem;
					width: 2rem;
					transition: color 0.4s ease-out;
				}

				div:last-of-type {
					font-size: 0.9rem;
					display: block;
					border-radius: 50%;
					height: 2rem;
					width: 2rem;
					padding: 0.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: transparent;
					transition: background-color 0.4s ease-out;

					span {
						line-height: 1;
						height: 1rem;
						margin-left: 0.1rem;
					}
				}
			}

			.weekNum {
				padding-left: 0.2rem;

				div:first-of-type {
					color: @--md-ref-palette-neutral70;
				}

				div:last-of-type {
					.primary-text;
					color: @--md-ref-palette-neutral70;
				}
			}

			.selected-day {

				div:first-of-type {
					.primary-text;
					transition: color 0.35s ease-in;
				}

				div:last-of-type {
					.primary;
					.on-primary-text;
					transition: background-color 0.35s ease-in;
				}
			}

			.weekname {
				font-size: 0.5rem;
			}
		}
	}
}

.mobilskema-1dag-outerContainer {
	.mobilskema-reset-link-tags;
	.mobilskema-reset-skemabrik;
	width: 100dvw;
	overflow-x: scroll;
	overflow-y: hidden;
	scroll-snap-type: x mandatory;


	.mobilskema-1dag-container {
		display: flex;
		flex-flow: row nowrap;
		width: fit-content;

		.mobilskema-1dag {
			width: 100vw;
			overflow: hidden;
			scroll-snap-align: center;
			scroll-snap-stop: always;


			.mobilskema-1dag-skematop {
				display: flex;
				flex-flow: row nowrap;
				border: none;
				outline: none;
				height: 72px;
				overflow: hidden;
				margin-bottom: 1.5rem;
				.surface-container;
				.on-surface-text;
				background: @--md-ref-palette-custom498;
				border-bottom: solid 1px @--md-ref-palette-neutral80;
				will-change: transform;
				transform-origin: top;
				transition: height 0.3s cubic-bezier(0.77, 0.2, 0.05, 1.0);
				box-sizing: border-box;

				.mobilskema-1dag-dag {
					.label-small;
					width: 16%;
					padding: 0.6rem 0 0 0.65rem;

					span {
						text-align: center;
						display: block;
						width: 50%;
					}
				}



				#mobilskema-1dag-dagsbm {
					.body-small;
					max-width: 72%;
					padding-right: 0.5rem;
					padding-top: 0.25rem;
					padding-bottom: 0.25rem;


					a:not(.dagsbm-expand-btn) {
						.surface;
						min-width: 35%;
						display: inline-block;
						border-left: solid 2px @--md-ref-palette-neutral80;
						margin: 0.0rem 0.2rem;
						padding: 0.1rem 0.3rem;
						border-radius: 0.15rem;
					}

					a[href].s2normal {
						.skemabrik-normal();
						min-width: 35%;
					}

					a[href].s2optional {
						.skemabrik-optional();
						min-width: 35%;
					}

					a[href].s2changed {
						.skemabrik-changed();
						min-width: 35%;
					}

					a[href].s2cancelled {
						.skemabrik-cancelled();
					}

					a[href].s2bgboxeksamen {
						.skemabrik-exam();
					}

					.dagsbm-expand-btn {
						position: absolute;
						right: 1rem;
						top: 1.2rem;

						.ls-fonticon {
							border-radius: 50%;
							.outline;
							height: 1.5rem;
							width: 1.5rem;
							line-height: 1.5rem;
						}
					}
				}
			}
		}


		.mobilskema-content {
			display: inline-flex;
			width: 100%;
			padding: 0.4rem 0.8rem 0.4rem 0;
			box-sizing: border-box;



			.mobilskema-1dag-tidslinje {
				display: inline-flex;
				height: 100%;
				width: 20%;

				.mobilskema-1dag-tidslinje-modulebox {
					position: relative;
					height: 100%;
					width: 30%;
					margin: 0 1% 0 0;


					.mobilskema-tidslinje-modulebox-row {
						position: absolute;
						width: 100%;
						height: 100%;
						border-radius: 0 0.25rem 0.25rem 0;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 12px;
						font-weight: bold;
						.on-surface-text;
						.surface-container;
						.body-small;
					}
				}

				.mobilskema-1dag-tidslinje-timeslots {
					position: relative;
					height: 100%;
					width: 70%;
					white-space: initial;
					margin: 0 9% 0 0;


					.mobilskema-tidslinje-timeslot-row {
						padding-left: 2px;
						line-height: normal;
						width: 100%;
						position: absolute;
						white-space: initial;
						display: flex;
						justify-content: space-between;
						flex-direction: column;
						align-items: center;


						.start-time {
							font-weight: normal;
							position: relative;
							top: -(3% + 6px);
						}

						.separator {
							border-left: 1px solid #ccc;
							height: 100%;
							position: relative;
							top: -3%;
						}

						.end-time {
							opacity: 0.45;
							font-size: 0.65rem;
							position: relative;
							top: -3%;
						}
					}
				}
			}

			.mobilskema-module-og-brik-content {
				position: relative;
				width: 88%;

				.mobilskema-module {
					display: flex;
					flex: 1 1 auto;
				}

				.mobilskema-module-content-row {
					position: absolute;
					width: 100%;

					.hline {
						position: relative;
						top: -3%;
						border-top: 1px solid @--md-sys-color-outline-variant;
					}
				}

				.s2skemabrik {
					width: 100%;
					height: 100%;
					z-index: 10;
					white-space: initial;
					/*background-position: left top;*/
					border-radius: 0.15rem;
					padding: 0.2rem;
					box-sizing: border-box;

					&.s2normal {
						.skemabrik-normal();
					}

					&.s2optional {
						.skemabrik-optional();
					}

					&.s2changed {
						.skemabrik-changed();
					}

					&.s2cancelled {
						.skemabrik-cancelled();
					}

					&.s2bgboxeksamen {
						.skemabrik-exam();
					}
				}
			}

			.imgDisplay {
				display: inline-flex;
				position: absolute;
				top: 0px;
				right: 0px;
			}
		}
	}
}



/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.mobilskema-header-container .mobilskema-1dag-outerContainer .mobilskema-1dag {
	-ms-overflow-style: none; /* Internet Explorer and Edge */
	scrollbar-width: none; /* Firefox */
}
/* Hide the scrollbar for Chrome, Safari and Opera */
.mobilskema-1dag-outerContainer::-webkit-scrollbar {
	display: none;
}
/* Hide the scrollbar for Chrome, Safari and Opera */
.mobilskema-header-container::-webkit-scrollbar {
	display: none;
}

.file-mobileskema-less-end {
	color: blue; /*  marker, not meant to be used. */
}
