@import "constants.less";

.file-fonticons-less {
	color: blue;
}
/* Icons *******************************************************************************************/
@font-face {
	font-family: ls-font-icons;
	src: url(/lectio/fonts/MaterialSymbolsOutlined-VariableFont_FILL,GRAD,opsz,wght.ttf);
}

.ls-fonticon {
	font-family: ls-font-icons;
	font-style: normal;
	font-weight: 250;
	font-optical-sizing: auto;
	font-size: 1.5rem;
	line-height: inherit;
	letter-spacing: normal;
	text-transform: none;
	display: inline-block;
	white-space: nowrap;
	word-wrap: normal;
	direction: ltr;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	font-feature-settings: "liga";
	
}

/***********************************************/
.prepend-fonticon {
	display: flex;
	align-items: center;

	&::before {
		.ls-fonticon;
		align-self: flex-start;
		content: "";
		padding-right: 0.1em;
	}

	&:active::before, &:focus::before {
		opacity: 0.88;
	}

	&:hover::before {
		opacity: 0.92;
	}

	&[disabled="disabled"]::before {
		opacity: 0.62;
	}

	&[disabled="disabled"]:hover::before {
		opacity: 0.62;
	}

	&.large::before {
		font-size: 2rem;
	}
}

.prepend-fonticon-filled {
	.prepend-fonticon;

	&::before {
		font-variation-settings: 'FILL' 1;
	}
}



/* entititeter ***********************************************/
/*.ac_results .xx er for at holde klassenavne små når de bliver brugt i LectioDropDowns */
.prepend-fonticon-student,
.ac_results .fs {
	.prepend-fonticon-filled;

	&::before {
		content: "person";
		color: @color_student;
	}
}

.prepend-fonticon-teacher,
.ac_results .ft {
	.prepend-fonticon-filled;

	&::before {
		content: "person";
		color: @color_teacher;
	}
}

.prepend-fonticon-hold,
.ac_results .fh {
	.prepend-fonticon-filled;

	&::before {
		content: "group";
		color: @color_hold;
	}
}

.prepend-fonticon-holdelement,
.ac_results .fhe {
	.prepend-fonticon-filled;

	&::before {
		content: "group";
		color: @color_hold;
	}
}

.prepend-fonticon-stamclass,
.ac_results .fsc {
	.prepend-fonticon-filled;

	&::before {
		content: "group";
		color: @color_stamclass;
	}
}

.prepend-fonticon-room,
.ac_results .fr {
	.prepend-fonticon-filled;
	// "cottage"
	&::before {
		content: "meeting_room";
		color: @color_room;
	}
}

.prepend-fonticon-resource {
	.prepend-fonticon-filled;

	&::before {
		content: "work";
		color: @color_resource;
	}
}

.prepend-fonticon-studentforloeb,
.ac_results .fsf {
	.prepend-fonticon-filled;

	&::before {
		content: "group";
		color: @color_studentforloeb;
	}
}

.prepend-fonticon-uddannelsesforloeb {
	.prepend-fonticon-filled;

	&::before {
		/*	content: "event_upcoming";*/
		content: "school";
		color: @color_uddannelseforloeb;
	}
}

.prepend-fonticon-group {
	.prepend-fonticon-filled;

	&::before {
		content: "group";
		color: @color_group;
	}
}

.prepend-fonticon-bullit {
	.prepend-fonticon-filled;

	&::before {
		content: "fiber_manual_record";
		font-size: 0.9rem;
		.primary-text;
	}
}

.prepend-fonticon-bullit-prio1 {
	.prepend-fonticon-filled;

	&::before {
		padding-right: 0.3em;
		content: "fiber_manual_record";
		font-size: 0.9rem;
		color: @color-bullit-prio1;
	}
}

.prepend-fonticon-bullit-prio2 {
	.prepend-fonticon-filled;

	&::before {
		padding-right: 0.3em;
		content: "fiber_manual_record";
		font-size: 0.9rem;
		color: @color-bullit-prio2;
	}
}

.prepend-fonticon-bullit-prio3 {
	.prepend-fonticon-filled;

	&::before {
		padding-right: 0.3em;
		content: "fiber_manual_record";
		font-size: 0.9rem;
		color: @color-bullit-prio3;
	}
}


.prepend-fonticon-document {
	.prepend-fonticon;

	&::before {
		content: "description";
	}
}

.prepend-fonticon-add {
	.prepend-fonticon;

	&::before {
		content: "add";
		/*color: @color-add;*/
		font-weight: 400;
	}
}

.prepend-fonticon-edit {
	.prepend-fonticon-filled;

	&::before {
		content: "edit";
	}
}

.prepend-fonticon-delete {
	.prepend-fonticon;

	&::before {
		content: "delete";
	}
}

.prepend-fonticon-preview {
	.prepend-fonticon;

	&::before {
		content: "preview";
	}
}
.prepend-fonticon-mail {
	.prepend-fonticon;

	&::before {
		content: "mail";
	}
}

.prepend-fonticon-mail-read {
	.prepend-fonticon;

	&::before {
		content: "mark_email_read";
	}
}

.prepend-fonticon-help {
	.prepend-fonticon;

	&::before {
		content: "help";
	}
}

.prepend-fonticon-search {
	.prepend-fonticon;

	&::before {
		content: "search";
	}
}

.prepend-fonticon-content-copy {
	.prepend-fonticon;

	&::before {
		content: "content_copy";
	}
}

.prepend-fonticon-arrow-up {
	.prepend-fonticon;

	&::before {
		content: "keyboard_arrow_up";
	}
}

.prepend-fonticon-arrow-down {
	.prepend-fonticon;

	&::before {
		content: "keyboard_arrow_down";
	}
}

.prepend-fonticon-arrow-right {
	.prepend-fonticon;

	&::before {
		content: "keyboard_arrow_right";
	}
}

.prepend-fonticon-arrow-left {
	.prepend-fonticon;

	&::before {
		content: "keyboard_arrow_left";
	}
}
.prepend-fonticon-arrow-back {
	.prepend-fonticon;

	&::before {
		content: "arrow_back";
	}
}

.prepend-fonticon-arrow-forward {
	.prepend-fonticon;

	&::before {
		content: "arrow_forward";
	}
}
.prepend-fonticon-arrow-upward {
	.prepend-fonticon;

	&::before {
		content: "arrow_upward";
	}
}
.prepend-fonticon-arrow-downward {
	.prepend-fonticon;

	&::before {
		content: "arrow_downward";
	}
}


.prepend-fonticon-logout {
	.prepend-fonticon;

	&::before {
		content: "logout";
	}
}

.prepend-fonticon-studiekort {
	.prepend-fonticon;

	&::before {
		content: "badge";
	}
}

.prepend-fonticon-phone {
	.prepend-fonticon;

	&::before {
		content: "phone_iphone";
	}
}


.prepend-fonticon-qr-code {
	.prepend-fonticon;

	&::before {
		content: "qr_code_2";
	}
}


.prepend-fonticon-ok {
	.prepend-fonticon;

	&::before {
		content: "check";
		color: @color-ok;
		font-weight:350;
	}
}


.prepend-fonticon-checkbox-checked {
	.prepend-fonticon;

	&::before {
		content: "check_box";
	}
}

.prepend-fonticon-checkbox-unchecked {
	.prepend-fonticon;

	&::before {
		content: "check_box_outline_blank";
	}
}

.prepend-fonticon-link {
	.prepend-fonticon;

	&::before {
		content: "open_in_new";
	}
}

.prepend-fonticon-forside {
	.prepend-fonticon;

	&::before {
		content: "home";
	}
}
.prepend-fonticon-dokumenter {
	.prepend-fonticon;

	&::before {
		content: "description";
	}
}
.prepend-fonticon-beskeder {
	.prepend-fonticon;

	&::before {
		content: "mail";
	}
}
.prepend-fonticon-studieplan {
	.prepend-fonticon;

	&::before {
		content: "list_alt";
	}
}
.prepend-fonticon-fravaer {
	.prepend-fonticon;

	&::before {
		content: "event_available";
	}
}

.prepend-fonticon-opgaver{
	.prepend-fonticon;

	&::before {
		content: "folder_open";
	}
}
.prepend-fonticon-lektier {
	.prepend-fonticon;

	&::before {
		content: "bookmark";
	}
}
.prepend-fonticon-karakterer {
	.prepend-fonticon;

	&::before {
		content: "star";
	}
}
.prepend-fonticon-spoergeskema {
	.prepend-fonticon;

	&::before {
		content: "checklist";
	}
}
.prepend-fonticon-indstillinger {
	.prepend-fonticon;

	&::before {
		content: "settings";
	}
}
.prepend-fonticon-login {
	.prepend-fonticon;

	&::before {
		content: "login";
	}
}
.prepend-fonticon-hovedmenu {
	.prepend-fonticon;

	&::before {
		content: "lan";
	}
}


/* subnavigator links **************************************/
.prepend-fonticon-boeger {
	.prepend-fonticon;

	&::before {
		content: "import_contacts";
	}
}
.prepend-fonticon-aarsopgoerelse {
	.prepend-fonticon;

	&::before {
		content: "overview";
	}
}
.prepend-fonticon-tidsregistrering {
	.prepend-fonticon;

	&::before {
		content: "timer";
	}
}
.prepend-fonticon-fravaersangivelse {
	.prepend-fonticon;

	&::before {
		content: "event_available";
	}
}

.prepend-fonticon-stamdata {
	.prepend-fonticon;

	&::before {
		content: "data_table";
	}
}

/* end subnavigator links **************************************/

.prepend-fonticon-checkmark {
	.prepend-fonticon;

	&::before {
		content: "check_circle";
	}
}

.prepend-fonticon-cancel{
	.prepend-fonticon;

	&::before {
		content: "cancel";
	}
}

.prepend-fonticon-expand_more {
	.prepend-fonticon;

	&::before {
		content: "expand_more";
	}
}
.prepend-fonticon-expand_less {
	.prepend-fonticon;

	&::before {
		content: "expand_less";
	}
}

.prepend-fonticon-idag {
	.prepend-fonticon;

	&::before {
		content: "today";
	}
}
.prepend-fonticon-activity {
	.prepend-fonticon;

	&::before {
		content: "event";
	}

	@media @mobileportrait {
		&::before {
			display: none
		}
	}
}

	.prepend-fonticon-ugeskema {
		.prepend-fonticon;

		&::before {
			content: "date_range";
		}
	}

	.prepend-fonticon-note {
		.prepend-fonticon;

		&::before {
			content: "sms";
		}
	}

	.prepend-fonticon-note-smallcolor {
		.prepend-fonticon;

		&::before {
			font-weight: 300;
			font-variation-settings: 'FILL' 1;
			font-size: 1rem;
			content: "sms";
			color: @--md-ref-palette-custom495;
			-webkit-text-stroke-width: 0.05rem;
			-webkit-text-stroke-color: @color_lectio_Black;
		}
	}

	.prepend-fonticon-privataftale {
		.prepend-fonticon;

		&::before {
			content: "lock";
		}
	}

	.prepend-fonticon-historik {
		.prepend-fonticon;

		&::before {
			content: "history";
		}
	}

	.prepend-fonticon-dice {
		.prepend-fonticon;

		&::before {
			content: "casino";
		}
	}

	.prepend-fonticon-qrscan {
		.prepend-fonticon;

		&::before {
			content: "qr_code_scanner";
		}
	}

	.prepend-fonticon-reply {
		.prepend-fonticon;

		&::before {
			content: "reply";
		}
	}

	.prepend-fonticon-annuller {
		.prepend-fonticon;

		&::before {
			content: "cancel";
		}
	}
	/**********************************/



	.prepend-fonticon-flag {
		.prepend-fonticon;

		&::before {
			content: "flag";
		}
	}

	.prepend-fonticon-burgermenu {
		.prepend-fonticon;

		&::before {
			content: "menu";
		}
	}

	.prepend-fonticon-moremenu-horizontal {
		.prepend-fonticon;

		&::before {
			content: "more_horiz";
		}
	}

	.prepend-fonticon-moremenu-vertical {
		.prepend-fonticon;

		&::before {
			content: "more_vert";
		}
	}

	.prepend-fonticon-close {
		.prepend-fonticon;

		&::before {
			content: "close";
		}
	}

	.prepend-fonticon-clock {
		.prepend-fonticon;

		&::before {
			content: "schedule";
		}
	}


	.prepend-fonticon-attachment {
		.prepend-fonticon;

		&::before {
			content: "attach_file";
		}
	}

	.prepend-fonticon-print {
		.prepend-fonticon;

		&::before {
			content: "print";
		}
	}

	.prepend-fonticon-preview {
		.prepend-fonticon;

		&::before {
			content: "document_scanner";
		}
	}

	.prepend-fonticon-profile {
		.prepend-fonticon;

		&::before {
			content: "account_circle";
		}
	}

	.prepend-fonticon-radio-checked {
		.prepend-fonticon;

		&::before {
			content: "radio_button_checked";
		}
	}

	.prepend-fonticon-radio-unchecked {
		.prepend-fonticon;

		&::before {
			content: "radio_button_unchecked";
		}
	}



	.prepend-fonticon-download {
		.prepend-fonticon;

		&::before {
			content: "download";
		}
	}

	.prepend-fonticon-upload {
		.prepend-fonticon;

		&::before {
			content: "upload";
		}
	}

	.prepend-fonticon-swap-vert {
		.prepend-fonticon;

		&::before {
			content: "swap_vert";
		}
	}

	.prepend-fonticon-swap-horiz {
		.prepend-fonticon;

		&::before {
			content: "swap_horiz";
		}
	}

	.prepend-fonticon-expand {
		.prepend-fonticon;

		&::before {
			content: "expand";
		}
	}

	.prepend-fonticon-compress {
		.prepend-fonticon;

		&::before {
			content: "compress";
		}
	}


	.prepend-fonticon-undo {
		.prepend-fonticon;

		&::before {
			content: "undo";
		}
	}

	.prepend-fonticon-input {
		.prepend-fonticon;

		&::before {
			content: "input";
		}
	}

	.prepend-fonticon-output {
		.prepend-fonticon;

		&::before {
			content: "output";
		}
	}

	.prepend-fonticon-filter {
		.prepend-fonticon;

		&::before {
			content: "tune";
		}
	}



	.prepend-fonticon-adjust {
		.prepend-fonticon;

		&::before {
			content: "arrow_range";
		}
	}


	.prepend-fonticon-calendar {
		.prepend-fonticon;

		&::before {
			content: "calendar_month";
		}
	}

	.prepend-fonticon-skema {
		.prepend-fonticon;

		&::before {
			content: "calendar_month";
		}
	}


	.prepend-fonticon-event {
		.prepend-fonticon;

		&::before {
			content: "event";
		}
	}

	.prepend-fonticon-refresh {
		.prepend-fonticon;

		&::before {
			content: "refresh";
		}
	}

	.prepend-fonticon-presentation {
		.prepend-fonticon;

		&::before {
			content: "jamboard_kiosk";
		}
	}

	.prepend-fonticon-feedback {
		.prepend-fonticon;

		&::before {
			content: "contact_support";
		}
	}

	.prepend-fonticon-draw {
		.prepend-fonticon;

		&::before {
			content: "draw";
		}
	}

	.prepend-fonticon-folder {
		.prepend-fonticon;

		&::before {
			content: "folder";
		}
	}

	.prepend-fonticon-satisfied {
		.prepend-fonticon;

		&::before {
			content: "sentiment_satisfied";
			font-size: 2.5rem;
			color: @color-correct;
		}
	}

	.prepend-fonticon-neutral {
		.prepend-fonticon;

		&::before {
			content: "sentiment_neutral";
			font-size: 2.5rem;
			color: @color-partly-correct;
		}
	}

	.prepend-fonticon-dissatisfied {
		.prepend-fonticon;

		&::before {
			content: "sentiment_dissatisfied";
			font-size: 2.5rem;
			color: @color-not-correct;
		}
	}



	.prepend-fonticon-move {
		.prepend-fonticon;

		&::before {
			content: "move_item";
		}
	}

	.prepend-fonticon-remove {
		.prepend-fonticon-filled;

		&::before {
			content: "remove";
			font-weight: 350;
		}
	}

	.prepend-fonticon-play {
		.prepend-fonticon;

		&::before {
			content: "play_circle";
		}
	}

	.prepend-fonticon-fullscreen {
		.prepend-fonticon;

		&::before {
			content: "open_in_full";
		}
	}

	.prepend-fonticon-fullscreen-close {
		.prepend-fonticon;

		&::before {
			content: "close_fullscreen";
		}
	}



	.prepend-fonticon-right-panel-close {
		.prepend-fonticon;

		&::before {
			/*content: "close";*/
			content: "view_sidebar";
		}

		&::after {
			/*content: "Luk panel";*/
			content: "Sidepanel";
		}
	}

	.prepend-fonticon-right-panel-open {
		.prepend-fonticon;

		&::before {
			content: "view_sidebar";
		}

		&::after {
			/*content: "Vis panel";*/
			content: "Sidepanel";
		}
	}

	.prepend-fonticon-male {
		.prepend-fonticon;

		&::before {
			content: "male";
		}
	}

	.prepend-fonticon-female {
		.prepend-fonticon;

		&::before {
			content: "female";
		}
	}

	.prepend-fonticon-person {
		.prepend-fonticon;

		&::before {
			content: "person";
		}
	}

	.prepend-fonticon-camera {
		.prepend-fonticon-filled;

		&::before {
			content: "photo_camera";
		}
	}

	.prepend-fonticon-default {
		.prepend-fonticon;

		&::before {
			content: "label";
		}
	}

	.prepend-fonticon-warning {
		.prepend-fonticon;

		&::before {
			content: "warning";
			color: @brush_attention;
		}
	}

	.prepend-fonticon-info {
		.prepend-fonticon;

		&::before {
			content: "info";
		}
	}

	.prepend-fonticon-test {
		.prepend-fonticon;

		&::before {
			content: "bug_report";
		}
	}

	.prepend-fonticon-hourglass {
		.prepend-fonticon;

		&::before {
			content: "hourglass";
			font-size: 1.2rem;
			color: @color_lectio_LightGray3;
		}
	}
	.prepend-fonticon-barcode-reader {
		.prepend-fonticon;

		&::before {
			content: "barcode_reader";
		}
	}
	/* bruges nok ikke skal nok slettes ?*/
	/*************************************************************************/
	.file-fonticons-less-end {
		color: blue;
	}
