.file-buttons-less {
	color: blue;
}

@import (reference) "constants.less";


/********************************************************************/
/** HYPERLINK *******************************************************/
/********************************************************************/
a:focus {
	.ls-focus-base-style;
}

A[href],
.ClientButton,
A.tooltip,
A SPAN.tooltip {
	color: @brush_link;
	text-decoration: none;
	cursor: pointer;

	img {
		vertical-align: middle;
	}
}

a.buttonlink {
	.primary-text;
	text-decoration: none;
	cursor: pointer;

	&:hover {
		/*opacity: 0.92;*/
		opacity: 0.82;
	}
}

A[href]:hover,
.ClientButton:hover,
A[href].alert:hover,
A[href].attention:hover {
	/*background-color: @brush_linkBackgroundHover;
	color: @brush_linkHover;*/
	/*.surface-container*/
	text-decoration: none;
	cursor: pointer;
	/*opacity: 0.92;*/
	opacity: 0.82;
}

label[disabled] {
	color: @brush_disabled;
}

a[disabled],
a[disabled]:hover {
	color: @brush_disabled;
	background: transparent;
	cursor: default;
	text-shadow: 1px 1px #ffffff;
	transition: none;
	/*opacity: 0.62; MD*/
	img {
		filter: grayscale(100%) opacity(0.5);
	}
}

/********************************************************************/
/*  START Button (standard buttons for INPUT TAGs )******************/
/********************************************************************/
.ls-button-layout {
	padding-top: 0.27em;
	padding-bottom: 0.27em;
	line-height: 1.3rem;
	padding-left: 0.75em;
	padding-right: 0.75em;
	vertical-align: middle;
	box-sizing: border-box;
}

.ls-button-behavior {
	cursor: pointer;
	display: inline-block;
	white-space: nowrap;
}

/* Button theme start ********************************************************************/
.ls-button-face {
	background-color: @brush_buttonBackground;
	background-image: @gradient_buttonBackground;
	background-position: left top;
	background-repeat: repeat-x;
	border-top: 1px solid @brush_buttonHighlight;
	border-left: 1px solid @brush_buttonHighlight;
	border-right: 1px solid @brush_buttonShadow;
	border-bottom: 1px solid @brush_buttonShadow;
	color: @brush_link;
	border-radius: @brush_border_radius;
}

.ls-button-face-hover {
	background-color: @brush_buttonBackgroundHover;
	background-image: @gradient_buttonBackgroundHover;
	background-position: left top;
	background-repeat: repeat-x;
	border-top: 1px solid @brush_buttonHighlightHover;
	border-left: 1px solid @brush_buttonHighlightHover;
	border-right: 1px solid @brush_buttonShadowHover;
	border-bottom: 1px solid @brush_buttonShadowHover;
	color: @brush_linkHover;
}


/* End of button theme ********************************************************************/
.ls-button {
	.ls-button-layout;
	.ls-button-face;
	.ls-button-behavior;
}

.ls-button-hover {
	.ls-button;
	.ls-button-face-hover;
	transition: all 0.1s linear;
}

.ls-button-disabled {
	color: @brush_disabled !important;
	text-shadow: 1px 1px #ffffff;
}

.ls-button-disabled-hover {
	.ls-button;
	.ls-button-disabled;
	cursor: default;
	transition: none;
}

/******************************************************************/
/*  START Button (standard buttons )******************/
/******************************************************************/
INPUT.button,
BUTTON.button {
	.ls-button;
}

INPUT.buttonHover,
BUTTON.buttonHover,
INPUT.button:hover,
BUTTON.button:hover {
	.ls-button-hover;
}

INPUT.button[disabled="disabled"],
INPUT.button[disabled="true"],
BUTTON.button[disabled="true"],
BUTTON.button[disabled="disabled"] {
	.ls-button-disabled;
}

DIV.button,
SPAN.button,
DIV.buttonlink,
DIV.buttonicon {
	display: inline-block;
	/*min-height: 1.4em;*/
}

DIV.button A,
A.button,
SPAN.button A {
	.ls-button;
}

DIV.button A:hover,
A.button:hover,
SPAN.button A:hover,
DIV.buttonSelected A {
	.ls-button-hover;
}

DIV.button A[disabled="disabled"],
SPAN.button A[disabled="disabled"],
DIV.button A[disabled="disabled"]:hover,
A.button[disabled="disabled"]:hover,
SPAN.button A[disabled="disabled"]:hover,
.buttonhover A[disabled="disabled"],
DIV.button A.aspNetDisabled,
DIV.button A.aspNetDisabled:hover,
.buttonhover A.aspNetDisabled,
.buttonhover A.aspNetDisabled:hover {
	.ls-button-disabled;
	.ls-button-disabled-hover;
}



/* spec. Back-button-icon fix*/
div.button a[data-rolevariant=back],
DIV.button A[data-rolevariant=back]:hover {
	padding-left: 0
}

DIV.button A IMG,
DIV.buttontoggleUP A IMG,
SPAN.button A IMG,
DIV.buttonlink A IMG,
DIV.buttonicon A IMG {
	vertical-align: middle;
	position: relative;
	/*top: -1px;*/
	margin-right: 0.15em;
	margin-left: 0.15em;
	/*height: 1em;*/
}

DIV.buttonicon A IMG {
	margin-right: 0;
}

DIV.buttonicon:hover A {
	background-color: transparent;
	opacity: 0.8;
}

DIV.buttonicon:hover A[disabled] {
	background-color: transparent;
	opacity: 1;
}

/* End Button **********************************/
/* End Subnavigator buttons **********************************/
/**** BUTTONHEADER ********/
.buttonHeader A,
INPUT.buttonHeader,
BUTTON.buttonHeader {
	.ls-button-layout;
	.ls-button-face;
	display: block;
	margin-bottom: 0.1em;
	white-space: nowrap;
	text-align: left;
}

.buttonHeader A:hover,
INPUT.buttonHeader:hover,
BUTTON.buttonHeader:hover {
	.ls-button-layout;
	.ls-button-face-hover;
	display: block;
	margin-bottom: 0.1em;
	white-space: nowrap;
	text-align: left;
}


/********************************************************** */
/* TOOLBAR ************************************************ */
/********************************************************** */
.lectioToolbar {
	//overflow: hidden;
	padding: 0em;
	background-image: @gradient_buttonBackground;
	background-position: left top;
	background-repeat: repeat-x;
	border-bottom: solid 1px @brush_border;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.lectioToolbar .button A,
.lectioToolbar .buttonicon A,
.lectioToolbar .tidsreg-wrapper,
.lectioToolbar .login-wrapper {
	display: inline-block;
	border-left: none;
	border-top: none;
	border-bottom: none;
}

.lectioToolbar .button A:hover {
	display: inline-block;
	border-left: none;
	border-top: none;
	border-bottom: none;
}

.lectioToolbar .button A[disabled="disabled"],
.lectioToolbar .button A[disabled="disabled"]:hover {
	cursor: default;
	border-top: none;
	border-bottom: none;
}

.lectiotoolbar IMG {
	background-color: Transparent;
	vertical-align: middle;
}

.lectioToolbar .button .ls-fonticon {
	font-weight: 300;
	font-size: 1.3rem;
	vertical-align: bottom;
}

.lectioToolbar .tidsreg-wrapper {
	display: inline-flex;

	:first-of-type a {
		padding-right: 0.6em;
		border-right: none;
	}

	.vertical-seperator {
		height: 10px;
		align-self: center;
		border-right: 1px solid @brush_buttonShadow;
	}

	a.navbar-middle {
		padding-left: 0.6em;
		align-self: center;
	}
}

.lectioToolbar .login-wrapper {
	display: inline-flex;

	> :first-of-type a {
		padding-right: 0.6em;
		border-right: none;
	}

	.vertical-seperator {
		height: 10px;
		align-self: center;
		border-right: 1px solid @brush_buttonShadow;
	}

	a.navbar-middle {
		padding-left: 0.6em;
		align-self: center;
	}

	.buttonicon A {
		.ls-button;
		padding-left: 0;
		padding-right: 0;
		border-left: none;
		border-top: none;
		border-bottom: none;
	}
}


/*fuldskærm top toolbar e.g. præsentationer*/
.ls-toolbar-fullscreen-face {
	background: #f5f5f5;
	border: 1px solid @color_lectio_GrayLightBlue1;
	background: linear-gradient(#ffffff, #f5f5f5);
}

/* END TOOLBAR ******************************************* */
img[onclick] {
	cursor: pointer
}

/* ************************************************** */
/*       Knapper - Bredde                             */
/* ************************************************** */
.smallButton {
	width: 2.75em !important;
}

.mediumButton {
	width: 5.5em !important;
}

.largeButton {
	width: 8.2em !important;
}

.xlargeButton {
	width: 10.9em !important;
}

.buttonImage:hover,
.buttonImage {
	cursor: pointer;
}





/**** LectioButtonGroup ***********************************/
div.lec-context-menu > * {
	display: block;
}
/* Labels for autogenererede genvejstaster. */
.ls-nav-motion {
	position: absolute;
	background: white;
	margin-top: 1.2em;
	margin-left: 0.2em;
	padding: 0.2em;
	color: white;
	background-color: navy;
	min-width: 1.4em;
	// Mindsk sandsynligheden for at de kommer til at staa oven i hinanden, fx naar der er en knap under
	// en tabel med knapper eller lignende.
	line-height: 1.8ex;
}

.buttonicon > .ls-nav-motion,
.buttonlink > .ls-nav-motion {
	// Vil ikke rykke ned, naar det er de groenne plusser o.l. vi knyttes til.
	margin-top: initial;
	// z-index fordi de groenne plusser har position:relative af en eller anden grund.
	// og derfor ender de oven paa vores labels.
	z-index: 1;
}

// Fanger baade brikker o.l. over dage, og absolut positionerede brikker i skemaet.
// Fungerer vist OK for begge
.s2skemabrikcontainer .ls-nav-motion {
	margin-top: initial;
	margin-left: -1.7em;
}

.s2skemabrikcontainer:not(.s2infoHeader) .ls-nav-motion {
	z-index: 5;
}

.ls-nav-motion[ls-nav-label-hint='a-in-td'] {
	margin-top: 0.8em;
}

/* link paa "stamdata - adminstration" fx., hvor der ikke er meget vertikal afstand.
 * Der viser vi labels til venstre for links.
 */

li > .ls-nav-motion:first-child,
li > .buttonlink:first-child > .ls-nav-motion {
	margin-left: -2em;
	margin-top: initial;
}

table.ls-help-table td {
	vertical-align: top;
	padding: .3em;
}


/**********************/
// FAB (floating action button) - md-small
.ls-fab {
	z-index: 100;
	.primary-container;
	.primary-container-text;
	border-radius: 1rem;
	position: fixed;
	.ls-elevation-level3;
	cursor: pointer;
	bottom: 1.33rem; /* position from the bottom of the screen */
	right: 1.33rem; /* position from the right of the screen */
	text-align: center;
	width: 3.33rem;
	height: 3.33rem;
	box-sizing: content-box;
	line-height: 3.33rem;
	opacity: 1;


	&:hover {
		.primary-container;
		.primary-container-text;
		.ls-elevation-level4;
		opacity: 0.92;
	}

	display: inline-flex;
	justify-content: center;

	.ls-fonticon {
		font-size: 2rem;
		line-height: 3.33rem;
	}

	@media @mobileportrait {
		bottom: calc(@mobil-menu-height + 16px); /* position from the bottom of the screen */
	}
}
	/* start new button-styles*/
	@ls-button-padding: 0.5rem 1rem;
	@ls-buttonlink-padding: 0.5rem 0.2rem;
	@ls-buttonlink-toolbar-padding: 0.5rem 0.75rem;

	.ls-button-base {
		display: inline-block;
		margin: 0 0.05em 0 0.05em;

		a:link, a:active, a:focus, a:visited {
			.ls-elevation-level0;
			.label-medium;
			border: none;
			white-space: nowrap;
			box-sizing: border-box;
			border-radius: 1.125rem;
			opacity: 1;
			padding: @ls-button-padding;
			text-shadow: none;
			font-weight: 500;
		}

		a:active, a:focus {
			opacity: 0.88;
		}

		&:hover a, a:hover {
			.ls-elevation-level0;
			.label-medium;
			border: none;
			white-space: nowrap;
			box-sizing: border-box;
			border-radius: 1.125rem;
			padding: @ls-button-padding;
			opacity: 0.92;
			cursor: pointer;
			font-weight: 500;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			.ls-elevation-level0;
			.label-medium;
			border: none;
			white-space: nowrap;
			box-sizing: border-box;
			border-radius: 1.125rem;
			padding: @ls-button-padding;
			opacity: 0.62;
			cursor: default;
			text-shadow: none;
			font-weight: 500;
		}
	}

	.ls-button-icon-base {
		display: inline-block;
		margin: 0 0.05em 0 0.05em;

		a:link, a:active, a:focus, a:visited {
			.ls-elevation-level0;
			.label-medium;
			white-space: nowrap;
			box-sizing: content-box;
			border-radius: 50%;
			height: 1.5rem;
			width: 1.5rem;
			line-height: 1.5rem;
			text-align: center;
			padding: 0.2em;
			opacity: 1;
			display: inline-block;
			text-shadow: none;
			font-weight: 500;
		}

		a:active, a:focus {
			opacity: 0.88;
		}

		&:hover a, a:hover {
			.ls-elevation-level0;
			.label-medium;
			white-space: nowrap;
			box-sizing: content-box;
			border-radius: 50%;
			height: 1.5rem;
			width: 1.5rem;
			line-height: 1.5rem;
			text-align: center;
			padding: 0.2em;
			display: inline-block;
			text-shadow: none;
			font-weight: 500;
			opacity: 0.92;
			cursor: pointer;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			.ls-elevation-level0;
			.label-medium;
			white-space: nowrap;
			box-sizing: content-box;
			border-radius: 50%;
			height: 1.5rem;
			width: 1.5rem;
			line-height: 1.5rem;
			text-align: center;
			padding: 0.2em;
			display: inline-block;
			text-shadow: none;
			font-weight: 500;
			opacity: 0.62;
			cursor: default;
		}
	}


	.ls-button-elevated {
		.ls-button-base;

		a:link, a:active, a:focus, a:visited {
			.surface-container-low;
			.primary-text;
			.ls-elevation-level1;
		}

		&:hover a, a:hover {
			.surface-container-low;
			.primary-text;
			.ls-elevation-level2;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			.surface-variant;
			.on-surface-variant-text;
			.ls-elevation-level0;
		}
	}

	.ls-button-filled {
		.ls-button-base;

		a:link, a:active, a:focus, a:visited {
			.primary;
			.on-primary-text;
			border: solid 1px @--md-sys-color-primary;
		}

		&:hover a, a:hover {
			.primary;
			.on-primary-text;
			border: solid 1px @--md-sys-color-primary;
			.ls-elevation-level1;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			border: solid 1px @--md-sys-color-surface-variant;
			.surface-variant;
			.on-surface-variant-text;
			.ls-elevation-level0;
		}
	}



	.ls-button-outlined {
		.ls-button-base;

		a:link, a:visited {
			background-color: transparent;
			.outline-variant;
			.primary-text;
		}

		a:active {
			background-color: transparent;
			.outline-variant;
			.primary-text;
		}

		a:focus {
			background-color: transparent;
			.primary-text;
			border: solid 1px @--md-sys-color-primary;
		}

		&:hover a, a:hover {
			background-color: transparent;
			.outline-variant;
			.primary-text;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			background-color: transparent;
			.outline-variant;
			.on-surface-variant-text;
		}
	}

	.ls-button-filled-tonal {
		.ls-button-base;

		a:link, a:visited {
			.secondary-container;
			.secondary-container-text;
			border: solid 1px @--md-sys-color-secondary-container;
		}

		a:active, a:focus {
			.secondary-container;
			.secondary-container-text;
			border: solid 1px @--md-sys-color-secondary-container;
			opacity: 0.88;
		}

		&:hover a, a:hover {
			.secondary-container;
			.secondary-container-text;
			border: solid 1px @--md-sys-color-secondary-container;
			.ls-elevation-level1;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			border: solid 1px @--md-sys-color-surface-variant;
			.surface-variant;
			.on-surface-variant-text;
			.ls-elevation-level0;
		}
	}

	.ls-button-text {
		a:link, a:visited {
			.primary-text;
			padding: @ls-buttonlink-padding;
			opacity: 1;
		}

		a:active, a:focus {
			.primary-text;
			padding: @ls-buttonlink-padding;
			opacity: 0.88;
		}


		&:hover a, a:hover {
			.primary-text;
			/*opacity: 0.92;*/
			opacity: 0.82;
			padding: @ls-buttonlink-padding;
			background-color: transparent;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			.on-surface-text;
			padding: @ls-buttonlink-padding;
			opacity: 0.62;
		}
	}

	.ls-button-icon-outlined {
		.ls-button-icon-base;

		a:link, a:visited {
			background-color: transparent;
			.outline-variant;
			.on-surface-variant-text;
			.outline;
		}

		a:active, a:focus {
			background-color: transparent;
			.outline-variant;
			.on-surface-text;
		}



		&:hover a, a:hover {
			background-color: transparent;
			.outline;
			.on-surface-variant-text;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			background-color: transparent;
			.outline-variant;
			.on-surface-variant-text;
			.on-surface-text;
			opacity: 0.62;
		}
	}

	.ls-button-icon-filled-tonal {
		.ls-button-icon-base;

		a:link, a:visited {
			.secondary-container;
			.secondary-container-text;
			border: solid 1px @--md-sys-color-secondary-container;
		}

		a:active, a:focus {
			.secondary-container;
			/*.secondary-container-text;*/
			.on-surface-variant-text;
			border: solid 1px @--md-sys-color-secondary-container;
		}

		&:hover a, a:hover {
			.secondary-container;
			/*.secondary-container-text;*/
			.on-surface-variant-text;
			border: solid 1px @--md-sys-color-secondary-container;
			.ls-elevation-level1;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			border: solid 1px @--md-sys-color-surface-variant;
			.surface-variant;
			.on-surface-variant-text;
			.ls-elevation-level0;
		}
	}

	.ls-button-icon-filled {
		.ls-button-icon-base;

		a:link, a:visited {
			.primary;
			.on-primary-text;
			border: solid 1px @--md-sys-color-primary;
		}

		a:active, a:focus {
			.primary;
			.on-primary-text;
			border: solid 1px @--md-sys-color-primary;
		}

		&:hover a, a:hover {
			.primary;
			.on-primary-text;
			border: solid 1px @--md-sys-color-primary;
			.ls-elevation-level1;
		}

		a[disabled="disabled"], a[disabled="disabled"]:hover {
			border: solid 1px @--md-sys-color-surface-variant;
			.surface-variant;
			.on-surface-variant-text;
			.ls-elevation-level0;
		}
	}

	.ls-button-icon-standard {
		.ls-button-icon-base;

		a, a[href], a:visited {
			.on-surface-variant-text;
		}

		a:active, a:focus {
			.primary-text;
			outline-color: transparent;
		}

		&:hover a, a:hover, &:hover a span, a:hover span, a:hover span:hover {
			.primary-text;
			opacity: 0.92
		}

		&:hover a[disabled="disabled"], a[disabled="disabled"]:hover, &:hover a[disabled="disabled"] span, a[disabled="disabled"]:hover span, a[disabled="disabled"]:hover span:hover,
		a[disabled="disabled"], a[disabled="disabled"]:hover {
			.on-surface-text;
			opacity: 0.62
		}
	}
	/* end button styles ************/
	/* Chips  */
	.ls-chip {
		margin: 0.1rem;
		display: inline-block;

		a, a:hover {
			.ls-elevation-level0;
			.surface-container-low;
			.outline-variant;
			height: 1.5rem;
			box-sizing: content-box;
			white-space: nowrap;
			line-height: 1.5rem;
			.on-surface-text;
			border-radius: 0.66rem;
			text-align: center;
			vertical-align: middle;
			padding: 0.2rem 0.66rem;
			min-width: 2rem;
		}
	}
	/*** Context menu ******************************************/
	.lec-context-menu {
		max-height: 65vh;
		overflow-y: auto;
		min-width: 7rem;
		box-sizing: border-box;
		display: none;
		list-style: none;
		text-align: left;
		z-index: 10051 !important;
		.surface;
		.ls-card-elevated;
		padding: 0.4rem 0; /*override card default padding*/
		.ls-elevation-level3;

		ul > li[data-role=separator] > div {
			border-top: 0.1em solid @--md-ref-palette-neutral90;
			margin-left: 1em;
			margin-right: 1em;
			margin-top: 0.5em;
			margin-bottom: 0.5em;
		}

		ul > li {
			display: block;
			color: @brush_link;
			vertical-align: middle;
			padding-left: 0 !important;
			padding-top: 0;
			padding-right: 0;
			padding-bottom: 0;

			img {
				vertical-align: middle;
				margin-right: 0.2rem;
			}

			& > div {
				display: block;
			}

			a, a[href] {
				padding: 0.4rem 0.8rem 0.4rem 0.8rem;
				display: flex;
				align-items: center;
				width: calc(100% - 1.6em);
			}

			&:hover a {
				color: @brush_linkHover;
				background-color: @brush_linkBackgroundHover;

				&[disabled] {
					padding: 0.4rem 0.8rem 0.4rem 0.8rem;
					background-color: transparent;
				}
			}
		}
		/* Fix til context-menu uden ul list layout *********/
		& > .buttonlink {
			a, a[href] {
				padding: 0.4rem 0.8rem 0.4rem 0.8rem;
				display: flex;
				align-items: center;
				width: calc(100% - 1.6em);
			}

			&:hover a {
				color: @brush_linkHover;
				background-color: @brush_linkBackgroundHover;

				&[disabled] {
					padding: 0.4rem 0.8rem 0.4rem 0.8rem;
					background-color: transparent;
				}
			}
		}
		/* end fix*********/
	}
	/* start Subnavigator buttons, standard button override **********************************/
	.ls-subnav1 {
		border-bottom: solid 1px #d3dae0;
	}

	.ls-subnav2 {
		margin-bottom: 0.5em;
	}

	.ls-subnav1,
	.ls-subnav2 {
		white-space: normal;

		.buttonlink {
			display: inline-block;
			padding: 0.6em;

			a:link, a:visited {
				padding: 0.3em 0.5em;
			}
		}

		.buttonlink.ls-subnav-active a,
		.buttonlink.ls-subnav-active a:hover {
			text-shadow: none;
			border-bottom: solid 3px #B20C00;
			color: #b20c00;
			background-color: transparent;
			cursor: default;
			opacity: 1;
		}
	}
	/*******************************/
	/****************/
	.reset-button {
		padding: unset;
		line-height: unset;
		background: unset;
		border: unset;
		border-radius: unset;
		cursor: unset;
		color: unset;
		text-decoration: unset;
		margin: unset;
		position: unset;
		top: unset;
		height: unset;
		overflow-clip-margin: unset;
		overflow: unset;
		transition: unset;
		border: none;
		outline: none;
		min-height: unset;
	}

	.reset-button-all {
		.reset-button;

		&:visited, &:visited a, &:focus, &:focus a, &:active, &:active a, &:hover, &:hover a {
			.reset-button;
		}

		a, a:hover, a[disabled], a[disabled]:hover, a.aspNetDisabled, a.aspNetDisabled:hover {
			.reset-button;

			&:focus, &:focus a, &:active, &:active a, &:hover, &:hover a {
				/*.reset-button;*/
			}
		}
	}
	/* TEXT (Link) */
	div.buttonlink {
		.ls-button-text;
	}
	/* Filled */
	button.buttonfilled, div.buttonfilled {
		.ls-button-filled;
	}
	/* FilledTonal */
	button.buttonfilledtonal, div.buttonfilledtonal {
		.ls-button-filled-tonal;
	}
	/* Outlined */
	button.buttonoutlined, div.buttonoutlined {
		.ls-button-outlined;
	}

	button.buttoniconoutlined, div.buttoniconoutlined {
		.ls-button-icon-outlined;
	}

	button.buttoniconfilledtonal, div.buttoniconfilledtonal {
		.ls-button-icon-filled-tonal;
	}

	button.buttoniconfilled, div.buttoniconfilled {
		.ls-button-icon-filled;
	}
	/* Icon standard*/
	div.buttonicon, button.buttonicon {
		/*.ls-button-icon-standard;*/
		&:hover a {
			.on-surface-variant-text;
			opacity: 0.92;
		}

		&:hover a[disabled] {
			.on-surface-text;
			opacity: 0.62;
		}
	}
	/* Segmented buttons ********************************************/
	.ls-segmented-buttonsgroup {
		.ls-elevation-level0;
		white-space: nowrap;
		box-sizing: border-box;
		display: flex;

		.ls-button-segment {

			a {
				border-radius: 0;
				.outline;
				border-collapse: collapse;
				display: inline-block;
				padding: @ls-button-padding;
				.on-surface-text;
				border-right-width: 0;
			}

			a:link, a:visited {
				background-color: transparent;
			}

			a:active {
				background-color: transparent;
			}

			a:focus {
				background-color: transparent;
				.secondary-container;
				outline: none;
				.secondary-container-text;
			}

			&:hover a, a:hover {
				background-color: transparent;
				.secondary-container;
				.secondary-container-text;
				.prepend-fonticon-ok;
				padding: 0.5rem 0.5rem 0.5rem 0.25rem;

				&:before {
					padding-left: 0.15rem;
					font-size: 1rem;
					.secondary-container-text;
				}
			}

			a[disabled="disabled"], a[disabled="disabled"]:hover {
				background-color: transparent;
				.on-surface-variant-text;
			}
		}

		.ls-button-segment:first-of-type > a {
			border-top-left-radius: 1.125rem;
			border-bottom-left-radius: 1.125rem;
			.secondary-container;
			.secondary-container-text;
			.prepend-fonticon-ok;

			&:before {
				font-size: 1rem;
				.secondary-container-text;
			}
		}

		.ls-button-segment:last-of-type > a {
			border-top-right-radius: 1.125rem;
			border-bottom-right-radius: 1.125rem;
			border-right-width: 1px;
		}
	}
	/****************************************************************/
	/* MobileHeaderbuttons styling **********************************/
	.mobile-header-buttons {
		li {
			div {
				width: 100%;
			}
		}
	}


	@media @mobile {
		/* Links mobile ***************************************************/
		a[href],
		.ClientButton,
		A.tooltip,
		A SPAN.tooltip {
			.primary-text;
			text-decoration: none;
			cursor: pointer;
		}

		A[href]:hover,
		.ClientButton:hover,
		A.tooltip:hover,
		A:hover SPAN.tooltip,
		A[href].alert:hover,
		A[href].attention:hover {
			.primary-text;
			opacity: 0.92;
			background: unset;
			text-decoration: none;
			cursor: pointer;
		}


		label[disabled] {
			.on-surface-text;
		}

		a[disabled],
		a[disabled]:hover {
			.on-surface-text;
			background: unset;
			cursor: default;
			text-shadow: unset;
			transition: none;

			img {
				filter: grayscale(100%) opacity(0.5);
			}
		}
		/* Buttons mobile ***************************************************/


		button.button, div.button, a.button, span.button {
			.reset-button-all;
			.ls-button-filled-tonal;
		}

		div.buttonlink, button.buttonlink {
			.reset-button-all;
			.ls-button-text;
		}

		div.buttonicon, button.buttonicon {
			.ls-button-icon-standard;
		}

		.lectioToolbar {
			overflow: hidden;
			padding: 0;
			background: unset;
			border-bottom: solid 1px @brush_border;
		}

		.lectioToolbar .button {
			.reset-button;
			.ls-button-text;
		}

		.documentchooser-foldercontent-col {
			padding-top: 0.5rem;
		}
		/* subnav kviklinks mobile */
		.ls-subnav1, .ls-subnav2 {
			.buttonlink, .buttonlink:hover, .buttonlink:focus, .buttonlink:active {
				display: inline-block;
				padding: 0.6em;

				a:link, a:visited, a:focus, a:active, a:hover {
					padding: 0.3em 0.5em 0.3em 0.5em;
				}
			}

			.ls-subnav-active {
				padding: 0.6em;

				a:link, a:visited, a:active, a:hover {
					padding: 0.3em 0.5em 0.3em 0.5em;
					text-shadow: none;
					background-color: transparent;
					cursor: default;
					border-radius: 0;
					border-bottom: solid 3px @--md-sys-color-primary;
					.primary-text;
				}
			}
		}
		/*context menu mobile*/
		div.lec-context-menu {
			.surface-container-highest;
			border: none;
			border-radius: 0.35rem;
			min-width: 10rem;
			box-shadow: 0 2px 2px 0 rgba(66, 66, 66, 0.08), 0 2px 3px 2px rgba(66, 66, 66, 0.16);

			ul > li {
				.buttonicon a, .buttonlink a {
					.on-surface-text;
					.body-small;
					font-size: 1rem;
					padding: 0.4rem 0.5rem 0.4rem 0.3rem;

					.ls-fonticon {
						padding-right: 0.15rem;
						font-size: 1.5rem;
					}
				}
			}
		}
	}
	/**************************************************************************************************/
	/* searchbox */
	.ls-searchbox-container {
		white-space: nowrap;
		border-radius: 1.125rem;
		padding: 0.5rem 1rem 0.5rem 0.75rem;
		.surface-container-highest;
		vertical-align: middle;
		.prepend-fonticon-search;

		&:before {
			vertical-align: middle;
			color: @color_lectio_DarkGray;
			padding: 0;
		}

		display: inline-block;
		box-sizing: border-box;
		outline: solid 1px transparent;

		&:hover, &:focus-within {
			/*outline: solid 1px @color_lectio_LightBlue;*/
			outline: solid 1px @--md-sys-color-primary;
		}

		input {
			border: none;
			background: transparent;
			vertical-align: middle;
			.label-medium;

			&:focus {
				border: none;
				outline: none;
			}
		}
	}
	/* ***************************************************/
	/* Fixed block Footer ************************/
	/* ***************************************************/
	#fixedBlock,
	.fixedBlock {
		z-index: 20;
		overflow: hidden;
		width: 100%;
		position: fixed;
		bottom: -0.4em;
		left: 0;
		margin: 0;
		padding: 0;
	}

	@media screen {

		FORM > #fixedBlock,
		FORM > .ls-std-rowblock {
			position: fixed;
			z-index: 20;
		}

		#fixedBlock,
		.fixedBlock {
			.ls-std-rowblock {
				transition: opacity 0.3s ease;
				opacity: 0.9;
				min-width: 40em;
				max-width: 1000px;
				margin-right: auto;
				margin-left: auto;
				border-radius: 1.125rem;
				.ls-elevation-level3;
				padding: 0.25rem 0.5rem 0rem 0.5rem;
				.surface-container-highest;
				margin: 0 auto 0.75rem auto;

				div.button {
					.reset-button-all;
					.ls-button-text;

					a:active, a:focus, a:link, a:visited {
						padding: @ls-buttonlink-toolbar-padding;
						cursor: pointer;
					}

					a:hover {
						opacity: 0.8
					}

					a[disabled="disabled"]:hover {
						cursor: default;
						opacity: 0.62;
					}
				}

				div.buttonlink, div.buttonlink:hover, div.buttonlink, div.buttonoutlined, div.ls-button-filledtonal {
					padding: @ls-buttonlink-toolbar-padding;

					a:active, a:focus, a:link, a:visited, a[disabled="disabled"]:hover {
						cursor: pointer;
						border: none;
					}

					a:hover {
						opacity: 0.8;
					}

					a[disabled="disabled"]:hover {
						cursor: default;
						opacity: 0.62;
					}
				}
			}

			.ls-std-rowblock:hover {
				opacity: 1.0;
			}
		}
	}
	/* END - FixedBlock Footer******************/
	/* target small screen */
	@media @mobile {
		/* vising af fixed knaprække på mobil */
		#fixedBlock .ls-std-rowblock,
		.fixedBlock .ls-std-rowblock, #fixedBlock .ls-std-rowblock:hover {
			min-width: 10rem;
			max-width: 100%;
			background: unset;
			cursor: unset;
			border: none;
			outline: none;
			margin: unset;
			padding: unset;
			border-radius: unset;
			transition: unset;
			box-shadow: unset;
			opacity: unset;
			background: unset;
			box-sizing: border-box;
			border-radius: 1.125rem;
			.ls-elevation-level1;
			margin: 0 0.5rem 0.75rem 0.5rem;
			padding: 0.1rem 0.5rem;
			.surface-container-highest;
			overflow-x: auto;
			flex-wrap: nowrap;

			div.buttonlink, div.buttonoutlined {
				padding: @ls-button-padding;
				padding-left: 0.4rem;
				padding-right: 0.4rem;

				a:active, a:focus, a:link, a:visited, a[disabled="disabled"]:hover {
					cursor: pointer;
					border: none;
					background: transparent;
					padding-left: 0.2rem;
					padding-right: 0.2rem;

					img, .ls-fonticon {
						display: none;
					}
				}
			}
		}
	}
	/* ***************************************************/
	/* MD Ikoner ************************/
	/* ***************************************************/
	.md-red {
		color: @brush_attention;
	}

	.ls-fonticon .notification-dot {
		position: absolute;
		padding: 5px 5px;
		border-radius: 50%;
		background: @brush_alert;
		margin-left: -0.5rem;
	}
	/* Mobil menu knapper*/
	.ls-mobil-mere-sheet-menu-list {
		display: flex;
		justify-content: flex-start;

		@media @mobileportrait {
			height: 90%;
			flex-flow: column wrap;
			overflow-y: auto;
		}

		@media @mobilelandscape {
			height: 100%;
			flex-flow: row wrap;
		}
	}

	.ls-mobil-mere-sheet-menu-list DIV.ls-mobil-menu-button {
		.label-small;

		@media @mobileportrait {
			width: 50%;
			min-height: calc(100% / 9);
			height: calc(100% / var(--TotalMobilMereLinks));
		}

		@media @mobilelandscape {
			width: calc(100% / 4);
			max-height: calc(100% / 4);
			min-height: calc(100% / 5);
			height: auto;
		}
	}

	.ls-mobil-mere-sheet-menu-list .ls-fonticon {
		font-size: 1.6rem;
		margin-right: 0.6rem;
	}

	.ls-mobil-mere-sheet-menu-list DIV a[href] {
		height: 100%;
		border-radius: 1.5rem;
		flex-flow: row nowrap;
		align-items: center;
		padding: 0 0.5rem;
		margin: 0 0.3rem;

		&:link, &:visited, &:hover, &:focus, &:active {
			.on-surface-text;
			background-color: transparent;
			display: flex;
			border: none;
			outline: none;
		}

		&[current-page] {
			.secondary-container;
			.secondary-container-text;
			border-radius: 1.5rem;
			font-weight: 700;

			.ls-fonticon {
				font-variation-settings: 'FILL' 1;
				font-weight: 300;
			}
		}
	}
