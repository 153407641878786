.file-constants-less {
	color: blue;
}
@import "fonticons.less";

/*	Opsætning af devices og mobile variabler .... */

// iOS Resolution: - https://www.ios-resolution.com/

// iPhone 14 Pro MAX, iPhone 15 Plus og iPhone 15 Pro Max: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone15promax_portrait: ~"only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone15promax_landscape: ~"only screen and (device-width: 430px) and (device-height: 932px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// iPhone 14 Pro, iPhone 15 og iPhone 15 Pro: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone15pro_portrait: ~"only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone15pro_landscape: ~"only screen and (device-width: 393px) and (device-height: 852px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// iPhone 12 Pro Max, iPhone 13 Pro Max og iPhone 14 Plus: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone14plus_portrait: ~"only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone14plus_landscape: ~"only screen and (device-width: 428px) and (device-height: 926px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// iPhone 12, iPhone 12 Pro, iPhone 13, iPhone 13 Pro og iPhone 14: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone14_portrait: ~"only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone14_landscape: ~"only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// For iPhone X, iPhone Xs, iPhone 11 Pro, iPhone 12 Mini og iPhone 13 Mini: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone13mini_portrait: ~"only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone13mini_landscape: ~"only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// For iPhone Xs Max og iPhone 11 Pro Max: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone11promax_portrait: ~"only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone11promax_landscape: ~"only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// iPhone XR og iPhone 11: - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone11_portrait: ~"only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)";
@device_iPhone11_landscape: ~"only screen and (device-width: 414px) and (device-height: 896px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)";

// For iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus og iPhone 8 Plus (2017): - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhone8plus_portrait: ~"only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";
@device_iPhone8plus_landscape: ~"only screen and (device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";

// For iPhone 7 (2016), iPhone 8 (2017), og iPhone SE (1st to 3rd gen): - https://stackoverflow.com/questions/58087446/all-media-queries-for-iphone-14-pro-max-plus-mini-and-older-ones
@device_iPhoneSE_portrait: ~"only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: portrait)";
@device_iPhoneSE_landscape: ~"only screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) and (orientation: landscape)";

@device_iPhone_portrait: @device_iPhone15pro_portrait, @device_iPhone14plus_portrait, @device_iPhone15promax_portrait, @device_iPhone14_portrait, @device_iPhone13mini_portrait, @device_iPhone11_portrait, @device_iPhone11promax_portrait, @device_iPhone8plus_portrait, @device_iPhoneSE_portrait;
@device_iPhone_landscape: @device_iPhone15pro_landscape, @device_iPhone14plus_landscape, @device_iPhone15promax_landscape, @device_iPhone14_landscape, @device_iPhone13mini_landscape, @device_iPhone11_landscape, @device_iPhone11promax_landscape, @device_iPhone8plus_landscape, @device_iPhoneSE_landscape;

@device_ipad_pro_landscape: ~ "only screen and (orientation: landscape) and (min-device-width: 1024px) and (max-device-width: 1366px)";
//@device_tablet_landscape: ~ "only screen and (device-width: 768px) and (device-height: 1024px) and (orientation:landscape)";

//Android Resolutions
@device_Galaxy8_landscape: ~"only screen and (device-width:  448px) and (device-height: 998px) and (-webkit-device-pixel-ratio: 3) and (orientation: landscape)";
@device_Galaxy8_portrait: ~"only screen and (device-width:  448px) and (device-height: 998px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait)";

//Android Nokia test
/*@device_NokiaXR21_portrait: ~"only screen and (device-width:  432px) and (device-height: 960px) and (-webkit-device-pixel-ratio: 2.5) and (orientation: portrait)";
@device_NokiaXR21_landscape: ~"only screen and (device-width:  432px) and (device-height: 960px) and (-webkit-device-pixel-ratio: 2.5) and (orientation: landscape)";*/

 
@device_Android_landscape: @device_Galaxy8_landscape;
@device_Android_portrait: @device_Galaxy8_portrait;

/* rettet fra 414px til 448px pga nokiaxr21, pixel8pro*/
/*@mobile_otherportrait: ~ "only screen and (max-device-width: 414px) and (orientation: portrait)";*/
@mobile_otherportrait: ~ "only screen and (max-device-width: 448px) and (orientation: portrait)";

@mobileportrait: @mobile_otherportrait, @device_iPhone_portrait, @device_Android_portrait;

@mobile_smalllandscape: ~ "only screen and (max-device-width: 767px) and (max-device-height: 1023) and (orientation: landscape)";
@mobile_otherlandscape: ~ "only screen and (max-device-width: 932px) and (orientation: landscape) and (min-resolution: 2.1dppx)";
@mobilelandscape: @mobile_otherlandscape, @device_iPhone_landscape, @mobile_smalllandscape, @device_Android_landscape;

@mobile: @mobileportrait, @mobilelandscape;

@mere_sheet_menu_height: calc((60vh / 10 * var(--TotalMobilMereLinks) + 6vh));
@mobil-menu-height: max(7svh, 4rem);
@mobil-menu-width: 5.2rem;
@mobil-page-drawer-width: 85vw;


/*************************/
/* START m3 color tokens */
@--md-sys-color-primary-light: #006493;
@--md-sys-color-on-primary-light: #ffffff;
@--md-ref-palette-primary90: #cae6ff;

@--md-sys-color-secondary-light: #50606e;
@--md-ref-palette-secondary95:#e7f1fe;

@--md-sys-color-outline-light: #72787e;
@--md-sys-color-outline-variant-light: #c1c7ce;

@--md-sys-color-tertiary-light: #00687a;
@--md-ref-palette-tertiary95: #f0fbff;
@--md-ref-palette-tertiary99: #f8fdff;


@--md-sys-color-surface-light: @--md-ref-palette-neutral99;
@--md-sys-color-on-surface-light: #1a1c1e;
@--md-sys-color-surface-variant-light: #dde3ea;
@--md-sys-color-on-surface-variant-light: #41474d;

@--md-sys-color-background-light: @--md-ref-palette-neutral98;
@--md-sys-color-background: @--md-sys-color-background-light;

@--md-sys-color-primary-container-light: #cae6ff;
@--md-sys-color-secondary-container-light: #d3e5f5;
@--md-sys-color-on-primary-container-light: #001e2f;
@--md-sys-color-on-secondary-container-light: #0c1d29;
@--md-sys-color-tertiary-container-light: #acedff;
@--md-sys-color-on-tertiary-container-light: #001f26;

@--md-ref-palette-neutral10: #1a1c1e;
@--md-ref-palette-neutral25: #393c3e;
@--md-ref-palette-neutral40: #5d5e61;
@--md-ref-palette-neutral50: #75777a;
@--md-ref-palette-neutral60: #8f9193;
@--md-ref-palette-neutral70: #aaabae;
@--md-ref-palette-neutral80: #c6c6c9;
@--md-ref-palette-neutral90: #e2e2e5;
@--md-ref-palette-neutral95: #f0f0f3;
@--md-ref-palette-neutral98: #f9f9fc;
@--md-ref-palette-neutral99: #fcfcff;


@--md-ref-palette-neutral-variant95: #ecf1f9;
@--md-ref-palette-neutral-variant98: #f6f9ff;

@--md-ref-palette-error30: #93000a;
@--md-ref-palette-error35: #a80710;
@--md-ref-palette-error40: #ba1a1a;
@--md-ref-palette-error50: #de3730;
@--md-ref-palette-error60: #e46f61;
@--md-ref-palette-error70: #ff897d;
@--md-ref-palette-error90: #ffdad6;
/*blue*/
@--md-ref-palette-custom195: #e9f1fe;
@--md-ref-palette-custom190: #cfe3fe;
@--md-ref-palette-custom180: #9bc9fd;
@--md-ref-palette-custom170: #6eaef1;
@--md-ref-palette-custom160: #5193d4;
@--md-ref-palette-custom150: #3279b8;
@--md-ref-palette-custom140: #1d5f97;
@--md-ref-palette-custom130: #144773;
@--md-ref-palette-custom110: #041c31;
/*red*/
@--md-ref-palette-custom295: #feeeea;
@--md-ref-palette-custom290: #fedbd6;
@--md-ref-palette-custom280: #fdb7ac;
@--md-ref-palette-custom270: #fc8e80;
@--md-ref-palette-custom260: #fc5d4f;
@--md-ref-palette-custom240: #a43f34;
@--md-ref-palette-custom210: #400404;
/*green*/
@--md-ref-palette-custom398: #f1ffcd;
@--md-ref-palette-custom395: #dcff93;
@--md-ref-palette-custom390: #cef087;
@--md-ref-palette-custom380: #b3d36e;
@--md-ref-palette-custom370: #98b756;
@--md-ref-palette-custom360: #7e9c3f;
@--md-ref-palette-custom340: #4e670f;
@--md-ref-palette-custom330: #394e08;
@--md-ref-palette-custom310: #151f01;
/*yellow*/
@--md-ref-palette-custom499: #fffefa;
@--md-ref-palette-custom498: #fcfbf7;
@--md-ref-palette-custom495: #fdf2c3;
@--md-ref-palette-custom490: #fbe570;
@--md-ref-palette-custom480: #dec855;
@--md-ref-palette-custom470: #c2ad3d;
@--md-ref-palette-custom440: #6d5f0f;
@--md-ref-palette-custom410: #211c01;
/*orange*/
@--md-ref-palette-custom580: #ffb877;
@--md-ref-palette-custom570: #fb910b;
@--md-ref-palette-custom560: #d67a00;
/*purple*/
@--md-ref-palette-custom660: #a67fd6;
@color_lectio_Purple: #ddc4ff;
/*brun*/
@--md-ref-palette-custom760: #b6893e;
/*turkish*/
@--md-ref-palette-custom898: #f0fbff;
@--md-ref-palette-custom895: #d8f6ff;
@--md-ref-palette-custom890: #daf0f7;
@--md-ref-palette-custom880: #55d6f4;
@--md-ref-palette-custom870: #2dbad7;
@--md-ref-palette-custom860: #009eb9;
@--md-ref-palette-custom840: #00687a;
@--md-ref-palette-custom810: #001f26;
/*
/* END m3 color tokens*/
/* START m3 color theme light */
@--md-sys-color-primary: @--md-sys-color-primary-light;
@--md-sys-color-on-primary: @--md-sys-color-on-primary-light;
@--md-sys-color-secondary: @--md-sys-color-secondary-light;
@--md-sys-color-tertiary: @--md-sys-color-tertiary-light;
@--md-sys-color-surface: @--md-sys-color-surface-light;
@--md-sys-color-on-surface: @--md-sys-color-on-surface-light;
@--md-sys-color-surface-variant: @--md-sys-color-surface-variant-light;
@--md-sys-color-on-surface-variant: @--md-sys-color-on-surface-variant-light;
@--md-sys-color-primary-container: @--md-sys-color-primary-container-light;
@--md-sys-color-secondary-container: @--md-sys-color-secondary-container-light;
@--md-sys-color-tertiary-container: @--md-sys-color-tertiary-container-light;
@--md-sys-color-on-primary-container: @--md-sys-color-on-primary-container-light;
@--md-sys-color-on-secondary-container: @--md-sys-color-on-secondary-container-light;
@--md-sys-color-on-tertiary-container: @--md-sys-color-on-tertiary-container-light;
@--md-sys-color-outline: @--md-sys-color-outline-light;
@--md-sys-color-outline-variant: @--md-sys-color-outline-variant-light;

@--md-sys-color-surface-container-light: @--md-ref-palette-neutral-variant98;
@--md-sys-color-surface-container: @--md-sys-color-surface-container-light;

@--md-sys-color-surface-container-low-light: #fff; /* - Alt MD spec org. @--md-ref-palette-neutral96 */
@--md-sys-color-surface-container-low: @--md-sys-color-surface-container-low-light;

@--md-sys-color-surface-container-highest-light: @--md-ref-palette-neutral-variant95;
@--md-sys-color-surface-container-highest: @--md-sys-color-surface-container-highest-light;
/* END m3 color theme light */
/* START m3 color module */
.primary {
	background-color: @--md-sys-color-primary;
}

.primary-text {
	color: @--md-sys-color-primary;
}

.on-primary {
	background-color: @--md-sys-color-on-primary;
}

.on-primary-text {
	color: @--md-sys-color-on-primary;
}

.tertiary-text {
	color: @--md-sys-color-tertiary;
}

.surface {
	background-color: @--md-sys-color-surface;
}
.background {
	background-color: @--md-sys-color-background;
}
.on-surface {
	background-color: @--md-sys-color-on-surface;
}

.on-surface-text {
	color: @--md-sys-color-on-surface;
}

.surface-variant {
	background-color: @--md-sys-color-surface-variant;
}

.on-surface-variant {
	background-color: @--md-sys-color-on-surface-variant;
}

.on-surface-variant-text {
	color: @--md-sys-color-on-surface-variant;
}

.primary-container {
	background-color: @--md-sys-color-primary-container;
}

.secondary-container {
	background-color: @--md-sys-color-secondary-container;
}
.tertiary-container {
	background-color: @--md-sys-color-tertiary-container;
}


.primary-container-text {
	color: @--md-sys-color-on-primary-container;
}

.secondary-container-text {
	color: @--md-sys-color-on-secondary-container;
}
.tertiary-container-text {
	color: @--md-sys-color-on-tertiary-container;
}

.surface-container {
	background-color: @--md-sys-color-surface-container;
}

.surface-container-low {
	background-color: @--md-sys-color-surface-container-low;
}

.surface-container-highest {
	background-color: @--md-sys-color-surface-container-highest;
}

.outline {
	/*outline-color: @--md-sys-color-outline;
	outline-style: solid;
	outline-width: 1px;*/
	border-color: @--md-sys-color-outline;
	border-style: solid;
	border-width: 1px;
}

.outline-variant {
	border-color: @--md-sys-color-outline-variant;
	border-style: solid;
	border-width: 1px;
}
/* END m3 color module */
/***************************************************/
/* START Elevation **********************************************************************************/
@--md-sys-elevation-level0:none;
@--md-sys-elevation-level1: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
@--md-sys-elevation-level2: rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px;
@--md-sys-elevation-level3: rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px 18px 0px;
@--md-sys-elevation-level4:rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
@--md-sys-elevation-level5:rgba(0, 0, 0, 0.2) 0px 7px 8px -4px, rgba(0, 0, 0, 0.14) 0px 12px 17px 2px, rgba(0, 0, 0, 0.12) 0px 5px 22px 4px;

.ls-elevation-level0 {
	box-shadow: @--md-sys-elevation-level0;
}
.ls-elevation-level1 {
	box-shadow: @--md-sys-elevation-level1;
}
.ls-elevation-level2 {
	box-shadow: @--md-sys-elevation-level2;
}
.ls-elevation-level3 {
	box-shadow: @--md-sys-elevation-level3;
}
.ls-elevation-level4 {
	box-shadow: @--md-sys-elevation-level4;
}
.ls-elevation-level5 {
	box-shadow: @--md-sys-elevation-level5;
}
/* END Elevation **********************************************************************************/

/* Diverse? */
@color_lectio_GrayBlue1: #475968;
@color_lectio_GrayBlue2: #5E6D7A;
@color_lectio_GrayBlue3: #466c80;
/*#d3dfeb*/
@color_lectio_GrayLightBlue1: #d3dae0;
@color_lectio_GrayLightBlue2: #b8c3cc;
@color_lectio_GrayLightBlue3: #9AA3AB;


@color_lectio_LightGray: @--md-ref-palette-neutral95;
@color_lectio_LightGray1: @--md-ref-palette-neutral98;
@color_lectio_LightGray2: @--md-ref-palette-neutral80;
@color_lectio_LightGray3: @--md-ref-palette-neutral70;

@color_lectio_Gray: @--md-ref-palette-neutral60;
@color_lectio_DarkGray: @--md-ref-palette-neutral50;
@color_lectio_Black: @--md-sys-color-on-surface-light;


@color_lectio_SkyBlue: #f7fafc;
@color_lectio_SkyBlue2: #ebeef0;
@color_lectio_BasicBlue: #0076d6;
@color_lectio_LightBlue: #1f76ea;
@color_lectio_Blue: #1b5c9e;
/*@color_lectio_DarkBlue: #0e65ba;*/
@color_lectio_DarkBlue: @--md-sys-color-primary;

@color_BlueWeak: #e5f5ff;
@color_BlueWeakStronger: @--md-ref-palette-custom190;
@color_GreenWeak: #f0ffe0;
@color_GreenWeakStronger: #e5ffcc;

@color_lectio_LightGreen: #f0fced;
@color_lectio_Green: @--md-ref-palette-custom390;
@color_lectio_Green2: #5BCF21;
@color_lectio_DarkGreen: #339900;

@color_lectio_LightYellow: #fffbcc;
@color_lectio_Yellow: @--md-ref-palette-custom490;
@color_lectio_DarkYellow: #E8B611;

@color_lectio_Pink: #ffdee9;
@color_lectio_LightRed: @--md-ref-palette-error90;
@color_lectio_Red: @--md-ref-palette-error60;
@color_lectio_DarkRed: @--md-ref-palette-error40;

@color_lectio_LightOrange: #ffbe4c;
@color_lectio_Orange: #ffa000;
@color_lectio_DarkOrange: #e59100;

@color_lectio_macomonly: #8000f0;
@color_lectio_macomonly_bg: #f7f2fa;

/*bar color styles*/
@color_lectio_Sky1: #dce9f5;
@color_lectio_Sky2: #cad5e0;
@color_lectio_Violet1: #dfdcf5;
@color_lectio_Violet2: #cdcae0;
@color_lectio_Grass1: #dcf5dc;
@color_lectio_Grass2: #cae0ca;
@color_lectio_Ocean: #dcf5f5;
@color_lectio_Sand: #f5f3dc;
/*backgrounds table rows*/
@brush_bg_highlight: @--md-ref-palette-neutral99;
@brush_bg_highlightWeak: @--md-ref-palette-custom499;

/*text*/
@brush_text: @color_lectio_Black;
@brush_text_heading: @color_lectio_GrayBlue1;
/* disabled */
@brush_disabled_light: @color_lectio_LightGray;
@brush_disabled: @color_lectio_LightGray2;
@brush_disabled_dark: @color_lectio_DarkGray;
/*font-sizes*/
@ls-fontsizebase: 75%;
@ls-h1: 1.5em;
@ls-h2: 1.4em;
@ls-h3: 1.2em;
@ls-h4: 1em;
/*info text*/
/*@brush_infotext: @color_lectio_GrayBlue3;*/
@brush_infotext:@--md-sys-color-tertiary;
/*norecord text*/
@brush_norecordtext: @color_lectio_DarkGray;
/*alerts*/
@brush_attention: @--md-ref-palette-error35;
@brush_alert: @--md-ref-palette-error50;
/*images*/
@gradient_bg_discrete: url('/lectio/img/graddiscrete.png');
@gradient_bg_darkGray: url('/lectio/img/graddarkgray.png');
@gradient_bg_lightGray: url('/lectio/img/gradlightgray.png');
@gradient_bg_header: url('/lectio/img/gradheader.png');
@gradient_bg_field: url('/lectio/img/field.gif');
/*icons*/
@icon_nav_down: url("/lectio/img/chevron_down_dark.auto");
@icon_nav_down_hover: url("/lectio/img/chevron_down_blue.auto");
@icon_nav_down_disabled: url("/lectio/img/chevron_down.auto");
/*paper*/
@solid_bg_paper: #ffffff;
@radius_border_paper: 2px;
@border_paper: 1px solid @--md-sys-color-outline-variant;
@shadow_border_paper: @--md-sys-elevation-level1;
/*canvas*/
@solid_bg_canvas: #f7f7f7;
@solid_bg_canvas_light: #fefefe;
/*borders*/
@brush_border_light: @--md-ref-palette-neutral90;
@brush_border: @--md-ref-palette-neutral80;
@brush_border_dark: @--md-ref-palette-neutral70;
@brush_border_radius: 0px;
/*input fields*/
@input-size: 1.75em;
@input-border-color: @color_lectio_LightGray3;
@input-border-color-disabled: @brush_disabled;
/*link*/
@brush_link: @color_lectio_Blue;
@brush_linkHover: #ffffff;
@brush_linkBackgroundHover: @color_lectio_DarkBlue;
/*button*/
@brush_buttonBackground: #d5e3f2;
@gradient_buttonBackground: url('/lectio/img/gradlink.png');
@brush_buttonHighlight: @brush_border_light;
@brush_buttonShadow: @brush_border;
@brush_buttonBackground-alt1: @color_lectio_LightBlue;
/*button hover*/
@brush_buttonBackgroundHover: @color_lectio_DarkBlue;
@gradient_buttonBackgroundHover: url('/lectio/img/gradhover.png');
@brush_buttonHighlightHover: @color_lectio_LightBlue;
@brush_buttonShadowHover: @color_lectio_DarkBlue;

.ls-focus-base-style {
	outline-style: solid;
	outline-color: @color_lectio_LightBlue;
	outline-width: thin;
}


/***************************************************************************************************/
/* NEW23 *******************************************************************************************/
/***************************************************************************************************/
/* generelt sidelayout (margin, padding) **********/
@ls-padding-page-container-top: 0.4em;
@ls-padding-page-container-right: 0.5em;
@ls-padding-page-container-bottom: 0;
@ls-padding-page-container-left: 0.5em;


	/* Fonts *******************************************************************************************/
	/* fonts.google.com/specimen/Roboto*/
	@font-face {
		font-family: 'Roboto';
		src: url(/lectio/fonts/Roboto-Regular.ttf) format(truetype);
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto';
		src: url(/lectio/fonts/Roboto-Italic.ttf) format(truetype);
		font-weight: 400;
		font-style: italic;
	}

	@font-face {
		font-family: 'Roboto';
		src: url(/lectio/fonts/Roboto-Bold.ttf) format(truetype);
		font-weight: 700;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto';
		src: url(/lectio/fonts/Roboto-BoldItalic.ttf) format(truetype);
		font-weight: 700;
		font-style: italic;
	}

	@font-face {
		font-family: 'Roboto Thin';
		src: local('Roboto Thin'), url(/lectio/fonts/Roboto-Thin.ttf) format(truetype);
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Thin';
		src: local('Roboto Thin'), url(/lectio/fonts/Roboto-ThinItalic.ttf) format(truetype);
		font-weight: 400;
		font-style: italic;
	}

	@font-face {
		font-family: 'Roboto Light';
		src: local('Roboto Light'), url(/lectio/fonts/Roboto-Light.ttf) format(truetype);
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Light';
		src: local('Roboto Light'), url(/lectio/fonts/Roboto-LightItalic.ttf) format(truetype);
		font-weight: 400;
		font-style: italic;
	}

	@font-face {
		font-family: 'Roboto Medium';
		src: local('Roboto Medium'), url(/lectio/fonts/Roboto-Medium.ttf) format(truetype);
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Medium';
		src: local('Roboto Medium'), url(/lectio/fonts/Roboto-MediumItalic.ttf) format(truetype);
		font-weight: 400;
		font-style: italic;
	}

	@font-face {
		font-family: 'Roboto Black';
		src: local('Roboto Black'), url(/lectio/fonts/Roboto-Black.ttf) format(truetype);
		font-weight: 400;
		font-style: normal;
	}

	@font-face {
		font-family: 'Roboto Black';
		src: local('Roboto Black'), url(/lectio/fonts/Roboto-BlackItalic.ttf) format(truetype);
		font-weight: 400;
		font-style: italic;
	}
	/* text-size md3 *************************************/
	.typography-base {
		font-family: Roboto;
		font-style: normal;
	}

	.body-small {
		.typography-base;
		font-weight: 400;
		letter-spacing: 0.033rem;
		line-height: 1.333rem;
	}

	.label-small {
		.typography-base;
		font-weight: 500;
		line-height: 1.333rem;
		letter-spacing: 0.042rem;
		font-size: 0.916rem;
	}

	.label-medium {
		.typography-base;
		font-weight: 500;
		line-height: 1.333rem;
		letter-spacing: 0.042rem;
	}

	.label-large {
		.typography-base;
		font-weight: 500;
		line-height: 1.666rem;
		letter-spacing: 0.008rem;
		font-size: 1.166rem;
	}

	@media only screen and (max-width: 420px) {
		.label-large, .label-medium, .label-small {
			white-space: pre-wrap;
		}
	}

	.title-small {
		.typography-base;
		font-weight: 500;
		font-size: 1.166rem;
		line-height: 1.666rem;
		letter-spacing: 0.008rem;
	}

	.title-medium {
		.typography-base;
		font-weight: 500;
		font-size: 1.333rem;
		line-height: 2rem;
		letter-spacing: 0.0125rem;
	}

	.title-large {
		.typography-base;
		font-weight: 400;
		font-size: 1.833rem;
		line-height: 2.333rem;
		letter-spacing: 0;
	}
/***********/


.ls-fill-fonticon {
	.primary-text;
	font-variation-settings: 'FILL' 1;
}

	// LectioButton
.button, .buttonfilled, .buttonoutlined, .buttonfilledtonal, .buttonicon, .buttonlink {
	.ls-fonticon {
		font-size: initial;
		vertical-align: middle;
		font-size: 1.5rem;
		
	}
}



	.ls-master-pageheader {
		.header-left-button, .header-function-buttons {
			.button, .buttonfilled, .buttonoutlined, .buttonfilledtonal, .buttonicon, .buttonlink {
				.ls-fonticon {
					font-size: 2rem;
				}
			}
		}
	}
	// Mobil menu buttons
	.ls-bottom-app-bar .ls-fonticon {
		font-size: 1.375rem;
	}
	/******************************************************/
		/* start skemabrik styles *****************************/
		/******************************************************/
		// Skemabrik-text
		.skemabrik-normal-text() {
			color: @--md-ref-palette-custom140;
			font-style: normal;
			text-decoration: none;
		}
		.skemabrik-optional-text() {
			color: @--md-ref-palette-custom840;
			font-style: normal;
			text-decoration: none;
		}
		.skemabrik-changed-text() {
			/*color: @--md-ref-palette-custom360;*/
			color: #339900;
			font-style: italic;
			text-decoration: none;
		}

		.skemabrik-cancelled-text() {
			color: @--md-ref-palette-custom240;
			font-style: italic;
			text-decoration: line-through;
		}

		.skemabrik-ambient-text() {
			color: @--md-ref-palette-custom140;
			font-style: normal;
			text-decoration: none;
		}

		.skemabrik-exam-text() {
			color: @--md-ref-palette-custom440;
			font-style: normal;
			text-decoration: none;
		}

		.skemabrik-scenario-text() {
			color: @--md-ref-palette-custom340;
			font-style: normal;
			text-decoration: none;
		}

		.skemabrik-box() {
			border: none;
			box-sizing: border-box;
			border: solid 2px @--md-sys-color-surface;
		}
		// Skemabrik-box
		.skemabrik-normal() {
			.skemabrik-box();
			border: solid 2px transparent;
			background-color: @--md-ref-palette-custom195;
			border-left: solid 2px @--md-ref-palette-custom170;
			.skemabrik-normal-text();
		}
		.skemabrik-optional() {
			.skemabrik-box();
			border: solid 2px transparent;
			background-color: @--md-ref-palette-custom895;
			border-left: solid 2px @--md-ref-palette-custom860;

			.skemabrik-optional-text();
		}

		.skemabrik-changed() {
			.skemabrik-box();
			border: solid 2px transparent;
			background-color: @--md-ref-palette-custom195;
			border-left: solid 2px @--md-ref-palette-custom170;
			.skemabrik-changed-text();
		}

		.skemabrik-cancelled() {
			.skemabrik-box();
			border: solid 2px transparent;
			background-color: @--md-ref-palette-custom290;
			border-left: solid 2px @--md-ref-palette-custom270;
			.skemabrik-cancelled-text();
		}

		.skemabrik-ambient() {
			.skemabrik-box();
			border: none;
			background-color: @--md-ref-palette-neutral95;
			border-left: solid 2px @--md-ref-palette-neutral80;
			.skemabrik-ambient-text();
		}

		.skemabrik-exam() {
			.skemabrik-box();
			border: solid 2px transparent;
			background-color: @--md-ref-palette-custom490;
			border-left: solid 2px @--md-ref-palette-custom470;
			.skemabrik-exam-text();
		}
		.skemabrik-scenario() {
			.skemabrik-box();
			border: solid 2px transparent;
			background-color: @--md-ref-palette-custom390;
			border-left: solid 2px @--md-ref-palette-custom370;
			/*	.skemabrik-scenario-text();*/
		}

		.skemabrik-selected() {
			.skemabrik-box();
			border: solid 2px @--md-sys-color-primary;
		}

		.skemabrik-defocus() {
			.skemabrik-box();
			opacity: 0.8;
			filter: grayscale(60%);
		}

		/* skemabrik ledige hold...*/
		/* skemabrik frivillig altivitet ...*/


		/***************************************************************************/
		/**************************************************************************/
		/* *************************************************************************/
		/*Entitet ikon farver */
		@color_stamclass: @--md-ref-palette-custom170; /*blå*/ /* Stamklasse */
		@color_hold: @--md-ref-palette-custom170; /* blå */ /* Hold */
		@color_holdelement: @color_hold;
		@color_student: @--md-ref-palette-custom570; /*orange*/ /* Elev */
		@color_teacher: @--md-ref-palette-custom370; /*grøn*/ /* Lærer */
		@color_room: @--md-ref-palette-custom480; /*gul*/ /* Lokale */
		@color_resource: @--md-ref-palette-custom440; /*brun*/ /* Ressource */
		@color_studentforloeb: @color_student; /*blå*/ /* Elevforløb */
		@color_group: @--md-ref-palette-custom170; /*blå*/ /* grupper*/
		@color_uddannelseforloeb: @--md-sys-color-primary; /* primary */ /* Uddannelsesforløb*/
		/****************************************************************************/
		/* dbl book farver */
		@color-dblbook-prio1: @--md-ref-palette-custom260; /*rød*/
		@color-dblbook-prio2: @--md-ref-palette-custom570; /*orange*/
		@color-dblbook-prio3: @--md-ref-palette-custom490; /*gul*/
		/************************************************************************/
		/* prio bullits*/
		@color-bullit-prio1: @--md-ref-palette-custom260; /*rød*/
		@color-bullit-prio2: @--md-ref-palette-custom490; /*gul*/
		@color-bullit-prio3: @--md-sys-color-primary; /* primary */
		/************************************************************************/
		/* Fravær farver */
		@color-fv-100: @--md-ref-palette-custom260; /*rød*/
		@color-fv-ig: lightcoral; /*lysere rød*/
		@color-fv-50: @--md-ref-palette-custom490; /*gul*/
		@color-fv-0: @--md-ref-palette-custom390; /*grøn*/
		@color-fv-not-relevant: @--md-ref-palette-neutral90; /*gray*/
		/************************************************************************/
		/*Frie lærere, lokaler,ledig-skolehjemplads ... farver */
		@color-ledig: @--md-ref-palette-custom390; /*grøn*/
		/************************************************************************/
		/* Plagiat farver */
		@color-plagiat-high: @--md-ref-palette-custom260; /*rød*/
		@color-plagiat-medium: @--md-ref-palette-custom570; /*orange*/
		@color-plagiat-low: @--md-ref-palette-custom480; /*gul*/

		/************************************************************************/
		/*modul norm afvigelse farver*/
		@color-warning-high: @--md-ref-palette-custom295; /*lys rød*/
		@color-warning-low: @--md-ref-palette-custom495; /*gul */
		@color-warning-no: @--md-ref-palette-custom398; /*grøn*/
		/*************************************************************************/
		/* studieplan farver **/
		@color-now: @--md-ref-palette-custom390; /*grøn*/
		/*************************************************************************/
		/* skolehjem farver **/
		/* Quiz */
		@color-correct: @--md-ref-palette-custom370; /*grøn*/
		@color-partly-correct: @--md-ref-palette-custom480; /*gul*/
		@color-not-correct: @--md-ref-palette-custom260; /*rød*/
		/* Andre farver **/
		@color-ok: @--md-ref-palette-custom370; /*grøn*/
		@color-add: @--md-ref-palette-custom380; /*grøn*/

		/* ikke-skoledage - Lukkedage - inaktive dage i skemaer, kalendere, planer*/
		.not-schoolday, .inactive-period {
			background-color:@--md-ref-palette-neutral95
			}

		/* farvetest */
		@color-graph-brown: @--md-ref-palette-custom760;
		@color-graph-yellow: @--md-ref-palette-custom480;
		@color-graph-orange: @--md-ref-palette-custom570;
		@color-graph-red: @--md-ref-palette-custom260;
		@color-graph-darkred: lch(51.64 74.08 34.51);
		@color-graph-blue: @--md-ref-palette-custom170;
		@color-graph-darkblue: lch(48.85 40.98 260.5);
		@color-graph-green: @--md-ref-palette-custom380;
		@color-graph-darkgreen: lch(60.56 51.22 116.37);
		@color-graph-turkish: @--md-ref-palette-custom870;
		@color-graph-darkturkish: lch(59.4 39.59 220.44);
		@color-graph-purple: @--md-ref-palette-custom660;
		@color-graph-gray: @--md-ref-palette-neutral70;
		@color-graph-black: @--md-ref-palette-neutral40;

		.gf-brown, .gf-yellow, .gf-orange, .gf-red, .gf-purple, .gf-blue, .gf-turkish, .gf-green, .gf-gray, .gf-black, .gf-darkblue, .gf-darkgreen, .gf-darkred, .gf-darkturkish {
			width: 1em;
			height: 1em;
			margin: 0.25rem;
		}
		.gf-brown {
			background-color: @color-graph-brown;
		}
		.gf-yellow {
			background-color: @color-graph-yellow;
		}
		.gf-orange {
			background-color: @color-graph-orange;
		}
		.gf-red {
			background-color: @color-graph-red;
		}
		.gf-darkred {
			background-color: @color-graph-darkred;
		}
		.gf-purple {
			background-color: @color-graph-purple;
		}
		.gf-blue {
			background-color: @color-graph-blue;
		}
		.gf-darkblue {
			background-color: @color-graph-darkblue;
		}
		.gf-turkish {
			background-color: @color-graph-turkish;
		}
		.gf-darkturkish {
			background-color: @color-graph-darkturkish;
		}
		.gf-green {
			background-color: @color-graph-green;
		}
		.gf-darkgreen {
			background-color: @color-graph-darkgreen;
		}
		.gf-gray {
			background-color: @color-graph-gray;
		}
		.gf-black {
			background-color: @color-graph-black;
		}


.test-color {

	th {
		text-align: right;
	}

	td {
		min-width: 3rem;
		text-align: center;
	}

	//@h: 215; //tertiary
	@h: 250; //lectio-color, primary, secondary
	/* C10 */
	tr:nth-of-type(1) {
		@c: 8;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/* C12 */
	tr:nth-of-type(2) {
		@c: 12;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/* C20 */
	tr:nth-of-type(3) {
		@c: 20;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/* C30 */
	tr:nth-of-type(4) {
		@c: 30;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/* C40 */
	tr:nth-of-type(5) {
		@c: 40;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/* C50 */
	tr:nth-of-type(6) {
		@c: 50;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/*C60 */
	tr:nth-of-type(7) {
		@c: 60;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/*C70 */
	tr:nth-of-type(8) {
		@c: 70;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/*C80 */
	tr:nth-of-type(9) {
		@c: 80;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/*C90 */
	tr:nth-of-type(10) {
		@c: 90;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
	/*C100 */
	tr:nth-of-type(11) {
		@c: 100;


		td:nth-of-type(1) {
			background-color: LCH(25 @c @h);
		}

		td:nth-of-type(2) {
			background-color: LCH(30 @c @h);
		}

		td:nth-of-type(3) {
			background-color: LCH(35 @c @h);
		}

		td:nth-of-type(4) {
			background-color: LCH(40 @c @h);
		}

		td:nth-of-type(5) {
			background-color: LCH(45 @c @h);
		}

		td:nth-of-type(6) {
			background-color: LCH(50 @c @h);
		}

		td:nth-of-type(7) {
			background-color: LCH(55 @c @h);
		}

		td:nth-of-type(8) {
			background-color: LCH(60 @c @h);
		}

		td:nth-of-type(9) {
			background-color: LCH(65 @c @h);
		}

		td:nth-of-type(10) {
			background-color: LCH(70 @c @h);
		}

		td:nth-of-type(11) {
			background-color: LCH(75 @c @h);
		}

		td:nth-of-type(12) {
			background-color: LCH(80 @c @h);
		}

		td:nth-of-type(13) {
			background-color: LCH(85 @c @h);
		}

		td:nth-of-type(14) {
			background-color: LCH(90 @c @h);
		}

		td:nth-of-type(15) {
			background-color: LCH(95 @c @h);
		}

		td:nth-of-type(16) {
			background-color: LCH(98 @c @h);
		}

		td:nth-of-type(17) {
			background-color: LCH(100 @c @h);
		}
	}
}


/*************************************************************************/
/*************************************************************************/
.file-constants-less-end {
	color: blue;
}




