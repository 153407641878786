@import "constants.less";
@import "framing.less";


.ls-scanner-container {
	max-width: 34rem;
	margin-bottom: 1rem;

	@media @mobile {
		width: 95vw;
		max-width: 95vw;
	}
}

.ls-scanner-container-title {
	.title-medium;
	
	@media @mobile {
		.title-small;
		max-width: 95vw;
	}
}

.ls-scanner-cameraview {
	margin-top: 0.75rem;
	margin-bottom: 1.25rem;
	box-sizing: border-box;
	text-align: center;

	div#videoContainer {
		video {
			text-align: center;
			display: block;
			object-fit: cover;
			object-position: 50% 50%;
			height: 15rem;
			width: 15rem;
			margin-left: auto;
			margin-right: auto;
			border-radius: 0.25rem;

			@media @mobileportrait {
				width: 35vw;
				height: 35vw;
			}
			/**************/
			--b: 0.2rem; /* border thickness */
			--c: #0000 25%,#666 0; /* define the color here */
			padding: 1rem;
			background: conic-gradient(from 90deg at top var(--b) left var(--b),var(--c)) 0 0, conic-gradient(from 180deg at top var(--b) right var(--b),var(--c)) 100% 0, conic-gradient(from 0deg at bottom var(--b) left var(--b),var(--c)) 0 100%, conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--c)) 100% 100%;
			background-size: 25px 25px; /* adjust border length here */
			background-repeat: no-repeat;
			/******************/
		}
		/** start **/
		div {
			display: flex;
			justify-content: center;
			margin: auto auto;
			width: 10rem;

			input[type="radio"] {
				appearance: none;
				cursor: pointer;
				margin: 0.2rem 0.5rem;
				border-radius: 50%;
				width: 0.8rem;
				height: 0.8rem;
				display: flex;
				justify-content: center;
				align-items: center;
				align-self: center;
				background-color: transparent;
				.surface-container-highest;
				transition: background-color 0.35s ease-in;

				&:checked {
					.primary;
					.on-primary-text;
				}
				/*&:not(:checked) {
					.surface-container-highest;
				}*/
			}
		}
	}
	/** end **/
}

	.ls-scanner-cameraview-nav {
		span {
			/*.outline-variant;*/
			margin: auto auto;
			display: inline;
			display: block;
			border-radius: 50%;
			width: 1.5rem;
			height: 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			align-self: center;
			background-color: transparent;
			/*.secondary-container;
		.secondary-container-text;*/
			.surface-container-highest;
			transition: background-color 0.35s ease-in;

			&.cam-selected {
				.primary;
				.on-primary-text;
			}
		}
	}


	.ls-scanner-person {
		/*.ls-card-filled;*/
		.ls-card-outlined;
		padding: 0.575rem;
		margin-top: 1rem;

		.ls-scanner-person-foto {
			img {
				width: 100px;
				margin-right: 0.25rem;
			}
		}

		.ls-scanner-person-name {
			.title-medium;

			@media @mobile {
				.title-small;
			}
		}

		.ls-scanner-status {
			.tertiary-text;
			font-weight: 600
		}

		.ls-scanner-status-history {
			max-width: 16rem;
			color: @color_lectio_DarkGray;

			@media @mobile {
				font-size: 0.8rem;
			}
		}
	}

	.ls-scanner-person-search {
		margin: 0.5em 0;
	}
	/* mobile portrait placer søgebox i toppen  ******************************/
	.ls-scanner-container-title {
		@media @mobileportrait {
			margin-top: 2.7rem;
		}
	}

.ls-searchbox-container {
	/* override default styles */
	input {
		width: 30rem;
	}

	@media @mobileportrait {
		position: fixed;
		top: 2.7rem;
		input{
			width:78dvw;
		}
	}
}
