.file-utilities-less {
	color: blue;
}
@import "constants.less";

/****************************************************/
/* Alignment ********************************/
/****************************************************/
.textRight,
TH.textRight,
.textRight TH,
.textRight TD {
	text-align: right;
}

.textLeft,
TH.textLeft,
.textLeft TH,
.textLeft TD {
	text-align: left;
}

.textCenter,
TH.textCenter,
TD.textCenter,
.textCenter TH,
.textCenter TD,
.textCenter TR TH,
.textCenter TR TD {
	text-align: center;
}

.textCenterMinWidth{
	text-align: center;
	min-width: 75px
}

.textTop,
.textTop TH,
.textTop TD {
	vertical-align: top;
}

.textBot,
.textBot TH,
.textBot TD {
	vertical-align: bottom !important;
}

.textMid *,
.textMid,
.textMid TH,
.textMid TD,
.textMid,
.textMid TR TD {
	vertical-align: middle !important;
}



/* for button or link rows  */
.floatContainer {
	display: block;
	overflow: hidden;
}

/* for larger areas NO specific height */
.floatClear,
.ls-float-clear {
	clear: both;
}

/* stop float */
.floatLeft {
	float: left;
	display: inline;
}

.floatRight {
	float: right;
	display: inline;
}



/* END - Alignment ********************************/
.hidetabinputbutton
/* hack: that hides input button, so that accesskey will work on links*/ {
	width: 0 !important;
	height: 0 !important;
	-moz-opacity: .01;
	/* for Firefox */
	opacity: .01;
	/* for other browsers */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=1)";
	/* for MSIE 8*/
	filter: alpha(opacity=1);
	/* for MSIE 5-7 */
	border: 0;
	/* fixed for at faa den til at blive i viewport, saa browseren ikke scroller hen til den, naar man trykker paa den. */
	position: fixed;
	right: 5px;
	top: 5px;
}

.hidden {
	width: 0;
	height: 0;
	position: absolute;
	visibility: hidden;
}

/****************************************************/
/* Fonts ********************************************/
/****************************************************/
.heavy,
.bb_b, .textBold {
	font-weight: bold;
}

.bb_i {
	font-style: italic;
}

.bb_u {
	text-decoration: underline;
}



.normal {
	font-weight: normal;
}

.resetText
/* til at override nedarvede styles */ {
	font-weight: normal !important;
	color: #333333 !important;
	text-decoration: none !important;
	font-size: 1em !important;
	font-family: helvetica, arial, sans-serif !important;
}

/* END - Fonts ********************************************/
/* ************************************************ */
/*		Tooltips									*/
/* ************************************************ */
.tooltips,
.tooltip {
	cursor: help;
	color: #466c80;
}

.tooltipimp {
	cursor: help;
}

.tooltiphead {
	cursor: help;
	color: #466c80;
	font-weight: bold;
}
@media @mobile{
	.tooltiphead, .tooltips, .tooltip, .tooltipimp {
		cursor: default;
		color: unset;
	}

}

/****************************************************/
/* Text  ********************************************/
/****************************************************/
.ellipsis
/*	MSIE only*/ {
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}

.noWrap,
.noWrap TD,
.noWrap TH,
.nowrap,
.nowrap TD,
.nowrap TH {
	white-space: nowrap !important;
}

.wrap,
TABLE.wrap TR TD {
	white-space: normal !important;
	word-break: break-word;
	hyphens: auto;

	@media @mobile {
		word-break: normal;
	}
}

.breakword {
	white-space: -moz-pre-wrap;
	/* Mozilla, since 1999 */
	white-space: -pre-wrap;
	/* Opera 4-6 */
	white-space: -o-pre-wrap;
	/* Opera 7 */
	word-wrap: break-word;
	/* Internet Explorer 5.5+ */
	word-break: normal;
}

.ls-text-wrap {
	white-space: pre-wrap;
	word-wrap: normal;
	word-break: break-word;
	overflow-wrap: break-word;
}

/****************************************************/
/* Links  ********************************************/
/****************************************************/
.cursorhand {
	cursor: pointer;
}

.cursorhelp {
	cursor: help;
}

A.nohover:hover,
.nohover:hover A,
.nohover:hover IMG {
	background: none;
	background-color: transparent;
	color: #1b5c9e !important;
}

.nomargin {
	margin: 0;
}

.nopadding {
	padding: 0;
}


/*******************************************/
/* widths & heights */
.maxW {
	max-width: 100%;
	width: 100%;
}
@media @mobile {
	.maxW {
		max-width: unset;
		width: unset;
	}
}


.marginRight {
	margin-right: 0.35em;
}

.marginLeft {
	margin-left: 0.35em;
}

.marginTop {
	margin-Top: 0.35em;
}

.marginBottom {
	margin-bottom: 0.35em;
}

.maxWidth {
	width: 98%;
	margin: 0;
	padding: 0;
}

.ls-height-75 {
	height: 75vh;
}

.ls-height-80 {
	height: 80vh;
}

.ls-height-100 {
	height: 100vh;
}

/* ************************************************** */
/*       Tabelkolonner - Bredde
/* ************************************************** */
.minWidth5em {
	min-width: 5em;
}

.minWidth8em {
	min-width: 8em;
}

.minWidth10em {
	min-width: 10em;
}
.minWidth20em {
	min-width: 20em;
}

.minWidth30em {
	min-width: 30em;
}

.maxWidth20em {
	max-width: 20em;
}
.maxWidth30em {
	max-width: 30em;
}

.width8em {
	min-width: 8em;
	max-width: 8em;
}

.width12em {
	min-width: 12em;
	max-width: 12em;
}
.width20em {
	min-width: 20em;
	max-width: 20em;
}

.Width120 {
	min-width: 120px;
	max-width: 120px;
}

@media @mobile {
	.maxWidth{width:unset;}
	.maxWidth15emMobile {
		max-width: 15em;
	}

	.maxWidth20emMobile {
		max-width: 20em;
	}
}

TD.smallCol,
TH.smallCol,
TR.smallCol TD,
TR.smallCol TH,
.smallCol {
	width: 30px;
}

TD.mediumCol,
TH.mediumCol,
TR.mediumCol TD,
TR.mediumCol TH,
.mediumCol {
	width: 60px;
}

TD.largeCol,
TH.largeCol,
TR.largeCol TD,
TR.largeCol TH,
.largeCol {
	width: 90px;
}

TD.xlargeCol,
TH.xlargeCol,
TR.xlargeCol TD,
TR.xlargeCol TH,
.xlargeCol {
	width: 120px;
}

TD.xxlargeCol,
TH.xxlargeCol,
TR.xxlargeCol TD,
TR.xxlargeCol TH,
.xxlargeCol {
	width: 150px;
}
/* ***************************************************************************** */
/* Lectio-control styles lec:LectioTextBox, lec:simple select, lec:LectioDropDown*/
/* ***************************************************************************** */

.ls-control-textbox-3xlarge {
	width: 29em;
}

.ls-control-textbox-2xlarge{
	width:21em;
}

.ls-control-textbox-xlarge {
	width: 18em;

	@media @mobileportrait {
			width: 80vw;
	}
}

.ls-control-textbox-large {
	width: 13em;
}

.ls-control-textbox-medium {
	width: 9em;
}

.ls-control-textbox-medium-small {
	width: 7em;
}

.ls-control-textbox-small {
	width: 6em;
}
.ls-control-textbox-xsmall {
	width: 4.5em;
}
.ls-control-textbox-multiline {
	white-space: pre-wrap;
}
.ls-control-select-simple {
	width: 15em;
}
.ls-control-dropdown {
	display: inline-flex;
	max-width: 100%;
	select {
		max-width: 100%;
	}
}
/* ************************************************** */
/*       Text og Select boxe - Bredde                 */
/* ************************************************** */
.smallBox,
INPUT.smallBox {
	width: 80px;
}
td.smallBox {
	input[type=text] {
		width: 80px;
	}
}
.mediumBox,
INPUT.mediumBox {
	width: 130px;
}
td.mediumBox {
	input[type=text] {
		width: 130px;
	}
}

.largeBox {
	width: 180px;
}
td.largeBox {
	input[type=text] {
		width: 180px;
	}
}

.xlargeBox {
	width: 250px;
}


	td.xlargeBox {
		input[type=text] {
			width: 250px;
		}
	}

	.xxlargeBox {
		width: 350px;
	}

	td.xxlargeBox {
		input[type=text] {
			width: 350px;
		}
	}
	/* CKEditor's filvælger sætter z-index:10020*/
	.documentchooserDialog {
		z-index: 10021
	}

	.documentchooserDialog .ui-dialog-buttonpane {
		/*background-color: #fafafa;
	border-top: solid 2px @color_lectio_LightBlue;*/
		@media @mobile {
			// tillad at knap-panelet kan bliver breddere på mobil
			width: fit-content;
		}
	}

	.documentchooserDialog .insert-as-type {
		display: flex;
		font-size: 1.2em;
		justify-content: right;
		margin-top: 0.6em;
		margin-right: 11em;
		color: #1b5c9e;
	}

	.documentchooserDialog .insert-as-type input[type=radio] {
		margin-left: 0.9em;
		margin-top: 0;
		padding-top: 0;
	}

	.documentchooser-uploadfile-div {
		margin-top: 3em;
		margin-left: 2em;

		@media @mobile {
			.button {
				margin-top: 0.5em;
			}
		}
	}
/* noty extra styling (se Test/notifiertest.aspx)*/
.noty_bar {
		padding: 0.5em;
	}
/*
	.noty_layout {
		background-repeat: no-repeat;
		background-position: 1.2em center;
		background-size: 3em 3em;
	}
	*/
	.noty_message {
		margin-left: 3.5em;
	}

	.nb_type_information {
		color: @brush_infotext;
		margin-left: 2.3rem;

		&::before {
			.ls-fonticon;
			content: "info";
			font-size: 1.9em;
			vertical-align: middle;
			position: relative;
			margin-left: -2.3rem;
		}
	}

	.nb_type_alert {
		color: @brush_attention;
		margin-left: 2.3rem;

		&::before {
			.ls-fonticon;
			content: "warning";
			font-size: 1.9em;
			vertical-align: middle;
			position: relative;
			margin-left: -2.3rem;
		}
	}
	/*************************************************************/
	/* layout af f.eks. div og span elementer - (ikke tabeller) */
	.ls-flex-content {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}

	.ls-flex-content img {
		.ls-std-inline-block;
	}
	/* praktikpladsforside, tjekkes */
	.ls-flex-0,
	.ls-flex-1,
	.ls-flex-2 .ls-flex-3,
	.ls-flex-4,
	.ls-flex-5,
	.ls-flex-6 {
		display: block !important;
		flex: 0;
	}

	.ls-flex-1 {
		flex: 1;
	}

	.ls-flex-2 {
		flex: 2;
	}

	.ls-flex-3 {
		flex: 3;
	}

	.ls-flex-4 {
		flex: 4;
	}

	.ls-flex-5 {
		flex: 5;
	}

	.ls-flex-6 {
		flex: 6;
	}
	/***********************************************************************/
	/* til knap/navigations rækker over under øer/tabeller m.v. sættes på ydre div samt to indre divs til indhold (venstre - højre placering)*/
	.ls-std-rowblock {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		margin: 0.2em 0;

		img,
		a,
		span,
		div,
		label {
			vertical-align: middle;
		}

		@media @mobile {
			/* toolbar knapper wrappede på mobil - skal muligvis gøres generel også gælde for desktop*/
			& > div {
				white-space: nowrap;
			}
		}

		@media @mobileportrait {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin: 0.2em 0;
			flex-wrap: wrap;

			img,
			a,
			span,
			div,
			label {
				vertical-align: middle;
			}
		}
	}
	//TODO ligger lige her atm burde flyttes
	.tidsreg-table-mobile {
		@media @mobile {
			margin: 10px;
		}
	}




	.ls-std-rowblock-ltr {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;
		margin: 0.2em 0;

		img,
		a,
		span,
		div,
		label {
			vertical-align: middle;
		}
	}

	.ls-std-rowblock-rtl {
		.ls-std-rowblock-ltr;
		justify-content: flex-end;

		DIV.buttonicon {
			padding-left: 0.2em;
			padding-right: 0.2em;

			&:last-of-type {
				padding-right: 0em;
			}
		}
	}


	ul.ls-std-ul-inputlist {
		li {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: center;
			margin: 0.4em 0;

			img,
			a,
			span,
			div,
			label,
			input {
				vertical-align: middle;
			}
		}
	}

	ul.ls-std-ul-inputlist-horisontal {
		display: flex;
		flex-direction: row;

		li {
			margin: 0 0.4em 0 0;

			img,
			a,
			span,
			div,
			label,
			input {
				vertical-align: middle;
			}
		}
	}
	/*************************************************************/
	/* til elementer der skal placeres ved siden af hinanden (NB må ikke bruges til islands (ø'er). Sættes på ydre div, herved vil underliggende diver(ø'er)  placeres ved siden af hinanden og alignes til top */
	.ls-std-rowblock-top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-start;
	}

	.ls-std-rowblock-top-ltr {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: flex-start;
	}
	/**********************************************/
	.ls-std-inline-block {
		display: inline-block;
		vertical-align: middle;

		img,
		span,
		a,
		label {
			vertical-align: middle;
			margin-top: 0;
		}
	}

	.ls-flip-element-horisontal {
		transform: scaleX(-1);
	}

	.ls-flex-col {
		display: flex;
		flex-direction: column;
	}

.ls-color-label {
	display: flex;
	justify-content: flex-end;
	padding-left: 4px;
	padding-right: 4px;
	align-items: center;
	height: 20px;
	border-radius: 5px;
	border: 1px solid @color_lectio_DarkGray;
	max-width: inherit;

	&:hover{
		opacity: 0.8;
	}

	a, .ls-fonticon, .tooltip {
		color: black;
	}
}

.ls-color-label-red {
	.ls-color-label;
	background-color: @color-fv-100;
	color: @--md-ref-palette-custom210;
}

.ls-color-label-green {
	.ls-color-label;
	background-color: @color-fv-0;
	color: @--md-ref-palette-custom340;
}