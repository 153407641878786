.file-framing-less {
	color: blue;
}

@import (reference) "constants.less";
@import (reference) "framing.less";

.ls-kpi-filters {
	@media @mobileportrait {
		label {
			display: block;
		}
	}
}

.ls-kpi-container {
	@mt: 0.5rem;
	@mr: 1rem;
	@mb: 1rem;
	@ml: 0;
	min-width: 97dvw;
	max-width: 99dvw;
	.ls-card-filled;

	@media @mobileportrait {
		background: transparent;
	}

	.ls-teasers-container {
		display: flex;
		flex-wrap: wrap;
		align-items:stretch;
		section {
			@maxw: 95vw;
			
			max-width: @maxw;
			margin-right: 1rem;
		}
	}

	.ls-diagrams-container {
		display: flex;
		flex-wrap: wrap;

		section {
			@maxw: 95vw;
			max-width: @maxw;
			margin-right: 1rem;
		}
	}


	.ls-diagram-teaser-container {
		margin: @mt @mr @mb @ml;
		
		@media @mobileportrait {
			width: 85dvw;
			height: auto;
		}
	}
	/* Diagramtyper  teasers */
	.ls-pie-mini-diagram-container {
		width: 12.5rem;
		height: 12.5rem;
		
		position: relative;
		z-index: auto;
		display: inline-block;

		@media @mobileportrait {
			width: 60dvw;
			height: auto;
		}
	}

	.ls-pie-medium-diagram-container {
		width: 25rem;
		height: 25rem;
		position: relative;
		z-index: auto;
		display: inline-block;

		@media @mobileportrait {
			width: 60dvw;
			height: auto;
		}
	}

	.ls-bar-mini-diagram-container {
		min-width: 45.5rem;
		width: 100%;
		min-height: 22.75rem;
		position: relative;
		z-index: auto;
		display: inline-block;
		margin: 1rem auto;

		@media @mobileportrait {
			width: 85dvw;
			height: auto;
			margin: auto auto;
		}
	}


	.ls-heatmap-mini-diagram-size-mini {
		width: 25rem;
		height: 25rem;

		@media @mobileportrait {
			width: 85dvw;
			height: auto;
			margin: auto auto;
		}
	}

	.ls-singlenumber-diagram {
		.ls-card-elevated;
		margin: @mt @mr @mb @ml;
		width: 15.5rem
	}

	a:has(.ls-singlenumber-diagram), a:has(.ls-diagram-teaser-container) {
		color: unset;
	}

	a:hover:has(.ls-singlenumber-diagram), a:hover:has(.ls-diagram-teaser-container) {

		.ls-singlenumber-diagram, .ls-diagram-teaser-container {
			.ls-elevation-level4;
			opacity: 1;
		}
	}
	/* diagramtyper  standard */
	.ls-bar-diagram-container {
		min-width: 50rem;
		width: 100%;
		height: 23.75rem;
		position: relative;
		z-index: auto;
		display: inline-block;
		margin: 1rem auto;

		@media @mobileportrait {
			width: 85dvw;
			height: auto;
			margin: auto auto;
		}
	}


	.ls-bar-large-diagram-container {
		width: 985px;
		height: auto;
		position: relative;
		z-index: auto;
		display: inline-block;
		margin: 1rem auto;

		@media @mobileportrait {
			width: 85dvw;
			height: auto;
			margin: auto auto;
		}
	}

	.ls-heatmap-diagram-size-default {
		width: 50rem;
		height: 50rem;


		@media @mobileportrait {
			width: 85dvw;
			height: auto;
			margin: auto auto;
		}
	}
}

// Anvendt i dashboard ansoeger
.ls-heatmap-diagram-size-default {
	width: 100%;
	height: 800px;


	@media @mobileportrait {
		width: 85dvw;
		height: auto;
		margin: auto auto;
	}
}

.ls-heatmap-mini-diagram-size-mini {
	width: 25rem;
	height: 25rem;

	@media @mobileportrait {
		width: 85dvw;
		height: auto;
		margin: auto auto;
	}
}


/* diagrammer custom tooltip start********************************************** */

@--diagram-tooltip-custom-triangle-size: 5px;

.ls-diagram-tooltip-custom {
	background: rgba(0, 0, 0, 0.7);
	border-radius: 4px;
	color: white;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	transform: translate(-50%, 0);
	transition: all .1s ease;
	z-index: 1000;
	padding: 5px;

	&::after {
		content: "";
		position: absolute;
		border-width: @--diagram-tooltip-custom-triangle-size;
		border-style: solid;
		border-color: transparent transparent transparent transparent;
	}

	table {
		margin: 0px;

		tr {
			border-width: 0;
			background-color: inherit;
		}

		tr th, tr td {
			border-width: 0;
			text-align: left;
			white-space: nowrap;
		}

		tr:first-of-type > td {
			border-bottom: solid 1px #777;
		}
		tr:not(:first-of-type) > td {
			color: #eaeaea;
		}

		tr td {
			.ls-diagram-colorbox {
				border-width: 2px;
				margin-right: 10px;
				height: 10px;
				width: 10px;
				display: inline-block;
			}
		}
	}
}

.ls-diagram-triangle-position-bottom.ls-diagram-tooltip-custom::after {
	bottom: 0%;
	left: 50%;
	transform: translateY(@--diagram-tooltip-custom-triangle-size * 2) translateX(-(@--diagram-tooltip-custom-triangle-size));
	border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}

.ls-diagram-triangle-position-top.ls-diagram-tooltip-custom::after {
	top: 0%;
	left: 50%;
	transform: translateY(-(@--diagram-tooltip-custom-triangle-size * 2)) translateX(-(@--diagram-tooltip-custom-triangle-size));
	border-color: transparent transparent rgba(0, 0, 0, 0.7) transparent;
}

.ls-diagram-triangle-position-right.ls-diagram-tooltip-custom::after {
	top: 50%;
	right: 0%;
	transform: translateX(@--diagram-tooltip-custom-triangle-size * 2) translateY(-(@--diagram-tooltip-custom-triangle-size));
	border-color: transparent transparent transparent rgba(0, 0, 0, 0.7);
}

.ls-diagram-triangle-position-left.ls-diagram-tooltip-custom::after {
	top: 50%;
	left: 0%;
	transform: translateX(-(@--diagram-tooltip-custom-triangle-size * 2)) translateY(-(@--diagram-tooltip-custom-triangle-size));
	border-color: transparent rgba(0, 0, 0, 0.7) transparent transparent;
}

/* diagrammer custom tooltip end********************************************** */

