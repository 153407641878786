@import (reference) "constants.less";

@skemany-grid-width: 100%;
@skemany-grid-skema-width: 100%;

.display-grid-skemany {
	display: grid;
	width: @skemany-grid-width;
	margin-top: 0.5rem;
	grid-template-columns: auto;
	grid-template-rows: auto;

	.ls-skema-scroll-horizontal {
		width: @skemany-grid-skema-width;
		overflow-x: auto;
		/* TEST scrollbar top **********/
		/*transform: rotateX(180deg);
			& >:first-child {
				transform: rotateX(-180deg);
			}*/
		/*********************************/
	}
}
.display-grid-skemaforside {
	.grid-area-skema {
		.skemaweekContainer {
			a.s2ambient {
				.skemabrik-ambient();

				span {
					cursor: pointer;
				}
			}
		}
	}
}

.display-grid-skemany,
.display-grid-skemaforside {
	.grid-area-skema {
		grid-area: skema;
		border-left: solid 1px transparent;
		padding: 0.25rem;
		overflow-x: hidden;
		/***** Skemauger *****/
		.skemaweekContainer {
			display: -ms-inline-grid;
			display: inline-grid;
			border: 1px solid #b8c3cc;
			border-left-color: transparent;
			-ms-justify-content: start;
			justify-content: start;
			font-size: 0.9em;
			border: none;
		}

		.skemaweekModuleContainer > span {
			/*modulnummer*/
			display: inline-block;
			white-space: nowrap;
			color: #466c80;
			font-weight: bold;
			vertical-align: middle;
		}

		.skemaweekModuleContainer[data-rowcol~=c1] > span {
			cursor: pointer;
			color: #006493; /*primary-text*/
		}

		.skemaweekModuleContainer {
			white-space: nowrap;
			border-left: 0.1em solid #b8c3cc;
			border: 1px solid #b8c3cc;
			border-bottom: 1px solid #dce9f5;
			display: flex;
			min-width: 1em;
			justify-content: center;
			align-items: flex-start;
			padding-top: 0.3em;
			padding-left: 0.3em;
			padding-right: 0.1em;
			padding-bottom: 0.1em;
			/* modul 'kolonne-bredde' */
			max-width: 5em;
			user-select: none;
			background-color: #ecf1f9; /*neutral-variant95*/
		}

		.skemaweekContainer.hasCheckboxes .skemaweekModuleContainer {
			justify-content: space-between;
			min-width: 2.6em;
		}

		.skemaweekContainer .skemaweekModuleContainer[data-rowcol~=c1] {
			min-width: 2.8em;
		}

		.skemaweekContainer.hasCheckboxes .skemaweekModuleContainer[data-rowcol~=c1] {
			min-width: 4em;
		}

		.skemaweekModuleContainer input[type=checkbox] {
			margin-left: 0.2em
		}

		.skemaweekWeek {
			white-space: nowrap;
			text-align: center;
			/* uge 'kolonne-bredde' */
			max-width: 35em;
			cursor: pointer;
			user-select: none;
			border: none;
			padding: 0 0 0.25em 0;
			border-bottom: solid 1px #c1c7ce; /*outline-variant*/
			/*title-small*/
			font-weight: 500;
			font-size: 1.166rem;
			line-height: 1.666rem;
			letter-spacing: 0.008rem;
			color: #006493 /*primary-text*/
		}

		.skemaweekWeek:hover {
			cursor: pointer;
			opacity: 0.85;
		}

		.skemaweekWeekDay {
			white-space: nowrap;
			border-bottom: solid 1px #b8c3cc;
			text-align: center;
			vertical-align: middle;
			border-left: 1px solid #ccc;
			/* uge 'kolonne-bredde' */
			max-width: 35em;
			cursor: pointer;
			user-select: none;
			background-color: #f6f9ff; /*neutral-variant98*/
			font-family: Roboto;
			font-style: normal;
			font-weight: 700;
			line-height: 1.333rem;
			letter-spacing: 0.042rem;
			color: #006493; /*primary-text*/
			padding: 0.15rem 0 0.05rem 0;
		}

		.skemaweekWeekDay:hover {
			cursor: pointer;
			opacity: 0.85;
		}

		.skemaweekWeekDayAlle {
			white-space: nowrap;
			text-align: center;
			vertical-align: middle;
			color: #475968;
			border-left: 1px solid #ccc;
			/* uge 'kolonne-bredde' */
			max-width: 35em;
			cursor: pointer;
			user-select: none;
			background-color: #f6f9ff; /*neutral-variant98*/
			/*label-medium;*/
			font-family: Roboto;
			font-style: normal;
			font-weight: 700;
			line-height: 1.333rem;
			letter-spacing: 0.042rem;
			color: #006493; /*primary-text*/
			padding: 0.15rem 0 0.05rem 0;
		}

		.skemaweekWeekDayAlle:hover {
			cursor: pointer;
			opacity: 0.85
		}

		.skemaweekSkemabrikContainer {
			padding: 0px 2px 0px 2px;
			background: white;
			min-height: 1.9em;
			border-bottom: 1px solid #dce9f5;
			/* skemabrik 'kolonne-bredde' */
			width: 202px;
			white-space: nowrap;
		}

		.skemaweekSkemabrikContainer.holiday {
			background: #eeeeee;
			border-bottom: 1px solid #c7c7c7;
		}

		.skemaweekSkemabrikContainer.drop-over, .s2module-bg.drop-over {
			background-color: royalBlue;
		}

		.lc-expander-link {
			float: left
		}

		.highlightSelection {
			background: #e5f8ff; /*tertiary97*/
		}
		/************** Skemauger - visning af skemabrikker **************/
		.s2skemabrik:hover:not(.s2bgboxeksamen):not(.s2ambient) {
			cursor: pointer !important;
			opacity: 0.85;
		}

		a.s2skemabrik {
			overflow: hidden;
			display: block;
			width: 175px;
			float: left;
			cursor: context-menu;
			padding-left: 0.2rem;
			padding-right: 0.2rem;
		}

		a.s2normal {
			.skemabrik-normal();
		}





		a.s2faded {
			opacity: 0.3;
		}

		a.s2changed {
			.skemabrik-changed();
		}


		a.s2optional {
			.skemabrik-optional();

			&.s2changed {
				.skemabrik-changed-text();
			}
		}





		a.s2cancelled {
			.skemabrik-cancelled()
		}

		a.s2bgboxeksamen {
			.skemabrik-exam();
			cursor: help !important;
		}


		a.s2scenario {
			.skemabrik-scenario();
		}

		a.s2skemabrik {
			border-top-width: 0;
			border-bottom-width: 0;
			border-right-width: 0;
			margin: 3px 3px 3px 2px;
		}

		a.s2selected {
			.skemabrik-selected();
			margin: 1px 1px 1px 2px;
		}

		a.s2defocus {
			.skemabrik-defocus();
		}
		/* expandablepanel: div-en med foerste brik og foldeknap. */
		.skemaweekSkemabrikContainer > div:first-child:not(.skemaweekSkemabrik) {
			display: flex;
		}

		.skemaweekSkemabrik {
			border: solid 2px transparent;
		}

		.skemaweekSkemabrikBorder-deleteme {
			border: solid 2px transparent;
			overflow: hidden;
			display: inline-block;
			float: left;
		}
	}
}
