// STM: 45441
// Based on: https://amphiluke.github.io/floating-scroll/


.fl-scrolls {
	overflow: auto;
	position: fixed;

	&,
	div {
		font-size: 1px;
		line-height: 0;
		margin: 0;
		padding: 0;
		z-index: 1;
	}

	div {
		overflow: hidden;
		pointer-events: none;

		&::before {
			content: "\A0"; /* fixes #6 */
		}
	}

	&[data-orientation="horizontal"],
	&:not([data-orientation]) { /* handling cases with no data attribute for backward compatibility */
		bottom: 0;
		min-height: 17px; /* based on https://codepen.io/sambible/post/browser-scrollbar-widths (fixes Amphiluke/handy-scroll#3) */

		div {
			height: 1px;
		}
	}

	&[data-orientation="vertical"] {
		right: 0;
		min-width: 17px;

		div {
			width: 1px;
		}
	}
}

.fl-scrolls-hidden {

	&.fl-scrolls[data-orientation="horizontal"],
	&.fl-scrolls:not([data-orientation]) {
		bottom: 9999px;
	}


	&.fl-scrolls[data-orientation="vertical"] {
		right: 9999px;
	}

	div::before {
		content: "\A0\A0"; /* changing content fixes eventual bug with widget re-rendering in Chrome */
	}
}

.fl-scrolls-viewport {
	position: relative;

	.fl-scrolls {
		position: absolute;

		&[data-orientation="horizontal"],
		&:not([data-orientation]) {
			left: 0;
		}

		&[data-orientation="vertical"] {
			top: 0;
		}
	}
}

.fl-scrolls-body {
	overflow: auto;
}

.fl-scrolls-hoverable {
	&:hover .fl-scrolls {
		opacity: 1;
	}

	.fl-scrolls {
		opacity: 0;
		transition: opacity 0.5s ease 0.3s;
	}
}
