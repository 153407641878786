.file-historik-less {
	color: blue;
}
@import (reference) "constants.less";
//@lectiocolor: rgba(211, 223, 235, 1);
//@lectiocolordark: rgba(161, 173, 185, 1);
/*
@color_lectio_Sky1: #dce9f5;
@color_lectio_Sky2: #cad5e0;
@color_lectio_Violet1: #dfdcf5;
@color_lectio_Violet2: #cdcae0;
@color_lectio_Grass1: #dcf5dc;
@color_lectio_Grass2: #cae0ca;
@color_lectio_Ocean: #dcf5f5;
@color_lectio_Sand: #f5f3dc;
@color_lectio_DarkGreen: #339900;
	*/

svg .zoom {
	cursor: move;
	fill: transparent;
	pointer-events: all;
}

svg .btn {
	stroke: black;
	stroke-width: 0.5pt;
	cursor: pointer;
}

svg .btn:not(:hover) {
	fill: transparent;
}

svg .btn:hover {
	fill: lightblue;
	fill-opacity: 0.5;
}

svg .main {
	clip-path: url(#clip);

	.labeltext {
		fill: black;
		font-size: 14pt;
		opacity: 1;
	}

	.box {
		stroke: black;
		stroke-width: 0.2pt;

		text {
			stroke-width: 0;

			&.nonClickable {
				pointer-events: none;
			}
		}
	}

	.box:hover {
		stroke-width: 0.4pt;
	}

	rect {
		rx: 2;
		ry: 2;

		&.udd {
			&.lectioControlled {
				fill: @color_lectio_Sky1;
			}

			&.nonLectioControlled {
				fill: @color_lectio_Sky2;
			}
		}

		&.elevhist {
			&.lectioControlled {
				fill: @color_lectio_Sky1;
			}

			&.nonLectioControlled {
				fill: @color_lectio_Sky2;
			}
		}

		&.aftale {
			&.lectioControlled {
				fill: @color_lectio_Violet1;
			}

			&.nonLectioControlled {
				fill: @color_lectio_Violet2;
			}
		}

		&.skoleforloeb {
			&.lectioControlled {
				fill: @color_lectio_Grass1;
			}

			&.nonLectioControlled {
				fill: @color_lectio_Grass2;
			}
		}

		&.xprselevtype {
			fill: darkkhaki;
			fill: @color_lectio_Sand;
		}

		&.skolePraktik {
			&.isNotInSchool {
				fill: white;
			}

			&.isInSchool {
				fill: @color_lectio_Ocean;
			}

			&.isTransparent {
				fill: transparent;
				pointer-events: none;
			}
		}

		&.pause {
			fill: orange;
			opacity: 0.20;

			&:hover {
				opacity: 0.40;
			}
		}
	}

	.todayLine {
		stroke: @color_lectio_DarkBlue;
		stroke-width: 1pt;
	}

	.todayLineZoom {
		stroke: @color_lectio_DarkBlue;
		stroke-width: 1pt;
	}

	.xAxis {
		line {
			stroke: gray;
		}

		text {
			fill: gray
		}

		.domain {
			stroke: gray
		}


		.axis {
			font-size: 10pt;
			font-family: Helvetica neue, Helvetica, sans-serif;

			&.month,
			&.year {
				font-size: 8pt;
				opacity: 1;
				font-family: Helvetica neue, Helvetica, sans-serif;
			}
		}
	}
}

.overview {
	fill: @color_lectio_DarkGreen;
}

.toolTip {
	position: absolute;
	display: none;
	min-width: 80px;
	height: auto;
	background: none repeat scroll 0 0 #ffffff;
	border: 1px solid #6F257F;
	padding: 14px;
	z-index: 10;
	text-align: center;
}
